<template>
<div>
 <el-breadcrumb separator-class="el-icon-arrow-right">
    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
    <el-breadcrumb-item>发票管理</el-breadcrumb-item>
    <el-breadcrumb-item>来票管理</el-breadcrumb-item>
 </el-breadcrumb>
  <!--   搜索Form表单区域   -->
  <el-card>
    <el-form v-model="searchForm" :inline="true">
      <el-form-item label="发票代码">
        <el-input v-model="searchForm.code" placeholder="请输入发票代码" clearable style="width: 150px"></el-input>
      </el-form-item>
      <el-form-item label="发票号码">
        <el-input v-model="searchForm.number" placeholder="请输入发票号码" clearable style="width: 150px"></el-input>
      </el-form-item>
      <el-form-item label="发票分类">
        <el-select v-model="searchForm.category" placeholder="请选择发票分类" clearable style="width: 120px">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="发票状态">
          <el-select v-model="searchForm.invoice_status" placeholder="请选择发票状态" clearable style="width: 100px">
            <el-option
              v-for="item in invoice_status_list_all"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      <el-form-item label="开票日期">
        <el-date-picker value-format="timestamp" v-model="searchForm.invoice_date" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" style="width: 240px">
        </el-date-picker>
      </el-form-item>
    </el-form>
  </el-card>
  <!-- 发票列表区域 -->
  <el-card>
    <el-button-group>
      <el-button type="primary" @click="searchInvoice" size="small" icon="el-icon-search">查询</el-button>
      <el-button type="primary" @click="addDialogShow"  size="small" icon="el-icon-plus" v-if="isAdminScope || !isInvoiceScope">新增</el-button>
  <!--      <el-button type="primary" v-loading.fullscreen.lock="fullscreenLoading" @click="exportExcel" size="small" icon="el-icon-document">导出EXCEL</el-button>-->
    </el-button-group>
    <el-table :data="invoiceListData" style="width: 100%" stripe :row-style="{height: '40px'}" :cell-style="{padding: '0'}">
      <el-table-column label="#" prop="id" width="80px" fixed="left"></el-table-column>
      <el-table-column label="发票代码" prop="code" width="120px" fixed="left"></el-table-column>
      <el-table-column label="发票号码" prop="number" width="200px" fixed="left" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="开票日期" prop="invoice_date" width="110px" :formatter="formatDate"></el-table-column>
      <el-table-column label="发票分类" prop="category" width="120px" :formatter="formatInvoiceCategory"></el-table-column>
      <el-table-column label="发票状态" prop="invoice_status" :formatter="formatInvoiceStatus"></el-table-column>
<!--      <el-table-column label="购买方抬头" prop="buyer_name" width="150px" :show-overflow-tooltip="true"></el-table-column>-->
<!--      <el-table-column label="购买方纳税号" prop="buyer_tax_code" width="150px" :show-overflow-tooltip="true"></el-table-column>-->
      <el-table-column label="销售方抬头" prop="seller_name" width="150px" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="销售方纳税号" prop="seller_tax_code" width="150px" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="发票金额" prop="amount" width="100px" :formatter="toDecimal2" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="发票税额" prop="tax_amount" width="100px" :formatter="toDecimal2" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="备注" prop="remark" width="200px" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="创建者" prop="creator" width="120px" :formatter="formatUserName"></el-table-column>
      <el-table-column label="创建时间" prop="create_time" width="165px" :formatter="formatDatetime"></el-table-column>
      <el-table-column label="更新者" prop="updator" width="120px" :formatter="formatUserName"></el-table-column>
      <el-table-column label="更新时间" prop="update_time" width="165px" :formatter="formatDatetime"></el-table-column>
      <el-table-column label="操作" width="170px" fixed="right" align="left">
       <template slot-scope="scope">
         <el-button-group>
          <el-button  v-if="isAdminScope || !isInvoiceScope"
            size="mini"
            type="primary"
            icon="el-icon-edit"
            @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
           <el-button  v-else
            size="mini"
            type="primary"
            icon="el-icon-view"
            @click="handleEdit(scope.$index, scope.row)">浏览</el-button>
          <el-popconfirm
            confirm-button-text='好的'
            cancel-button-text='不用了'
            icon="el-icon-info"
            icon-color="red"
            title="确定要删除吗？"
            @confirm="handleDelete(scope.$index, scope.row)">
              <el-button v-if="isAdminScope || !isInvoiceScope"
                size="mini"
                type="danger"
                icon="el-icon-delete"
                slot="reference">删除</el-button>
          </el-popconfirm>
         </el-button-group>
      </template>
    </el-table-column>
    </el-table>
    <el-pagination background style="width: 100%;margin:15px 0px;" class="pagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryInfo.page"
      :page-sizes="[3, 6, 10, 20]"
      :page-size="queryInfo.pageSize"
      layout="prev, pager, next, jumper,total, sizes"
      :total="queryInfo.total">
     </el-pagination>
  </el-card>
  <!-- 新增发票对话框 -->
  <el-dialog title="新增发票" width=60% :fullscreen=false :visible.sync="addDialogVisible" @click="addDialogClosed">
    <el-tabs type="border-card">
      <el-tab-pane>
      <span slot="label"><i class="el-icon-tickets"></i> 基础信息</span>
      <el-form :model="addForm" ref="addFormRef" :rules="addFormRules" :inline="true" label-width="120px">
        <el-form-item label="发票代码" prop="code">
          <el-input v-model="addForm.code"></el-input>
        </el-form-item>
        <el-form-item label="发票号码" prop="number">
          <el-input v-model="addForm.number"></el-input>
        </el-form-item>
        <el-collapse v-model="activeNames" @change="handleChange">
          <el-collapse-item title="购买方信息" name="1">
            <el-form-item label="名称" prop="buyer_name">
              <el-input v-model="addForm.buyer_name"></el-input>
            </el-form-item>
<!--            <el-form-item label="纳税识别号" prop="buyer_tax_code">-->
<!--              <el-input v-model="addForm.buyer_tax_code" :readonly="true"></el-input>-->
<!--            </el-form-item>-->
            <el-form-item label="发票状态" prop="invoice_status">
              <el-select v-model="addForm.invoice_status" placeholder="请选择发票状态">
                <el-option
                  v-for="item in this.invoice_status_list"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="地址电话" prop="buyer_address_phone">
              <el-input v-model="addForm.buyer_address_phone"></el-input>
            </el-form-item>
            <el-form-item label="银行账户" prop="buyer_bank_account">
              <el-input v-model="addForm.buyer_bank_account"></el-input>
            </el-form-item>
          </el-collapse-item>
          <el-collapse-item title="销售方信息" name="2">
            <el-form-item label="名称" prop="seller_name">
              <el-input v-model="addForm.seller_name"></el-input>
            </el-form-item>
            <el-form-item label="纳税识别号" prop="seller_tax_code">
              <el-input v-model="addForm.seller_tax_code"></el-input>
            </el-form-item>
            <el-form-item label="地址电话" prop="seller_address_phone">
              <el-input v-model="addForm.seller_address_phone"></el-input>
            </el-form-item>
            <el-form-item label="银行账户" prop="seller_bank_account">
              <el-input v-model="addForm.seller_bank_account"></el-input>
            </el-form-item>
          </el-collapse-item>
        </el-collapse>
        <br />
        <el-form-item label="发票金额" prop="amount">
          <el-input v-model="addForm.amount"></el-input>
        </el-form-item>
        <el-form-item label="发票税额" prop="tax_amount">
          <el-input v-model="addForm.tax_amount"></el-input>
        </el-form-item>
        <el-form-item label="发票分类"  prop="category">
          <el-select v-model="addForm.category" placeholder="请选择发票分类" style="width:250px">
            <el-option
              v-for="item in this.getInvoiceCategoryList()"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开票日期" prop="invoice_date">
          <el-date-picker v-model="addForm.invoice_date" type="date" value-format="timestamp" style="width:250px"></el-date-picker>
        </el-form-item>
         <el-form-item label="备注" prop="remark">
          <el-input v-model="addForm.remark" type="textarea" style="width: 625px"></el-input>
        </el-form-item>
      </el-form>
      </el-tab-pane>
      <el-tab-pane>
        <span slot="label"><i class="el-icon-picture-outline"></i> 发票附件</span>
          <el-form>
            <el-form-item>
              <el-upload
                class="upload-demo"
                :on-change="uploadInvoiceFile"
                :auto-upload="false"
                action="#"
                :file-list="fileList"
                accept=".jpg, .jpeg, .png, .zip, .rar, .pdf">
                <el-button size="small" type="primary">选择发票复印件并上传</el-button>
                <div slot="tip" class="el-upload__tip">只能上传jpg|jpeg|png|zip|pdf文件，且不超过10MB</div>
              </el-upload>
            </el-form-item>
          </el-form>
          <el-table :data="tableImageData" style="width: 100%" stripe>
            <el-table-column label="序号" prop="id" width="60px"></el-table-column>
            <el-table-column label="上传者" prop="creator" :formatter="formatUserName" width="120"></el-table-column>
            <el-table-column label="上传时间" prop="create_time" :formatter="formatDatetime" width="165px"></el-table-column>
            <el-table-column label="文件（下载）" prop="name">
              <template slot-scope="scope">
                <el-link v-if="scope.row.name.split('.').pop().toLowerCase() === 'zip' || scope.row.name.split('.').pop().toLowerCase() === 'rar' || scope.row.name.split('.').pop().toLowerCase() === 'pdf'"
                  :href="getFileUrl(scope.row.path, scope.row.name)">{{scope.row.name}}</el-link>
                <el-image v-else
                  style="width: 100px; height: 100px"
                  :src="getFileUrl(scope.row.path, scope.row.name)"
                  :preview-src-list="[getFileUrl(scope.row.path, scope.row.name)]">
                </el-image>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="80px" fixed="right" align="left">
              <template slot-scope="scope">
                <el-popconfirm
                  confirm-button-text='好的'
                  cancel-button-text='不用了'
                  icon="el-icon-info"
                  icon-color="red"
                  title="确定要删除吗？"
                  @confirm="delInvoiceFileByID(scope.$index, scope.row)">
                    <el-button v-if="isAdminScope || isInvoiceScope"
                      size="mini"
                      type="danger"
                      icon="el-icon-delete"
                      slot="reference">删除</el-button>
                </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
    </el-tabs>
    <!-- 底部区域 -->
    <div slot="footer" class="dialog-footer">
      <el-button @click="addDialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="addInvoice">保 存</el-button>
    </div>
  </el-dialog>
  <!-- 编辑发票对话框 -->
  <el-dialog title="编辑发票" width=60% :fullscreen=false :visible.sync="editDialogVisible" @click="editDialogClosed">
    <el-tabs type="border-card">
      <el-tab-pane>
      <span slot="label"><i class="el-icon-tickets"></i> 基础信息</span>
      <el-form :model="editForm" ref="editFormRef" :rules="editFormRules" :inline="true" label-width="110px">
        <el-form-item label="发票代码" prop="code">
          <el-input v-model="editForm.code"></el-input>
        </el-form-item>
        <el-form-item label="发票号码" prop="number">
          <el-input v-model="editForm.number"></el-input>
        </el-form-item>
        <el-collapse v-model="activeNames" @change="handleChange">
          <el-collapse-item title="购买方信息" name="1">
            <el-form-item label="名称" prop="buyer_name">
              <el-input v-model="editForm.buyer_name"></el-input>
            </el-form-item>
<!--            <el-form-item label="纳税识别号" prop="buyer_tax_code">-->
<!--              <el-input v-model="editForm.buyer_tax_code" :readonly="true"></el-input>-->
<!--            </el-form-item>-->
            <el-form-item label="发票状态" prop="invoice_status">
              <el-select v-model="editForm.invoice_status" placeholder="请选择发票状态">
                <el-option
                  v-for="item in this.invoice_status_list"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="地址电话" prop="buyer_address_phone">
              <el-input v-model="editForm.buyer_address_phone"></el-input>
            </el-form-item>
            <el-form-item label="银行账户" prop="buyer_bank_account">
              <el-input v-model="editForm.buyer_bank_account"></el-input>
            </el-form-item>
          </el-collapse-item>
          <el-collapse-item title="销售方信息" name="2">
            <el-form-item label="名称" prop="seller_name">
              <el-input v-model="editForm.seller_name"></el-input>
            </el-form-item>
            <el-form-item label="纳税识别号" prop="seller_tax_code">
              <el-input v-model="editForm.seller_tax_code"></el-input>
            </el-form-item>
            <el-form-item label="地址电话" prop="seller_address_phone">
              <el-input v-model="editForm.seller_address_phone"></el-input>
            </el-form-item>
            <el-form-item label="银行账户" prop="seller_bank_account">
              <el-input v-model="editForm.seller_bank_account"></el-input>
            </el-form-item>
          </el-collapse-item>
        </el-collapse>
        <br />
        <el-form-item label="发票金额" prop="amount">
          <el-input v-model="editForm.amount"></el-input>
        </el-form-item>
        <el-form-item label="发票税额" prop="tax_amount">
          <el-input v-model="editForm.tax_amount"></el-input>
        </el-form-item>
        <el-form-item label="发票分类"  prop="category">
          <el-select v-model="editForm.category" placeholder="请选择发票分类" style="width:250px">
            <el-option
              v-for="item in this.getInvoiceCategoryList()"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开票日期" prop="invoice_date">
          <el-date-picker v-model="editForm.invoice_date" type="date" value-format="timestamp" style="width:250px"></el-date-picker>
        </el-form-item>
         <el-form-item label="备注" prop="remark">
          <el-input v-model="editForm.remark" type="textarea" style="width: 625px"></el-input>
        </el-form-item>
      </el-form>
      </el-tab-pane>
      <el-tab-pane>
        <span slot="label"><i class="el-icon-picture-outline"></i> 发票附件</span>
          <el-form>
            <el-form-item>
              <el-upload
                class="upload-demo"
                :on-change="uploadInvoiceFile"
                :auto-upload="false"
                action="#"
                :file-list="fileList"
                accept=".jpg, .jpeg, .png, .zip, .rar, .pdf">
                <el-button size="small" type="primary">选择发票复印件并上传</el-button>
                <div slot="tip" class="el-upload__tip">只能上传jpg|jpeg|png|zip|pdf文件，且不超过10MB</div>
              </el-upload>
            </el-form-item>
           <el-table :data="tableImageData" style="width: 100%" stripe>
            <el-table-column label="序号" prop="id" width="80px"></el-table-column>
            <el-table-column label="上传者" prop="creator" :formatter="formatUserName" width="120"></el-table-column>
            <el-table-column label="上传时间" prop="create_time" :formatter="formatDatetime" width="165px"></el-table-column>
            <el-table-column label="文件（下载）" prop="name">
              <template slot-scope="scope">
                <el-link v-if="scope.row.name.split('.').pop().toLowerCase() === 'zip' || scope.row.name.split('.').pop().toLowerCase() === 'rar' || scope.row.name.split('.').pop().toLowerCase() === 'pdf'"
                  :href="getFileUrl(scope.row.path, scope.row.name)">{{scope.row.name}}</el-link>
                <el-image v-else
                  style="width: 100px; height: 100px"
                  :src="getFileUrl(scope.row.path, scope.row.name)"
                  :preview-src-list="[getFileUrl(scope.row.path, scope.row.name)]">
                </el-image>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="80px" fixed="right" align="left">
              <template slot-scope="scope">
                <el-popconfirm
                  confirm-button-text='好的'
                  cancel-button-text='不用了'
                  icon="el-icon-info"
                  icon-color="red"
                  title="确定要删除吗？"
                  @confirm="delInvoiceFileByID(scope.$index, scope.row)">
                    <el-button v-if="isAdminScope || isInvoiceScope"
                      size="mini"
                      type="danger"
                      icon="el-icon-delete"
                      slot="reference">删除</el-button>
                </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
          </el-form>
        </el-tab-pane>
    </el-tabs>
    <!-- 底部区域 -->
    <div slot="footer" class="dialog-footer">
      <el-button @click="editDialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="updateInvoice">保 存</el-button>
    </div>
  </el-dialog>
</div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'invoicecome',
  data () {
    return {
      activeNames: ['1', '2'],
      invoiceListData: [],
      dialogVisible: false,
      addDialogVisible: false,
      editDialogVisible: false,
      userList: [],
      /** 新增发票对象 */
      addForm: {
        code: '',
        number: '',
        buyer_name: '',
        buyer_tax_code: '',
        buyer_address_phone: '',
        buyer_bank_account: '',
        seller_name: '',
        seller_tax_code: '',
        seller_address_phone: '',
        seller_bank_account: '',
        category: '',
        invoice_status: '',
        invoice_date: moment().valueOf(),
        amount: 0.00,
        tax_amount: 0.00,
        remark: '',
        images: []
      },
      /** 编辑合同数据检查 */
      addFormRules: {
        code: [
          { required: true, message: '请输入发票代码', trigger: 'blur' }
        ],
        number: [
          { required: true, message: '请输入发票号码', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在1～20个字符之间', trigger: 'blur' }
        ],
        category: [
          { required: true, message: '请选择发票分类', trigger: 'blur' }
        ],
        invoice_status: [
          { required: true, message: '请选择发票状态', trigger: 'blur' }
        ],
        buyer_name: [
          { required: true, message: '请输入购买方抬头', trigger: 'blur' },
          { min: 1, max: 32, message: '长度在1～32个字符之间', trigger: 'blur' }
        ],
        seller_name: [
          { required: true, message: '请输入销售方抬头', trigger: 'blur' },
          { min: 1, max: 32, message: '长度在1～32个字符之间', trigger: 'blur' }
        ],
        inovice_date: [
          { required: true, message: '请选择开票日期', trigger: 'blur' }
        ],
        amount: [
          { required: true, message: '请输入发票金额', trigger: 'blur' },
          { pattern: /^(0|[1-9]\d{0,9})(\.\d{0,2})?$/, message: '请输入正确的发票金额，保留2位小数', trigger: 'blur' }
        ],
        tax_amount: [
          { required: true, message: '请输入发票税额', trigger: 'blur' },
          { pattern: /^(0|[1-9]\d{0,9})(\.\d{0,2})?$/, message: '请输入正确的发票税额，保留2位小数', trigger: 'blur' }
        ],
        remark: [
          { max: 256, message: '长度不能超过256个字符', trigger: 'blur' }
        ]
      },
      /** 编辑发票表单对象 */
      editForm: {
        images: []
      },
      /** 编辑发票数据检查 */
      editFormRules: {},
      /** 合同附件上传 */
      images: [],
      uploadForm: {
        file: ''
      },
      fileList: [],
      tableImageData: [],
      file: {},
      /** 搜索条件对象 */
      queryInfo: {
        query: '',
        page: 1,
        pageSize: 10,
        total: 0
      },
      /** 搜索表单对象 */
      searchForm: {
        code: '',
        number: '',
        category: 0,
        invoice_status: 0,
        invoice_date: [moment().subtract(1, 'months').valueOf(), moment().valueOf()]
      },
      options: [{
          value: 0,
          label: '全部'
        }, {
          value: 10,
          label: '普通发票'
        }, {
          value: 20,
          label: '增值税专用发票'
      }, {
        value: 30,
          label: '增值税普通发票'
      }],
      invoice_status_list: [{ label: '未付', value: 10 }, { label: '已付', value: 20 }],
      invoice_status_list_all: [{ label: '全部', value: 0 }, { label: '未付', value: 10 }, { label: '已付', value: 20 }],

      isAdminScope: window.sessionStorage.getItem('scope') === 'AdminScope',
      isInvoiceScope: window.sessionStorage.getItem('scope') === 'InvoiceComeScope' || window.sessionStorage.getItem('scope') === 'InvoiceGoScope'
    }
  },
  created: function () {
    this.getUserList()
    this.editFormRules = this.addFormRules
    this.getInvoiceList(this.queryInfo)
  },
  methods: {
    addDialogShow () {
      this.addDialogVisible = true
      this.getInvoiceList(this.queryInfo)
      this.tableImageData = []
      // 来票
      this.addForm.buyer_name = '杭州力码先供应链有限公司'
      this.addForm.buyer_tax_code = '91330105MA28XHWD4R'
      this.addForm.buyer_address_phone = '浙江省杭州市江干区港龙城3区932室0571-57173679'
      this.addForm.buyer_bank_account = '杭州联合农村商业银行股份有限公司彭埠支行201000199659292'
      this.addForm.seller_name = ''
      this.addForm.seller_tax_code = ''
      this.addForm.seller_address_phone = ''
      this.addForm.seller_bank_account = ''
    },
    /** 上传合同附件 */
    uploadInvoiceFile (file, fileList) {
      this.images.file = fileList
      this.fileList = []
      const wfForm = new FormData()
      Object.entries(this.images).forEach(file => {
        file[1].forEach(item => {
          wfForm.append('file', item.raw)
          wfForm.append(item.name, file[0])
        })
      })
      // console.log('wfForm = ' + wfForm)
      this.$http.post('/upload/file', wfForm, { headers: { enctype: 'multipart/form-data' } }).then(
        res => {
          const image = res.data.data
          // image.guid = this.guid()
          image.create_time = moment().valueOf() / 1000
          image.creator = window.sessionStorage.getItem('nickname')
          this.tableImageData.push(image)
          // this.addForm.images.push(image)
        }
      )
    },
    getInvoiceCategoryList () {
      const categoryList = JSON.parse(JSON.stringify(this.options))
      categoryList.shift()
      return categoryList
    },
    /** 获取附件URL */
    getFileUrl (path, filename) {
      return this.$config.image_base_url + path + filename
    },
    /** 获取用户列表信息 */
    getUserList: function () {
      this.$http.get('/user/list').then(
        res => {
          this.userList = res.data.data.rows
          // console.log(this.userList)
        },
        err => {
          this.$message.error({ message: err.response.data.msg })
        }
      )
    },
    /** 监听新增发票对话框关闭事件 */
    addDialogClosed () {
      // this.$message.success('success!')
      this.$refs.addFormRef.resetFields()
    },
    /** 监听编辑发票对话框关闭事件 */
    editDialogClosed () {
      this.$refs.editFormRef.resetFields()
    },
    /** 查询发票列表 */
    searchInvoice: function () {
      this.getInvoiceList(this.queryInfo)
    },
    /** 获取发票列表 */
    getInvoiceList (queryInfo) {
      const invoiceEndTime = this.searchForm.invoice_date
      this.searchForm.buyer_tax_code = '91330105MA28XHWD4R'
      this.searchForm.seller_tax_code = ''
      if (invoiceEndTime) {
        this.searchForm.invoice_date[1] = moment(invoiceEndTime[1]).endOf('day').valueOf()
      }
       this.$http.post(`/invoice/pagination/${queryInfo.page}/${queryInfo.pageSize}`, JSON.stringify(this.searchForm)).then(
        res => {
          console.log('this.searchForm :', JSON.stringify(this.searchForm))
          this.queryInfo.total = res.data.data.total
          this.invoiceListData = res.data.data.rows
        },
        err => {
          this.$message.error({ message: err.response.data.msg })
        }
      )
    },
    /** 保存发票信息 */
    addInvoice () {
      this.$refs.addFormRef.validate(valid => {
        if (!valid) return
        const obj = JSON.parse(JSON.stringify(this.addForm)) /** 避免对象浅拷贝 */
        obj.invoice_date /= 1000
        obj.images = this.tableImageData
        this.$http.post('/invoice', obj).then(
          res => {
            this.$message.success({ message: res.data.msg })
            this.addDialogVisible = false
            this.$refs.addFormRef.resetFields()
            this.invoiceListData = []
            this.getInvoiceList(this.queryInfo)
          },
          err => {
            console.log(err)
            this.$message.error({ message: err.response.data.msg })
          }
        )
      })
    },
    /** 更新发票信息 */
    updateInvoice () {
      this.$refs.editFormRef.validate(valid => {
        if (!valid) return
        const obj = JSON.parse(JSON.stringify(this.editForm)) /** 避免对象浅拷贝 */
        obj.invoice_date /= 1000
        obj.images = this.tableImageData
        this.$http.post('/invoice', obj).then(
          res => {
            this.$message.success({ message: res.data.msg })
            this.editDialogVisible = false
            this.$refs.editFormRef.resetFields()
            this.invoiceListData = []
            this.getInvoiceList(this.queryInfo)
          },
          err => {
            console.log(err)
            this.$message.error({ message: err.response.data.msg })
          }
        )
      })
    },
    /** 获取发票附件列表 */
    getInvoiceFileList: function (invoiceId) {
      this.$http.get(`/image/invoice/${invoiceId}`).then(
        res => {
          this.tableImageData = res.data.data.rows
          console.log('this.tableImageData :', this.tableImageData)
        },
        err => {
          this.$message.error({ message: err.response.data.msg })
        }
      )
    },
    /** 显示编辑发票的对话框 */
    handleEdit (index, row) {
      this.editDialogVisible = true
      this.$http.get(`/invoice/${row.id}`).then(
        res => {
          const obj = res.data.data
          obj.invoice_date *= 1000
          this.editForm = obj
          this.editDialogVisible = true
          this.getInvoiceFileList(row.id)
          this.getInvoiceList(this.queryInfo)
        },
        err => {
          this.$message.error({ message: err.response.data.msg })
        }
      )
    },
    /** 删除发票指定的附件 */
    delInvoiceFileByID: function (index, row) {
      this.$http.delete(`/image/${row.id}`).then(
        res => {
          this.getInvoiceFileList(row.godown_id)
          this.$message.success({ message: res.data.msg })
        }
      )
    },
    /** 删除指定的发票 */
    handleDelete: function (index, row) {
      this.$http.delete(`/invoice/${row.id}`).then(
        res => {
          this.getInvoiceList(this.queryInfo)
          this.$message.success({ message: res.data.msg })
        },
        err => {
          // console.log('err: ' + JSON.stringify(err))
          this.$message.error({ message: err.response.data.msg })
        }
      )
    },
    /** 改变列表显示记录数触发事件 */
    handleSizeChange (val) {
      this.queryInfo.pageSize = val
      this.getInvoiceList(this.queryInfo)
    },
    handleCurrentChange (val) {
      this.queryInfo.page = val
      this.getInvoiceList(this.queryInfo)
    },
    handleChange (val) {
      console.log(val)
    },
    /** 格式化用户名称 */
    formatUserName: function (row, column, cellValue) {
      let user
      for (let i = 0; i < this.userList.length; i++) {
        user = this.userList[i]
        if (user.id === cellValue) {
          return user.nickname
        }
      }
      return window.sessionStorage.getItem('nickname')
    },
    /** 格式化发票状态 */
    formatInvoiceStatus: function (row, column, cellValue) {
      return cellValue === 10 ? '未付' : '已付'
    },
    /** 格式化发票分类 */
    formatInvoiceCategory: function (row, column, cellValue) {
      return cellValue === 10 ? '普通发票' : (cellValue === 20 ? '增值税专用发票' : '增值税普通发票')
    },
    /** 保留小数位数，x原始值，y是小数保留位 */
    toDecimal: function (x, y) {
      let f = parseFloat(x)
      if (isNaN(f)) {
          return false
      }
      f = Math.round(x * 10 ** y) / 10 ** y
      let s = f.toString()
      let rs = s.indexOf('.')
      if (rs < 0) {
          rs = s.length
          s += '.'
      }
      while (s.length <= rs + y) {
          s += '0'
      }
      return s
    },
    toDecimal2: function (row, column, cellValue) {
      return this.toDecimal(cellValue, 2)
    },
    /** 格式化时间戳为标准的时间字符串 */
    formatDate: function (row, column, cellValue) {
      return moment(cellValue * 1000).format('YYYY/MM/DD')
    },
    /** 格式化时间戳为标准的时间字符串 */
    formatDatetime: function (row, column, cellValue) {
      return moment(cellValue * 1000).format('YYYY/MM/DD HH:mm:ss')
    }
  }
}
</script>

<style lang="less" scoped>
.el-input {
  width: 250px;
}
</style>
