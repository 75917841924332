<template>
<div style="height: 100%" >
  <el-breadcrumb separator-class="el-icon-arrow-right">
  <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
  <el-breadcrumb-item>用车管理</el-breadcrumb-item>
  <el-breadcrumb-item>车辆管理</el-breadcrumb-item>
  </el-breadcrumb>
  <!-- 卡片视图区域 -->
  <!-- 搜索视图区 -->
  <el-card>
    <div class="search">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline" style="width: 960px">
        <el-form-item label="车牌号">
          <el-input placeholder="请输入车牌号" v-model="searchForm.plate_no" clearable style="width: 160px"></el-input>
        </el-form-item>
        <el-form-item label="车型">
          <el-select v-model="searchForm.type" placeholder="请选择车型">
              <el-option
                v-for="item in this.vehicle_type_list_all"
                :key="item.name"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="类别">
          <el-select v-model="searchForm.category" placeholder="请选择车辆类别">
              <el-option
                v-for="item in this.vehicle_category_list_all"
                :key="item.name"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
        </el-form-item>
      </el-form>
    </div>
  </el-card>
  <!-- 列表视图区 -->
  <el-card>
    <el-button-group>
      <el-button type="primary" @click="searchVehicle" size="small" icon="el-icon-search">查询</el-button>
      <el-button type="primary" @click="addDialogVisible = true" size="small" icon="el-icon-plus">新增</el-button>
    </el-button-group>
    <el-table :data="tableData" style="width: 100%" stripe>
      <el-table-column label="ID" prop="id" width="60px" fixed="left"></el-table-column>
      <el-table-column label="车牌号" prop="plate_no" width="120px" fixed="left"></el-table-column>
      <el-table-column label="驾驶员" prop="driver_id" width="100px" :formatter="formatDriverName"></el-table-column>
      <el-table-column label="准载证号" prop="vehicle_permit_no" width="120px"></el-table-column>
      <el-table-column label="车辆类别" prop="category" width="120px" :formatter="formatVehicleCategoryName"></el-table-column>
      <el-table-column label="地区" prop="region_id" width="200px" :formatter="formatRegionName"></el-table-column>
      <el-table-column label="购车日期" prop="purchase_date" width="120px" :formatter="formatDate"></el-table-column>
      <el-table-column label="核定载质量" prop="approved_load" width="120px"></el-table-column>
      <el-table-column label="车长" prop="length" width="100px"></el-table-column>
      <el-table-column label="二级维护" prop="sec_maintenance" width="120px"></el-table-column>
      <el-table-column label="二维日期" prop="sec_maintenance_date" width="100px" :formatter="formatDate"></el-table-column>
      <el-table-column label="货保金额" prop="cargo_insurance_sum" width="120px" :formatter="toDecimal2"></el-table-column>
      <el-table-column label="货保日期" prop="cargo_insurance_date" width="100px" :formatter="formatDate"></el-table-column>
      <el-table-column label="保险日期" prop="commercial_insurance_date" width="100px" :formatter="formatDate"></el-table-column>
      <el-table-column label="年审日期" prop="ann_review_date" width="100px" :formatter="formatDate"></el-table-column>
      <el-table-column label="备注" prop="remark" width="300px" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="创建者" prop="creator" width="100px" :formatter="formatUserName"></el-table-column>
      <el-table-column label="创建时间" prop="create_time" width="165px" :formatter="formatDatetime"></el-table-column>
      <el-table-column label="更新者" prop="updator" width="100px" :formatter="formatUserName"></el-table-column>
      <el-table-column label="更新时间" prop="update_time" width="165px" :formatter="formatDatetime"></el-table-column>
      <el-table-column width="200px" fixed="right" align="left" label="操作">
         <template slot-scope="scope">
           <el-button-group>
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-edit"
              @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
            <el-popconfirm
              confirm-button-text='好的'
              cancel-button-text='不用了'
              icon="el-icon-info"
              icon-color="red"
              title="确定要删除吗？"
              @confirm="handleDelete(scope.$index, scope.row)">
                <el-button
                  size="mini"
                  type="danger"
                  icon="el-icon-delete"
                  slot="reference">删除</el-button>
            </el-popconfirm>
           </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页视图区 -->
    <el-pagination background style="width: 100%;margin:15px 0px;" class="pagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryInfo.page"
      :page-sizes="[3, 6, 10, 20]"
      :page-size="queryInfo.pageSize"
      layout="prev, pager, next, jumper,total, sizes"
      :total="queryInfo.total">
   </el-pagination>
    <!-- 新增车辆对话框 -->
    <el-dialog title="新增车辆" :visible.sync="addDialogVisible" @click="addDialogClosed">
      <el-form :model="addForm" ref="addFormRef" :rules="addFormRules" :inline="true" label-width="110px">
      <el-tabs type="border-card">
        <el-tab-pane>
          <span slot="label"><i class="el-icon-tickets"></i>基础信息</span>
          <!-- 基础信息Tab -->
          <el-form-item label="车牌号" prop="plate_no">
            <el-input v-model="addForm.plate_no"></el-input>
          </el-form-item>
          <el-form-item label="驾驶员" prop="driver_id">
            <el-select v-model="addForm.driver_id" style="width: 180px">
              <el-option v-for="item in this.driver_list" :key="item.id" :value="item.id" :label="item.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="车辆类别" prop="category">
            <el-select v-model="addForm.category" style="width: 180px">
              <el-option v-for="item in this.vehicle_category_list" :key="item.id" :value="item.id" :label="item.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="地区" prop="region_id">
            <el-select v-model="addForm.region_id" style="width: 180px">
              <el-option v-for="item in this.region_list" :key="item.id" :value="item.id" :label="item.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="整车质量" prop="gross_mass">
            <el-input v-model="addForm.gross_mass"></el-input>
          </el-form-item>
          <el-form-item label="整备质量" prop="unladen_mass">
            <el-input v-model="addForm.unladen_mass"></el-input>
          </el-form-item>
          <el-form-item label="核定载重" prop="approved_load">
            <el-input v-model="addForm.approved_load"></el-input>
          </el-form-item>
          <el-form-item label="车型" prop="type">
            <el-select v-model="addForm.type" style="width: 180px">
              <el-option v-for="item in this.vehicle_type_list" :key="item.id" :value="item.id" :label="item.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="车长" prop="length">
            <el-input v-model="addForm.length"></el-input>
          </el-form-item>
          <el-form-item label="车宽" prop="width">
            <el-input v-model="addForm.width"></el-input>
          </el-form-item>
          <el-form-item label="车轴数" prop="axles">
            <el-input v-model="addForm.axles"></el-input>
          </el-form-item>
          <el-form-item label="发动机号" prop="engine_no">
            <el-input v-model="addForm.engine_no"></el-input>
          </el-form-item>
          <el-form-item label="车架号" prop="vin_no">
            <el-input v-model="addForm.vin_no"></el-input>
          </el-form-item>
          <el-form-item label="厂牌型号" prop="model">
            <el-input v-model="addForm.model"></el-input>
          </el-form-item>
          <el-form-item label="GPS设备号" prop="gps_no">
            <el-input v-model="addForm.gps_no"></el-input>
          </el-form-item>
          <el-form-item label="SIM卡号" prop="sim_no">
            <el-input v-model="addForm.sim_no"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
          <el-input v-model="addForm.remark" type="textarea" style="width: 475px"></el-input>
        </el-form-item>
        </el-tab-pane>
        <el-tab-pane>
          <span slot="label"><i class="el-icon-tickets"></i>保养与保险</span>
          <!-- 保养与保险Tab -->
          <el-form-item label="二级维护" prop="sec_maintenance">
            <el-input v-model="addForm.sec_maintenance"></el-input>
          </el-form-item>
          <el-form-item label="二维日期" prop="sec_maintenance_date">
            <el-date-picker v-model="addForm.sec_maintenance_date" type="date" value-format="timestamp" style="width: 180px"></el-date-picker>
          </el-form-item>
          <el-form-item label="准载证号" prop="vehicle_permit_no">
            <el-input v-model="addForm.vehicle_permit_no"></el-input>
          </el-form-item>
          <el-form-item label="保险证号" prop="insurance_no">
            <el-input v-model="addForm.insurance_no"></el-input>
          </el-form-item>
          <el-form-item label="货保金额" prop="cargo_insurance_sum">
            <el-input v-model="addForm.cargo_insurance_sum"></el-input>
          </el-form-item>
          <el-form-item label="货保日期" prop="cargo_insurance_date">
            <el-date-picker v-model="addForm.cargo_insurance_date" type="date" value-format="timestamp" style="width: 180px"></el-date-picker>
          </el-form-item>
          <el-form-item label="商业险日期" prop="commercial_insurance_date">
            <el-date-picker v-model="addForm.commercial_insurance_date" type="date" value-format="timestamp" style="width: 180px"></el-date-picker>
          </el-form-item>
          <el-form-item label="交强险日期" prop="traffic_insurance_date">
            <el-date-picker v-model="addForm.traffic_insurance_date" type="date" value-format="timestamp" style="width: 180px"></el-date-picker>
          </el-form-item>
          <el-form-item label="行驶证号" prop="driving_permit_no">
            <el-input v-model="addForm.driving_permit_no"></el-input>
          </el-form-item>
          <el-form-item label="行驶证日期" prop="driving_permit_date">
            <el-date-picker v-model="addForm.driving_permit_date" type="date" value-format="timestamp" style="width: 180px"></el-date-picker>
          </el-form-item>
          <el-form-item label="运营证号" prop="operation_no">
            <el-input v-model="addForm.operation_no"></el-input>
          </el-form-item>
          <el-form-item label="年审日期" prop="ann_review_date">
            <el-date-picker v-model="addForm.ann_review_date" type="date" value-format="timestamp" style="width: 180px"></el-date-picker>
          </el-form-item>
          <el-form-item label="购车日期" prop="purchase_date">
            <el-date-picker v-model="addForm.purchase_date" type="date" value-format="timestamp" style="width: 180px"></el-date-picker>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane>
          <span slot="label"><i class="el-icon-tickets"></i>车主信息</span>
          <!-- 车主信息Tab -->
          <el-form-item label="车主姓名" prop="owner">
            <el-input v-model="addForm.owner"></el-input>
          </el-form-item>
          <el-form-item label="车主电话" prop="phone">
            <el-input v-model="addForm.phone"></el-input>
          </el-form-item>
          <el-form-item label="车辆单位" prop="unit">
            <el-input v-model="addForm.unit"></el-input>
          </el-form-item>
          <el-form-item label="家庭电话" prop="home_phone">
            <el-input v-model="addForm.home_phone"></el-input>
          </el-form-item>
          <el-form-item label="家庭住址" prop="home_address">
            <el-input v-model="addForm.home_address"></el-input>
          </el-form-item>
          <el-form-item label="身份证号" prop="identity">
            <el-input v-model="addForm.identity"></el-input>
          </el-form-item>
          <el-form-item label="五证" prop="five_certificates">
            <el-switch v-model="addForm.five_certificates" style="width: 180px"></el-switch>
          </el-form-item>
          <el-form-item label="照片" prop="photo">
            <el-switch v-model="addForm.photo" style="width: 180px"></el-switch>
          </el-form-item>
          <el-form-item label="档案编号" prop="file_no">
            <el-input v-model="addForm.file_no"></el-input>
          </el-form-item>
          <el-form-item label="建档日期" prop="file_date">
            <el-date-picker v-model="addForm.file_date" type="date" value-format="timestamp" style="width: 180px"></el-date-picker>
          </el-form-item>
        </el-tab-pane>
      </el-tabs>
      </el-form>
      <!-- 底部区域 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addVehicle">保 存</el-button>
      </div>
    </el-dialog>
      <!-- 编辑车辆对话框 -->
    <el-dialog title="编辑车辆" :visible.sync="editDialogVisible" @click="addDialogClosed">
      <el-form :model="editForm" ref="editFormRef" :rules="editFormRules" :inline="true" label-width="110px">
      <el-tabs type="border-card">
        <el-tab-pane>
          <span slot="label"><i class="el-icon-tickets"></i>基础信息</span>
          <!-- 基础信息Tab -->
          <el-form-item label="ID" prop="id">
            <el-input v-model="editForm.id" readonly></el-input>
          </el-form-item>
          <el-form-item label="车牌号" prop="plate_no">
            <el-input v-model="editForm.plate_no"></el-input>
          </el-form-item>
          <el-form-item label="驾驶员" prop="driver_id">
            <el-select v-model="editForm.driver_id" style="width: 180px">
              <el-option v-for="item in this.driver_list" :key="item.id" :value="item.id" :label="item.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="车辆类别" prop="category">
            <el-select v-model="editForm.category" style="width: 180px">
              <el-option v-for="item in this.vehicle_category_list" :key="item.id" :value="item.id" :label="item.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="地区" prop="region_id">
            <el-select v-model="editForm.region_id" style="width: 180px">
              <el-option v-for="item in this.region_list" :key="item.id" :value="item.id" :label="item.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="整车质量" prop="gross_mass">
            <el-input v-model="editForm.gross_mass"></el-input>
          </el-form-item>
          <el-form-item label="整备质量" prop="unladen_mass">
            <el-input v-model="editForm.unladen_mass"></el-input>
          </el-form-item>
          <el-form-item label="核定载重" prop="approved_load">
            <el-input v-model="editForm.approved_load"></el-input>
          </el-form-item>
          <el-form-item label="车型" prop="type">
            <el-select v-model="editForm.type" style="width: 180px">
              <el-option v-for="item in this.vehicle_type_list" :key="item.id" :value="item.id" :label="item.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="车长" prop="length">
            <el-input v-model="editForm.length"></el-input>
          </el-form-item>
          <el-form-item label="车宽" prop="width">
            <el-input v-model="editForm.width"></el-input>
          </el-form-item>
          <el-form-item label="车轴数" prop="axles">
            <el-input v-model="editForm.axles"></el-input>
          </el-form-item>
          <el-form-item label="发动机号" prop="engine_no">
            <el-input v-model="editForm.engine_no"></el-input>
          </el-form-item>
          <el-form-item label="车架号" prop="vin_no">
            <el-input v-model="editForm.vin_no"></el-input>
          </el-form-item>
          <el-form-item label="厂牌型号" prop="model">
            <el-input v-model="editForm.model"></el-input>
          </el-form-item>
          <el-form-item label="GPS设备号" prop="gps_no">
            <el-input v-model="editForm.gps_no"></el-input>
          </el-form-item>
          <el-form-item label="SIM卡号" prop="sim_no">
            <el-input v-model="editForm.sim_no"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
          <el-input v-model="editForm.remark" type="textarea" style="width: 475px"></el-input>
        </el-form-item>
        </el-tab-pane>
        <el-tab-pane>
          <span slot="label"><i class="el-icon-tickets"></i>保养与保险</span>
          <!-- 保养与保险Tab -->
          <el-form-item label="二级维护" prop="sec_maintenance">
            <el-input v-model="editForm.sec_maintenance"></el-input>
          </el-form-item>
          <el-form-item label="二维日期" prop="sec_maintenance_date">
            <el-date-picker v-model="editForm.sec_maintenance_date" type="date" value-format="timestamp" style="width: 180px"></el-date-picker>
          </el-form-item>
          <el-form-item label="准载证号" prop="vehicle_permit_no">
            <el-input v-model="editForm.vehicle_permit_no"></el-input>
          </el-form-item>
          <el-form-item label="保险证号" prop="insurance_no">
            <el-input v-model="editForm.insurance_no"></el-input>
          </el-form-item>
          <el-form-item label="货保金额" prop="cargo_insurance_sum">
            <el-input v-model="editForm.cargo_insurance_sum"></el-input>
          </el-form-item>
          <el-form-item label="货保日期" prop="cargo_insurance_date">
            <el-date-picker v-model="editForm.cargo_insurance_date" type="date" value-format="timestamp" style="width: 180px"></el-date-picker>
          </el-form-item>
          <el-form-item label="商业险日期" prop="commercial_insurance_date">
            <el-date-picker v-model="editForm.commercial_insurance_date" type="date" value-format="timestamp" style="width: 180px"></el-date-picker>
          </el-form-item>
          <el-form-item label="交强险日期" prop="traffic_insurance_date">
            <el-date-picker v-model="editForm.traffic_insurance_date" type="date" value-format="timestamp" style="width: 180px"></el-date-picker>
          </el-form-item>
          <el-form-item label="行驶证号" prop="driving_permit_no">
            <el-input v-model="editForm.driving_permit_no"></el-input>
          </el-form-item>
          <el-form-item label="行驶证日期" prop="driving_permit_date">
            <el-date-picker v-model="editForm.driving_permit_date" type="date" value-format="timestamp" style="width: 180px"></el-date-picker>
          </el-form-item>
          <el-form-item label="运营证号" prop="operation_no">
            <el-input v-model="editForm.operation_no"></el-input>
          </el-form-item>
          <el-form-item label="年审日期" prop="ann_review_date">
            <el-date-picker v-model="editForm.ann_review_date" type="date" value-format="timestamp" style="width: 180px"></el-date-picker>
          </el-form-item>
          <el-form-item label="购车日期" prop="purchase_date">
            <el-date-picker v-model="editForm.purchase_date" type="date" value-format="timestamp" style="width: 180px"></el-date-picker>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane>
          <span slot="label"><i class="el-icon-tickets"></i>车主信息</span>
          <!-- 车主信息Tab -->
          <el-form-item label="车主姓名" prop="owner">
            <el-input v-model="editForm.owner"></el-input>
          </el-form-item>
          <el-form-item label="车主电话" prop="phone">
            <el-input v-model="editForm.phone"></el-input>
          </el-form-item>
          <el-form-item label="车辆单位" prop="unit">
            <el-input v-model="editForm.unit"></el-input>
          </el-form-item>
          <el-form-item label="家庭电话" prop="home_phone">
            <el-input v-model="editForm.home_phone"></el-input>
          </el-form-item>
          <el-form-item label="家庭住址" prop="home_address">
            <el-input v-model="editForm.home_address"></el-input>
          </el-form-item>
          <el-form-item label="身份证号" prop="identity">
            <el-input v-model="editForm.identity"></el-input>
          </el-form-item>
          <el-form-item label="五证" prop="five_certificates">
            <el-switch v-model="editForm.five_certificates" style="width: 180px"></el-switch>
          </el-form-item>
          <el-form-item label="照片" prop="photo">
            <el-switch v-model="editForm.photo" style="width: 180px"></el-switch>
          </el-form-item>
          <el-form-item label="档案编号" prop="file_no">
            <el-input v-model="editForm.file_no"></el-input>
          </el-form-item>
          <el-form-item label="建档日期" prop="file_date">
            <el-date-picker v-model="editForm.file_date" type="date" value-format="timestamp" style="width: 180px"></el-date-picker>
          </el-form-item>
        </el-tab-pane>
      </el-tabs>
      </el-form>
      <!-- 底部区域 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateVehicle">保 存</el-button>
      </div>
    </el-dialog>
  </el-card>
</div>
</template>

<script>
  import moment from 'moment'
  export default {
    name: 'Vehicles',
    data () {
      return {
        tableData: [],
        addDialogVisible: false,
        editDialogVisible: false,
        /** 列表展示过滤条件信息 */
        queryInfo: {
          query: '',
          page: 1,
          pageSize: 10,
          total: 0
        },
        /** 搜索表单 */
        searchForm: {
          plate_no: '',
          type: 0,
          category: 0
        },
        addForm: {
          /** 车辆基本信息 */
          plate_no: '',
          driver_id: 1, /** 驾驶员ID */
          category: 1, /** 车辆类别 */
          region_id: 1, /** 地区 */
          gross_mass: 0, /** 整车质量 */
          unladen_mass: 0, /** 整备质量 */
          approved_load: 0, /** 核定载重 */
          type: 1, /** 类型 */
          length: 0, /** 车长 */
          width: 0, /** 车宽 */
          axles: 0, /** 车轴数 */
          engine_no: '', /** 发动机号 */
          vin_no: '', /** 车架号 */
          model: '', /** 厂牌型号 */
          gps_no: '', /** GPS设备号 */
          sim_no: '', /** SIM卡号 */
          remark: '',
          /** 车辆保养与保险信息 */
          sec_maintenance: '', /** 二级维护 */
          sec_maintenance_date: moment().valueOf(), /** 二维日期 */
          vehicle_permit_no: '', /** 准载证号 */
          insurance_no: '', /** 保险证号 */
          cargo_insurance_sum: 0.00, /** 货保金额 */
          cargo_insurance_date: moment().valueOf(), /** 货保日期 */
          commercial_insurance_date: moment().valueOf(), /** 商业险日期 */
          traffic_insurance_date: moment().valueOf(), /** 交强险日期 */
          driving_permit_no: '', /** 行驶证号 */
          driving_permit_date: moment().valueOf(), /** 行驶证日期 */
          operation_no: '', /** 运营证号 */
          ann_review_date: moment().valueOf(), /** 年审日期 */
          purchase_date: moment().valueOf(), /** 购车日期 */
          /** # 车主信息 */
          owner: '', /** 车主姓名 */
          phone: '', /** 联系电话 */
          unit: '', /** 车辆单位 */
          home_phone: '', /** 家庭电话 */
          home_address: '', /** 家庭地址 */
          identity: '', /** 身份证号 */
          five_certificates: false, /** 五证 */
          photo: false, /** 照片 */
          file_no: '', /** 建档编号 */
          file_date: moment().valueOf() /** 建档日期 */
        },
        addFormRules: {
          plate_no: [
            { required: true, message: '请输入车牌号码', trigger: 'blur' },
            { pattern: /^(([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z](([0-9]{5}[DF])|([DF]([A-HJ-NP-Z0-9])[0-9]{4})))|([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z][A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳使领]))$/, message: '请输入正确的车牌号码', trigger: 'blur' }
          ],
          driver_id: [
            { required: true, message: '请选择驾驶员', trigger: 'blur' }
          ],
          category: [
            { required: true, message: '请选择车辆类别', trigger: 'blur' }
          ],
          region_id: [
            { required: true, message: '请选择地区', trigger: 'blur' }
          ],
          gross_mass: [
            { required: true, message: '请输入整车质量', trigger: 'blur' },
            { pattern: /^[1-9]\d*$/, message: '整车质量必须为正整数', trigger: 'blur' }
          ],
          unladen_mass: [
            { required: true, message: '请输入整备质量', trigger: 'blur' },
            { pattern: /^[1-9]\d*$/, message: '整备质量必须为正整数', trigger: 'blur' }
          ],
          approved_load: [
            { required: true, message: '请输入核定载重', trigger: 'blur' },
            { pattern: /^[1-9]\d*$/, message: '核定载重必须为正整数', trigger: 'blur' }
          ],
          type: [
            { required: true, message: '请选择车辆类型', trigger: 'blur' }
          ],
          length: [
            { required: true, message: '请输入车长数量', trigger: 'blur' },
            { pattern: /^[1-9]\d*$/, message: '车长必须为正整数', trigger: 'blur' }
          ],
          width: [
            { required: true, message: '请输入车宽数量', trigger: 'blur' },
            { pattern: /^[1-9]\d*$/, message: '车宽必须为正整数', trigger: 'blur' }
          ],
          axles: [
            { required: true, message: '请输入车轴数', trigger: 'blur' },
            { pattern: /^[1-9]\d*$/, message: '车轴数必须为正整数', trigger: 'blur' }
          ],
          engine_no: [
            { required: true, message: '请输入发动机号', trigger: 'blur' }
          ],
          vin_no: [
            { required: true, message: '请输入车架号', trigger: 'blur' }
          ],
          model: [
            { required: true, message: '请输入车型', trigger: 'blur' }
          ],
          gps_no: [
            { required: true, message: '请输入GPS设备号', trigger: 'blur' }
          ],
          sim_no: [
            { required: true, message: '请输入SIM卡号', trigger: 'blur' }
          ],
          owner: [
            { required: true, message: '请输入车主姓名', trigger: 'blur' },
            { min: 2, max: 20, message: '长度在2-20个字符之间', trigger: 'blur' }
          ],
          phone: [
            { required: true, message: '请输入车主手机号', trigger: 'blur' },
            { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号', trigger: 'blur' }
          ],
          vehicle_permit_no: [
            { required: true, message: '请输入准载证号', trigger: 'blur' },
            { min: 2, max: 20, message: '长度在2-20个字符之间', trigger: 'blur' }
          ]
        },
        editForm: {},
        editFormRules: {},
        userList: [],
        driver_list: [],
        vehicle_category_list: [],
        vehicle_category_list_all: [],
        vehicle_type_list: [],
        region_list: [],
        vehicle_type_list_all: []
      }
    },
    created () {
      this.getVehicleTypeList()
      this.getVehicleList(this.queryInfo)
      this.getDriverList()
      this.getVehicleCategoryList()
      this.getRegionList()
      this.getUserList()

      this.editFormRules = this.addFormRules
    },
    methods: {
      /** 获取用户列表信息 */
      getUserList: function () {
        this.$http.get('/user/list').then(
          res => {
            this.userList = res.data.data.rows
            // console.log(this.userList)
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      /** 获取地区列表 */
      getRegionList () {
        this.region_list = [
          { id: 1, name: '萧山机场' },
          { id: 2, name: '嘉兴' },
          { id: 3, name: '全国' },
          { id: 4, name: '市区' },
          { id: 5, name: '杭州' },
          { id: 6, name: '机场A' },
          { id: 7, name: '机场B' },
          { id: 8, name: '下沙加工区' },
          { id: 9, name: '上海' }
        ]
      },
      /** 获取车辆类别列表 */
      getVehicleCategoryList () {
        this.vehicle_category_list = [
          { id: 1, name: '内部车辆' },
          { id: 2, name: '外部车辆' },
          { id: 3, name: '挂靠车' }
        ]
        this.vehicle_category_list_all = [
          { id: 0, name: '全部' },
          { id: 1, name: '内部车辆' },
          { id: 2, name: '外部车辆' },
          { id: 3, name: '挂靠车' }
        ]
      },
      /** 获取驾驶员列表 */
      getDriverList () {
        this.$http.get('/driver/list').then(
          res => {
            this.driver_list = res.data.data.rows
          },
          err => {
            this.$message.error(err.response.data.msg)
          }
        )
      },
      /** 添加或者更新车辆信息，通过用户ID来判断 */
      saveUpdateVehicle (form) {
        const _form = JSON.parse(JSON.stringify(form))
        _form.sec_maintenance_date /= 1000
        _form.file_date /= 1000
        _form.ann_review_date /= 1000
        _form.cargo_insurance_date /= 1000
        _form.commercial_insurance_date /= 1000
        _form.traffic_insurance_date /= 1000
        _form.purchase_date /= 1000
        _form.driving_permit_date /= 1000
        this.$http.post('/vehicle', _form).then(
          res => {
            // console.log('res :' + res)
            this.$message.success({ message: res.data.msg })
            this.addDialogVisible = false
            this.editDialogVisible = false
            this.getVehicleList(this.queryInfo)
          },
          err => {
            // console.log('err.response.data :' + JSON.stringify(err.response.data))
            this.$message.error({ message: err.response.data.msg })
          })
      },
      /** 新增车辆 */
      addVehicle () {
        this.$refs.addFormRef.validate(valid => {
          if (!valid) return
          this.saveUpdateVehicle(this.addForm)
        })
      },
      /** 更新车辆 */
      updateVehicle () {
        this.$refs.editFormRef.validate(valid => {
          if (!valid) return
          this.saveUpdateVehicle(this.editForm)
        })
      },
      /** 搜索事件函数 */
      searchVehicle: function () {
        this.getVehicleList(this.queryInfo)
      },
      /** 获取车辆列表 */
      getVehicleList (queryInfo) {
        this.$http.post(`/vehicle/pagination/${queryInfo.page}/${queryInfo.pageSize}`, this.searchForm).then(
          res => {
            // console.log('this.searchForm :', JSON.stringify(this.searchForm))
            this.queryInfo.total = res.data.data.total
            this.tableData = res.data.data.rows
            console.log(this.tableData)
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      /** 显示编辑车辆信息的对话框 */
      handleEdit (index, row) {
        this.editDialogVisible = true
        this.$http.get(`/vehicle/${row.id}`).then(
          res => {
            const vehicle = JSON.parse(JSON.stringify(res.data.data))
            vehicle.sec_maintenance_date *= 1000
            vehicle.file_date *= 1000
            vehicle.ann_review_date *= 1000
            vehicle.cargo_insurance_date *= 1000
            vehicle.commercial_insurance_date *= 1000
            vehicle.traffic_insurance_date *= 1000
            vehicle.purchase_date *= 1000
            vehicle.driving_permit_date *= 1000
            this.editForm = vehicle
            // console.log("this.editForm :", this.editForm.id);
            this.editDialogVisible = true
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      /** 删除指定的车辆记录 */
      handleDelete: function (index, row) {
        // console.log(row)
        /** 删除前，先确认数据是否在其他地方被引用 */
        this.$http.get(`/vehiclefee/plate_no/${row.id}`).then(
          res => {
            // console.log(res.data.data)
            // const rows = res.data.data
            this.$message.error({ message: '已被其他地方引用，您不能删除！' })
          },
          () => {
            this.$http.delete(`/vehicle/${row.id}`).then(
              res => {
                this.getVehicleList(this.queryInfo)
                this.$message({ message: res.data.msg, type: 'success' })
              },
              err => {
                // console.log('err: ' + JSON.stringify(err))
                this.$message({ message: err.response.data.msg, type: 'error' })
              }
            )
          }
        )
      },
      /** 监听添加车辆对话框关闭事件 */
      addDialogClosed () {
        this.$refs.addFormRef.resetFields()
      },
      /** 监听修改车辆对话框关闭事件 */
      editDialogClosed () {
        this.$refs.editFormRef.resetFields()
      },
      /** 格式化驾驶员名称 */
      formatDriverName: function (row, column, cellValue) {
        let levelName
        for (let i = 0; i < this.driver_list.length; i++) {
          levelName = this.driver_list[i]
          if (levelName.id === cellValue) {
            return levelName.name
          }
        }
      },
      /** 格式化车辆类型名称 */
      formatVehicleCategoryName: function (row, column, cellValue) {
        let typeName
        for (let i = 0; i < this.vehicle_category_list.length; i++) {
          typeName = this.vehicle_category_list[i]
          if (typeName.id === cellValue) {
            return typeName.name
          }
        }
      },
      /** 格式化地区名称 */
      formatRegionName: function (row, column, cellValue) {
        let typeName
        for (let i = 0; i < this.region_list.length; i++) {
          typeName = this.region_list[i]
          if (typeName.id === cellValue) {
            return typeName.name
          }
        }
      },
      /** 格式化用户名称 */
      formatUserName: function (row, column, cellValue) {
        let user
        for (let i = 0; i < this.userList.length; i++) {
          user = this.userList[i]
          if (user.id === cellValue) {
            return user.nickname
          }
        }
        return window.sessionStorage.getItem('nickname')
      },
      /** 格式化时间戳为标准的时间字符串 */
      formatDatetime: function (row, column, cellValue) {
        return moment(cellValue * 1000).format('YYYY/MM/DD HH:mm:ss')
      },
      /** 格式化时间戳为标准的时间字符串 */
      formatDate: function (row, column, cellValue) {
        return moment(cellValue * 1000).format('YYYY/MM/DD')
      },
      /** 保留小数位数，x原始值，y是小数保留位 */
      toDecimal: function (x, y) {
        let f = parseFloat(x)
        if (isNaN(f)) {
            return false
        }
        f = Math.round(x * 10 ** y) / 10 ** y
        let s = f.toString()
        let rs = s.indexOf('.')
        if (rs < 0) {
            rs = s.length
            s += '.'
        }
        while (s.length <= rs + y) {
            s += '0'
        }
        return s
      },
      toDecimal3: function (row, column, cellValue) {
        return this.toDecimal(cellValue, 3)
      },
      toDecimal2: function (row, column, cellValue) {
        return this.toDecimal(cellValue, 2)
      },
      /** 分页组件改变每页显示记录数 */
      handleSizeChange (val) {
        // console.log(`每页 ${val} 条`)
        this.queryInfo.pageSize = val
        this.getVehicleList(this.queryInfo)
      },
      /** 分页组件改变当前显示页码数 */
      handleCurrentChange (val) {
        // console.log(`当前页: ${val}`)
        this.queryInfo.page = val
        this.getVehicleList(this.queryInfo)
      },
      getVehicleTypeList () {
        this.vehicle_type_list = [{
          id: 1,
          name: '厢车'
        }, {
          id: 2,
          name: '厢货'
        }, {
          id: 3,
          name: '面包车'
        }, {
          id: 4,
          name: '15坐全顺'
        }, {
          id: 5,
          name: '8坐面包车'
        }]
        this.vehicle_type_list_all = JSON.parse(JSON.stringify(this.vehicle_type_list))
        this.vehicle_type_list_all.unshift({ id: 0, name: '全部' })
        console.log(this.vehicle_type_list)
        console.log(this.vehicle_type_list_all)
      }
    }
  }
</script>

<style scoped>

</style>
