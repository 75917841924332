<template>
  <div  style="height: 100%" >
    <el-breadcrumb separator-class="el-icon-arrow-right">
    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
    <el-breadcrumb-item>海关管理</el-breadcrumb-item>
    <el-breadcrumb-item>实时监控</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图区域 -->
    <div  id="completeDiv" style="height: 100%" >
      <iframe :src="vehicleUrl" frameborder="0" scrolling="no" width="100%" height="100%"></iframe>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Monitor',
    data () {
      return {
        vehicleUrl: 'https://pageapi.gpsoo.net/third?method=jump&page=monitor&locale=zh-cn&account=zheng9527&target=zheng9527&appkey=3b9873ec8ba4ddb5cbea1b8a2315fa97&t=1623373541536'
        // vehicleUrl: 'http://www.gpsoo.net/pageapi/monitor.html?page=monitor&sign=20007117140710162349415832f29071658a49451dfce6a529ee6c11b50000010018010&objectid=1171407&locale=zh-cn&custname=zheng9527'
      }
    },
    created () {
      // this.setIframeHeight(document.getElementById('external-frame'))
    },
    methods: {
      // setIframeHeight (iframe) {
      //   if (iframe) {
      //     const iframeWin = iframe.contentWindow || iframe.contentDocument.parentWindow
      //       if (iframeWin.document.body) {
      //         iframe.height = iframeWin.document.documentElement.scrollHeight || iframeWin.document.body.scrollHeight
      //       }
      //   }
      // }
    }
  }
</script>

<style scoped>

</style>
