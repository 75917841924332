<template>
<div>
 <el-breadcrumb separator-class="el-icon-arrow-right">
    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
    <el-breadcrumb-item>成本管理</el-breadcrumb-item>
    <el-breadcrumb-item>仓库费用</el-breadcrumb-item>
 </el-breadcrumb>
  <el-card>
    <el-form v-model="searchForm" :inline="true">
      <el-form-item label="主运单号">
        <el-input v-model="searchForm.mawbn" placeholder="请输入主运单号" clearable style="width: 150px"></el-input>
      </el-form-item>
      <el-form-item label="进仓编号">
        <el-input v-model="searchForm.store_no" placeholder="请输入进仓编号" clearable style="width: 150px"></el-input>
      </el-form-item>
      <el-form-item label="确认状态">
        <el-select v-model="searchForm.confirm" placeholder="请选择确认状态" clearable style="width: 90px">
          <el-option
            v-for="item in confirm_options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属客户">
          <el-select v-model="searchForm.cus_id" placeholder="请选择客户" style="width: 340px">
            <el-option
              v-for="item in this.customer_list_all"
              :key="item.name"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      <el-form-item label="入库日期">
        <el-date-picker value-format="timestamp" v-model="searchForm.inbound_time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" style="width: 240px">
        </el-date-picker>
      </el-form-item>
      <!--el-form-item label="出库日期">
        <el-date-picker value-format="timestamp" v-model="searchForm.outbound_time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" style="width: 250px">
        </el-date-picker>
      </el-form-item-->
    </el-form>
  </el-card>
  <el-card>
    <el-button-group>
      <el-button type="primary" @click="searchGodown" size="small" icon="el-icon-search">查询</el-button>
      <el-button type="primary" v-loading.fullscreen.lock="fullscreenLoading" @click="exportExcel" size="small" icon="el-icon-document">导出EXCEL</el-button>
    </el-button-group>
    <el-table :data="tableData" style="width: 100%" stripe  show-summary :summary-method="getSummaries">
      <el-table-column label="业务编号" prop="id" width="80px" fixed="left" v-if="show"></el-table-column>
      <el-table-column label="主运单号" prop="mawbn" width="150px" fixed="left" :show-overflow-tooltip="true">
        <template scope="scope">
          <div class="input-box">
            <el-input v-if="scope.row.confirm === 0" size="small" v-model="scope.row.mawbn" ></el-input>
            <span style="margin-left: 10px" v-else>{{ scope.row.mawbn }}</span>
          </div>
       </template>
      </el-table-column>
      <el-table-column label="进仓编号" prop="store_no" width="150px" fixed="left" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="中文品名" prop="product_name" width="150px" :show-overflow-tooltip="true"></el-table-column>
      <!--el-table-column label="是否出库" prop="is_bound" width="100px" :formatter="formatYesNO"></el-table-column-->
      <el-table-column label="所属客户" prop="cus_id" width="200px" :formatter="formatCustomerName" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="入库时间" prop="inbound_time" width="165px" :formatter="formatDatetime"></el-table-column>
      <el-table-column label="出库时间" prop="outbound_time" width="165px" :formatter="formatDatetime"></el-table-column>
      <el-table-column label="件数" prop="quantity" width="100px"></el-table-column>
      <el-table-column label="应付仓库杂费" prop="warehouse_fee" width="110px" :formatter="toDecimal2"></el-table-column>
      <el-table-column label="仓库杂费备注" prop="fee_remark" width="160px" :show-overflow-tooltip="true">
        <template scope="scope">
          <div class="input-box">
            <el-input v-if="scope.row.confirm === 0 && !isCustomerScope" size="small" v-model="scope.row.fee_remark" ></el-input>
            <span style="margin-left: 10px" v-else>{{ scope.row.fee_remark }}</span>
          </div>
       </template>
      </el-table-column>
      <el-table-column label="车费" prop="warehouse_fare" width="120px">
        <template scope="scope">
          <el-input v-if="scope.row.confirm === 0 && !isCustomerScope" size="small" v-model="scope.row.warehouse_fare"></el-input>
          <span style="margin-left: 10px" v-else>{{ toDecimal(scope.row.warehouse_fare, 2) }}</span>
       </template>
      </el-table-column>
      <el-table-column label="车费备注" prop="fare_remark" width="160px" :show-overflow-tooltip="true">
        <template scope="scope">
          <div class="input-box">
             <el-input v-if="scope.row.confirm === 0 && !isCustomerScope" size="small" v-model="scope.row.fare_remark" ></el-input>
            <span style="margin-left: 10px" v-else>{{ scope.row.fare_remark }}</span>
          </div>
       </template>
      </el-table-column>
      <el-table-column label="操作费" prop="handling_charge" width="120px" :formatter="toDecimal2">
        <template scope="scope">
          <div class="input-box">
             <el-input v-if="scope.row.confirm === 0 && !isCustomerScope" size="small" v-model="scope.row.handling_charge" ></el-input>
            <span style="margin-left: 10px" v-else>{{ toDecimal(scope.row.handling_charge, 2) }}</span>
          </div>
       </template>
      </el-table-column>
      <el-table-column label="操作费备注" prop="charge_remark" width="160px" :show-overflow-tooltip="true">
        <template scope="scope">
          <div class="input-box">
             <el-input v-if="scope.row.confirm === 0 && !isCustomerScope" size="small" v-model="scope.row.charge_remark" ></el-input>
            <span style="margin-left: 10px" v-else>{{ scope.row.charge_remark }}</span>
          </div>
       </template>
      </el-table-column>
      <el-table-column label="总收入" prop="total_cost" width="100px" :formatter="computeMoney2Decimal2"></el-table-column>
      <el-table-column label="总支出" prop="expense" width="100px" :formatter="toDecimal2">
        <template scope="scope">
          <div class="input-box">
            <el-input v-if="scope.row.confirm === 0 && !isCustomerScope" size="small" v-model="scope.row.expense"></el-input>
            <span style="margin-left: 10px" v-else>{{ toDecimal(scope.row.expense, 2) }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="创建者" prop="creator" width="120px" :formatter="formatUserName"></el-table-column>
      <el-table-column label="创建时间" prop="create_time" width="165px" :formatter="formatDatetime"></el-table-column>
      <el-table-column label="更新者" prop="updator" width="120px" :formatter="formatUserName"></el-table-column>
      <el-table-column label="更新时间" prop="update_time" width="165px" :formatter="formatDatetime"></el-table-column>
      <el-table-column label="操作" width="180px" fixed="right" align="left">
       <template slot-scope="scope">
         <el-button-group>
          <el-button v-if="scope.row.confirm === 0 && (isBalanceScope || isAdminScope)"
            size="mini"
            type="primary"
            icon="el-icon-edit"
            @click="handleSave(scope.$index, scope.row)">保存</el-button>
           <el-popconfirm
                confirm-button-text='好的'
                cancel-button-text='不用了'
                icon="el-icon-info"
                icon-color="red"
                title="真的要确认吗？"
                @confirm="handleConfirm(scope.$index, scope.row)">
              <el-button v-if="scope.row.confirm === 0 && (isCustomerScope || isAdminScope)"
                size="mini"
                type="warning"
                icon="el-icon-check"
                slot="reference">确认</el-button>
           </el-popconfirm>
           <el-popconfirm
                confirm-button-text='好的'
                cancel-button-text='不用了'
                icon="el-icon-info"
                icon-color="red"
                title="真的要取消确认吗？"
                @confirm="cancelConfirm(scope.$index, scope.row)">
               <el-button v-if="scope.row.confirm === 1 && isAdminScope"
                size="mini"
                type="danger"
                icon="el-icon-close"
                slot="reference">取消确认</el-button>
           </el-popconfirm>
         </el-button-group>
      </template>
    </el-table-column>
    </el-table>
    <el-pagination background style="width: 100%;margin:15px 0px;" class="pagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryInfo.page"
      :page-sizes="[3, 6, 10, 20]"
      :page-size="queryInfo.pageSize"
      layout="prev, pager, next, jumper,total, sizes"
      :total="queryInfo.total">
     </el-pagination>
  </el-card>
</div>
</template>

<script>
  import moment from 'moment'
  export default {
    name: 'Godownfee',
    data () {
      return {
        fullscreenLoading: false,
        show: false,
        tableData: [],
        queryInfo: {
          query: '',
          page: 1,
          pageSize: 10,
          total: 0
        },
        /** 搜索表单对象 */
        searchForm: {
          mawbn: '',
          store_no: '',
          confirm: -1,
          inbound_time: [moment().subtract(1, 'months').valueOf(), moment().valueOf()],
          outbound_time: [moment().subtract(1, 'months').valueOf(), moment().valueOf()]
        },
        confirm_options: [{
            value: -1,
            label: '全部'
          }, {
            value: 0,
            label: '未确认'
          }, {
            value: 1,
            label: '已确认'
        }],
        isAdminScope: window.sessionStorage.getItem('scope') === 'AdminScope',
        isCustomerScope: window.sessionStorage.getItem('scope') === 'CustomerScope',
        isBalanceScope: window.sessionStorage.getItem('scope') === 'BalanceScope',
        sum_list: []
      }
    },
    created () {
      this.getGodownList(this.queryInfo)
      this.getCustomerList()
      this.getUserList()
    },
    methods: {
      /** 自定义合计列算法 */
      getSummaries (param) {
        const columns = param.columns
        const sums = []

        sums[0] = '合计'
        for (const key in this.sum_list) {
          for (let i = 1; i < columns.length; i++) {
            // console.log('column.property = ' + columns[i].property)
            if (columns[i].property === key) {
              sums[i] = this.sum_list[key]
              continue
            }
          }
        }
        // sums[0] = '合计'
        // sums[6] = this.sum_list.quantity
        // sums[7] = this.sum_list.warehouse_fee
        // sums[9] = this.sum_list.warehouse_fare
        // sums[11] = this.sum_list.handling_charge
        // sums[13] = this.sum_list.total_cost

        return sums
      },
      exportExcel () {
        this.fullscreenLoading = true
        this.$http.post('/godown/excel/wh_fee', this.searchForm, { responseType: 'blob' }).then(
          res => {
            console.log(res)
            const blob = new Blob([res.data], { type: 'application/vnd.ms-excel;charset=utf-8' })
            const fileName = '仓库费用.xls'
            const elink = document.createElement('a')
            elink.download = fileName
            elink.style.display = 'none'
            elink.href = URL.createObjectURL(blob)
            document.body.appendChild(elink)

            elink.click()
            URL.revokeObjectURL(elink.href)
            document.body.removeChild(elink)

            this.fullscreenLoading = false
          },
          err => {
            this.$message.error(err.response.data.msg)
          }
        )
      },
      loseFcous (index, row) {
        // debugger
        row.seen = false
        console.log('lostFcous: ' + row.seen)
      },
      cellClick (row, column) {
        // debugger
        row.seen = true
        console.log('cellClick: ' + row.seen)
      },
      /** 确认事件 */
      handleConfirm: function (index, row) {
        row.confirm = 1
        this.$http.post('/godown', JSON.stringify(row)).then(
          res => {
            this.getGodownList(this.queryInfo)
            this.$message.success({ message: res.data.msg })
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      /** 取消确认事件 */
      cancelConfirm: function (index, row) {
        row.confirm = 0
        this.$http.post('/godown', JSON.stringify(row)).then(
          res => {
            this.getGodownList(this.queryInfo)
            this.$message.success({ message: res.data.msg })
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      /** 记录保存 */
      handleSave: function (index, row, column, cell) {
        console.log(JSON.stringify(row))
        this.$http.post('/godown', JSON.stringify(row)).then(
          res => {
            this.$message.success({ message: res.data.msg })
            this.getGodownList(this.queryInfo)
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      /** 查询客户列表 */
      searchGodown: function () {
        this.getGodownList(this.queryInfo)
      },
      /** 获取进仓单列表 */
      getGodownList (queryInfo) {
        const outEndTime = this.searchForm.outbound_time
        if (outEndTime) {
          this.searchForm.outbound_time[1] = moment(outEndTime[1]).endOf('day').valueOf()
        }
        const inEndTime = this.searchForm.inbound_time
        if (inEndTime) {
          this.searchForm.inbound_time[1] = moment(inEndTime[1]).endOf('day').valueOf()
        }
        this.$http.post(`/godown/pagination/${queryInfo.page}/${queryInfo.pageSize}`, this.searchForm).then(
          res => {
            console.log('sum = ' + JSON.stringify(res.data.data.sum))
            // console.log('this.searchForm :', JSON.stringify(this.searchForm))
            this.queryInfo.total = res.data.data.total
            this.tableData = res.data.data.rows
            this.sum_list = res.data.data.sum
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      /** 获取客户列表信息 */
      getCustomerList: function () {
        this.$http.get('/customer/list').then(
          res => {
            this.customerList = res.data.data.rows

            this.customer_list_all = JSON.parse(JSON.stringify(this.customerList))
            this.customer_list_all.unshift({ id: 0, name: '全部' })
            // console.log(this.customerList)
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      /** 格式化客户名称 */
      formatCustomerName: function (row, column, cellValue) {
        let customer
        for (let i = 0; i < this.customerList.length; i++) {
          customer = this.customerList[i]
          if (customer.id === cellValue) {
            return customer.name
          }
        }
      },
      /** 获取用户列表信息 */
      getUserList: function () {
        this.$http.get('/user/list').then(
          res => {
            this.userList = res.data.data.rows
            // console.log(this.userList)
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      /** 格式化收费模式 */
      formatChargeMode: function (row, column, cellValue) {
        return cellValue === 1 ? '件数' : cellValue === 2 ? '按质量' : '按体积'
      },
      /** 格式化是否名称 */
      formatYesNO: function (row, column, cellValue) {
        return cellValue === 0 ? '否' : '是'
      },
      /** 格式化用户名称 */
      formatUserName: function (row, column, cellValue) {
        let user
        for (let i = 0; i < this.userList.length; i++) {
          user = this.userList[i]
          if (user.id === cellValue) {
            return user.nickname
          }
        }
      },
      /** 格式化时间戳为标准的时间字符串 */
      formatDatetime: function (row, column, cellValue) {
        return moment(cellValue * 1000).format('YYYY/MM/DD HH:mm:ss')
      },
      /** 改变列表显示记录数触发事件 */
      handleSizeChange (val) {
        this.queryInfo.pageSize = val
        this.getGodownList(this.queryInfo)
      },
      /** 改变列表当前显示页码触发事件 */
      handleCurrentChange (val) {
        this.queryInfo.page = val
        this.getGodownList(this.queryInfo)
      },
      /** 保留小数位数，x原始值，y是小数保留位 */
      toDecimal: function (x, y) {
        let f = parseFloat(x)
        if (isNaN(f)) {
            return false
        }
        f = Math.round(x * 10 ** y) / 10 ** y
        let s = f.toString()
        let rs = s.indexOf('.')
        if (rs < 0) {
            rs = s.length
            s += '.'
        }
        while (s.length <= rs + y) {
            s += '0'
        }
        return s
      },
      toDecimal3: function (row, column, cellValue) {
        return this.toDecimal(cellValue, 3)
      },
      toDecimal2: function (row, column, cellValue) {
        return this.toDecimal(cellValue, 2)
      },
      computeMoney2Decimal2: function (row, column, cellValue) {
        const total = parseFloat(row.warehouse_fare) + parseFloat(row.warehouse_fee) + parseFloat(row.handling_charge)
        return this.toDecimal(total, 2)
      }
    }
  }
</script>

<style scoped>
.tb-edit .input-box {
   display: none
}
.tb-edit .current-cell .input-box {
   display: block;
   margin-left: -10px;
}
</style>
