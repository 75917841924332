import Vue from 'vue'
import ECharts from 'vue-echarts'
// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from 'echarts/core'
// 引入柱状图图表，图表后缀都为 Chart
import {
  PieChart, LineChart, BarChart, MapChart
} from 'echarts/charts'
// 引入直角坐标系组件，组件后缀都为 Component
import {
  TitleComponent, TooltipComponent, LegendComponent, GridComponent, VisualMapComponent, ToolboxComponent
} from 'echarts/components'
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from 'echarts/renderers'
// 注册必须的组件
echarts.use([
    CanvasRenderer,
    PieChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    LineChart,
    GridComponent,
    BarChart,
    MapChart,
    VisualMapComponent,
    ToolboxComponent
  ])
Vue.prototype.$echarts = echarts
Vue.component('v-chart', ECharts)
