import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import { Base64 } from 'js-base64'
import './plugins/echart.js'
// 导入全局样式表
import './assets/css/global.css'
import axios from 'axios'
// import moment from 'moment'
import { config } from '../config.js'
import { FormatTools } from '../util/format.js'
import moment from 'moment'

// import 'amfe-flexible'

const formatTools = new FormatTools()

document.title = '力码先管理系统V1.0'

// 配置请求的根路径
axios.defaults.baseURL = config.api_base_url
axios.defaults.headers.post['Content-Type'] = 'application/json'
// 发起请求拦截器
axios.interceptors.request.use(config => {
  // console.log(config)
  const token = window.sessionStorage.getItem('token')
  if (token) {
    config.headers.Authorization = 'Basic ' + Base64.encode(token + ':')
    // console.log('token: ' + token)
  }
  return config
})
// 返回结果拦截器
axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  /** token过期或者不合法，返回401认证失败， 则直接跳转到登录页面 */
  if (error.response.status === 401) {
    window.location.href = '#/login'
  }
  return Promise.reject(error)
})

Vue.prototype.$http = axios
Vue.prototype.$config = config
Vue.prototype.$format = formatTools
Vue.prototype.$moment = moment

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
