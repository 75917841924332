import Vue from 'vue'
import {
  Button, Form, FormItem, Input, Message, Container, Header, Aside, Main, Menu, Submenu, MenuItem, Breadcrumb,
  BreadcrumbItem, Card, Select, Option, Table, TableColumn, Popconfirm, Pagination, Dialog, ButtonGroup, Switch,
  DatePicker, Divider, Tabs, TabPane, Checkbox, Upload, Image, Footer, Dropdown, DropdownItem, DropdownMenu, Loading,
  Autocomplete, Tag, Link, Row, Col, Radio, RadioGroup, Descriptions, DescriptionsItem, Drawer, Timeline, TimelineItem,
  Collapse, CollapseItem
} from 'element-ui'

Vue.use(Button)
Vue.use(ButtonGroup)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)

Vue.use(Container)
Vue.use(Header)
Vue.use(Aside)
Vue.use(Main)

Vue.use(Menu)
Vue.use(Submenu)

Vue.use(MenuItem)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Card)

Vue.use(Select)
Vue.use(Option)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Popconfirm)
Vue.use(Pagination)
Vue.use(Dialog)
Vue.use(Switch)
Vue.use(DatePicker)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Divider)
Vue.use(Checkbox)
Vue.use(Upload)
Vue.use(Image)
Vue.use(Footer)
Vue.use(Loading)

Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Autocomplete)
Vue.use(Tag)
Vue.use(Link)
Vue.use(Row)
Vue.use(Col)
Vue.use(Radio)
Vue.use(RadioGroup)

Vue.use(Descriptions)
Vue.use(DescriptionsItem)

Vue.use(Drawer)
Vue.use(Timeline)
Vue.use(TimelineItem)

Vue.use(Collapse)
Vue.use(CollapseItem)

Vue.prototype.$message = Message
