<template>
<div class="login_container">
  <div class="login_box">
    <div class="avatar_box">
      <img src="../assets/logo.png">
    </div>
    <el-form ref="loginFormRef" :model="loginForm" :rules="loginFormRules" label-width="0px" class="login_form">
      <el-form-item prop="username" >
        <el-input v-model="loginForm.username" prefix-icon="el-icon-user" placeholder="请输入用户名" clearable></el-input>
      </el-form-item>
      <el-form-item prop="password" >
        <el-input v-model="loginForm.password" show-password prefix-icon="el-icon-lock" placeholder="请输入用户密码" clearable></el-input>
      </el-form-item>
      <el-form-item class="btns">
        <el-button type="primary" @click="login">登录</el-button>
        <el-button type="info" @click="resetLoginForm">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
  <div class="footer_box copyright">
    <span>Copyright © 2021 杭州力码先供应链有限公司 All Rights Reserved.</span>
    <br />
    <span>Email : 43467142@qq.com</span><span> QQ : 43467142</span>
    <br />
    <span> <a target="_blank" style="text-decoration:none; color: white;" href="https://beian.miit.gov.cn/">浙ICP备2024093671号-1</a> </span>
  </div>
</div>
</template>

<script>
export default {
  data () {
    return {
      loginForm: {
        username: '',
        password: '',
        type: 300
      },
      loginFormRules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { min: 3, max: 15, message: '长度在 5 到 20 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入用户密码', trigger: 'blur' },
          { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  mounted () {
    // 绑定事件，其他界面可能要写在methods里
    window.addEventListener('keydown', this.keyDown)
  },
  methods: {
    // 绑定监听事件
    keyDown (e) {
      // 如果是按回车则执行登录方法
      if (e.keyCode === 13) {
        this.login()
      }
    },
    // 销毁事件
    dstroyed () {
      window.removeEventListener('keydown', this.keyDown, false)
    },
    /** 用户名和密码重置 */
    resetLoginForm () {
      // console.log(this)
      this.$refs.loginFormRef.resetFields()
    },
    /** 用户登录 */
    login () {
      this.$refs.loginFormRef.validate(valid => {
        // console.log(valid)
        if (!valid) return
        this.$http.post('/token', this.loginForm).then(
          res => {
            // console.log(res.data)

            this.$message.success('登录成功！')
            window.sessionStorage.setItem('token', res.data.data.token)
            window.sessionStorage.setItem('uid', res.data.data.userInfo.uid)
            window.sessionStorage.setItem('nickname', res.data.data.userInfo.nickname)
            window.sessionStorage.setItem('scope', res.data.data.userInfo.scope)

            let next = this.$router.app._route.query.redirect
            if (next === undefined) {
              next = '/welcome'
            }
            this.$router.push(next)

            // const curScope = res.data.data.userInfo.scope
            // if (curScope === 'AdminScope' || curScope === 'RecorderScope') {
            //   this.$router.push('/godownlist')
            // } else {
            //   this.$router.push('/godownfee')
            // }
            this.dstroyed()
          },
          err => {
            console.log(err)
            this.$message.error('登录失败！' + err.msg)
          })
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .login_container {
    background-color: #2b4b6b;
    height: 100vh;
  }
  .login_box {
    width: 450px;
    height: 300px;
    background-color: #fff;
    border-radius: 3px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .avatar_box{
      height: 130px;
      width: 130px;
      border: 1px solid #eee;
      border-radius: 50%;
      padding: 10px;
      box-shadow: 0 0 10px;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;
      img {
        height: 100%;
        width: 100%;
        border-radius: 50%;
        background-color: #eee;
      }
    }
    .login_form {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 0 20px;
      box-sizing: border-box;
    }
    .btns {
      display: flex;
      justify-content: flex-end;
    }
  }
  .footer_box{
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      color: #fff;
    }
  .copyright {
    font-family: "PingFangSC-Thin";
    /*height: 80px;*/
    /*margin-top: -80px;*/
    /*background-color: #3333FF;*/
    font-size: 16px;
    /*color: #999;*/
    margin: 0 auto;
    width: 100%;
    text-align: center;
    padding: 10px 0
  }
</style>
