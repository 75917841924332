<template>
<div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
    <el-breadcrumb-item>报关管理</el-breadcrumb-item>
    <el-breadcrumb-item>订单列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图区域 -->
    <!-- 搜索视图区 -->
    <el-card>
      <div class="search">
        <el-form :inline="true" :model="searchForm" class="demo-form-inline" style="width: 960px">
<!--          <el-form-item label="订单号">-->
<!--            <el-autocomplete :fetch-suggestions="querySearchAsync" :trigger-on-focus="false" @select="handleSelect" placeholder="请输入订单号" v-model="searchForm.order_id" clearable style="width: 160px"></el-autocomplete>-->
<!--          </el-form-item>-->
          <el-form-item label="商品名">
            <el-input placeholder="请输入商品名" v-model="searchForm.goods" clearable style="width: 160px"></el-input>
          </el-form-item>
          <el-form-item label="订单日期">
          <el-date-picker value-format="timestamp" v-model="searchForm.create_time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" style="width: 300px">
          </el-date-picker>
        </el-form-item>
        </el-form>
      </div>
    </el-card>
    <!-- 列表视图区 -->
    <el-card>
      <el-button-group>
        <el-button type="primary" @click="searchOrderList" size="small" icon="el-icon-search">查询</el-button>
<!--      <el-button type="primary" @click="addDialogShow" style="margin: 15px 1px" size="small" icon="el-icon-plus">新增</el-button>-->
      </el-button-group>
      <el-table :data="tableData" style="width: 100%" stripe>
        <el-table-column label="ID" prop="id" width="80px" fixed="left"></el-table-column>
        <el-table-column label="订单号" prop="order.code" width="180px" :show-overflow-tooltip="true" fixed="left"></el-table-column>
        <el-table-column label="订单日期" prop="order.order_time" width="125px" :formatter="formatDate"></el-table-column>
        <el-table-column label="商品名" prop="goods" width="200px" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column label="规格" prop="specs" width="210px" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column label="数量" prop="quantity" width="120px"></el-table-column>
        <el-table-column label="重量(KG)" prop="weight" width="120px" :formatter="toDecimal3"></el-table-column>
        <el-table-column label="金额($)" prop="price" width="120px" :formatter="toDecimal3"></el-table-column>
<!--        <el-table-column label="创建者" prop="creator" width="100px" :formatter="formatUserName"></el-table-column>-->
        <el-table-column label="创建时间" prop="create_time" width="165px" :formatter="formatDatetime"></el-table-column>
<!--        <el-table-column label="更新者" prop="updator" width="100px" :formatter="formatUserName"></el-table-column>-->
        <el-table-column label="更新时间" prop="update_time" width="165px" :formatter="formatDatetime"></el-table-column>
      </el-table>
      <!-- 分页视图区 -->
      <el-pagination background style="width: 100%;margin:15px 0px;" class="pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[3, 6, 10, 20]"
        :page-size="queryInfo.pageSize"
        layout="prev, pager, next, jumper,total, sizes"
        :total="queryInfo.total">
     </el-pagination>
    </el-card>
</div>
</template>

<script>
  import moment from 'moment'
  export default {
    name: 'orderlist',
    data () {
      return {
        // orders: [],
        // orderId: '',
        // timeout: null,
        userList: [],
        addDialogVisible: false,
        editDialogVisible: false,
        addForm: {
          code: '',
          goods_num: 0,
          order_weight: 0.000,
          order_sum: 0.000,
          order_time: 0.000,
          template_id: 0,
          remark: ''
        },
        tableData: [],
        /** 列表展示过滤条件信息 */
        queryInfo: {
          query: '',
          page: 1,
          pageSize: 10,
          total: 0
        },
        /** 搜索表单 */
        searchForm: {
          order_id: '',
          goods: '',
          create_time: [moment().startOf('day').valueOf(), moment().endOf('day').valueOf()]
        }
      }
    },
    created () {
      this.getUserList()
      this.getOrderdetailList(this.queryInfo)
    },
    /**
    mounted () {
      this.orders = this.loadAll()
    }, */
    methods: { /**
      loadAll () {
        this.$http.get('/order/list').then(
          res => {
            this.orders = res.data.data.rows
            console.log(this.orders)
          },
          err => {
            this.$message({ message: err.response.data.msg })
          }
        )
      },
      querySearchAsync (queryString, cb) {
        const orders = this.orders
        const results = queryString ? orders.filter(this.createStateFilter(queryString)) : orders

        // clearTimeout(this.timeout)
        // this.timeout = setTimeout(() => {
        cb(results)
        // }, 3000 * Math.random())
      },
      createStateFilter (queryString) {
        return (orders) => {
          return (orders.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
        }
      },
      handleSelect (item) {
        console.log(item)
      }, */
      /** 查询操作 */
      searchOrderList: function () {
        this.getOrderdetailList(this.queryInfo)
      },
      /** 得到用户列表 */
      getUserList: function () {
        this.$http.get('/user/list').then(
          res => {
            this.userList = res.data.data.rows
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      /** 获取模版导入记录列表 */
      getOrderdetailList (queryInfo) {
        const endTime = this.searchForm.create_time
        if (endTime) {
          this.searchForm.create_time[1] = moment(endTime[1]).endOf('day').valueOf()
        }
        this.$http.post(`/orderdetail/pagination/${queryInfo.page}/${queryInfo.pageSize}`, this.searchForm).then(
          res => {
            this.queryInfo.total = res.data.data.total
            this.tableData = res.data.data.rows
            console.log(this.tableData)
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      /** 格式化用户名称 */
      formatUserName: function (row, column, cellValue) {
        let user
        for (let i = 0; i < this.userList.length; i++) {
          user = this.userList[i]
          if (user.id === cellValue) {
            return user.nickname
          }
        }
        return window.sessionStorage.getItem('nickname')
      },
      /** 格式化时间戳为标准的时间字符串 */
      formatDatetime: function (row, column, cellValue) {
        return moment(cellValue * 1000).format('YYYY/MM/DD HH:mm:ss')
      },
      /** 格式化时间戳为标准的日期字符串 */
      formatDate: function (row, column, cellValue) {
        return moment(cellValue * 1000).format('YYYY/MM/DD')
      },
      /** 保留小数位数，x原始值，y是小数保留位 */
      toDecimal: function (x, y) {
        let f = parseFloat(x)
        if (isNaN(f)) {
            return false
        }
        f = Math.round(x * 10 ** y) / 10 ** y
        let s = f.toString()
        let rs = s.indexOf('.')
        if (rs < 0) {
            rs = s.length
            s += '.'
        }
        while (s.length <= rs + y) {
            s += '0'
        }
        return s
      },
      toDecimal3: function (row, column, cellValue) {
        return this.toDecimal(cellValue, 3)
      },
       /** 分页组件改变每页显示记录数 */
      handleSizeChange (val) {
        // console.log(`每页 ${val} 条`)
        this.queryInfo.pageSize = val
        this.getOrderdetailList(this.queryInfo)
      },
      /** 分页组件改变当前显示页码数 */
      handleCurrentChange (val) {
        // console.log(`当前页: ${val}`)
        this.queryInfo.page = val
        this.getOrderdetailList(this.queryInfo)
      }
    }
  }
</script>

<style scoped>

</style>
