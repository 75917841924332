<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
    <el-breadcrumb-item>报关管理</el-breadcrumb-item>
    <el-breadcrumb-item>报关费用</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图区域 -->
    <!-- 搜索视图区 -->
    <el-card>
      <div class="search">
        <el-form :inline="true" :model="searchForm" class="demo-form-inline" style="width: 960px">
          <el-form-item label="主运单号">
            <el-input placeholder="请输入主运单号" v-model="searchForm.mwnb" clearable style="width: 160px"></el-input>
          </el-form-item>
          <el-form-item label="所属客户">
            <el-select v-model="searchForm.cus_id" placeholder="请选择所属客户">
              <el-option
                v-for="item in this.customerList_all"
                :key="item.name"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
    <!-- 列表视图区 -->
    <el-card>
      <el-button-group>
        <el-button type="primary" @click="searchDeclarance" size="small" icon="el-icon-search">查询</el-button>
        <el-button type="primary" @click="addDialogShow"  size="small" icon="el-icon-plus">新增</el-button>
      </el-button-group>
      <el-table :data="tableData" style="width: 100%" stripe>
        <el-table-column label="ID" prop="id" width="60px" fixed="left"></el-table-column>
        <el-table-column label="主运单号" prop="mwbn" width="120px" fixed="left"></el-table-column>
        <el-table-column label="件数" prop="quantity" width="100px"></el-table-column>
        <el-table-column label="重量" prop="quality" width="120px" :formatter="toDecimal3"></el-table-column>
        <el-table-column label="客户" prop="cus_id" width="200px" :formatter="formatCustomerName" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column label="应收" prop="receivable" width="120px" :formatter="toDecimal2"></el-table-column>
        <el-table-column label="应付" prop="payable" width="120px" :formatter="toDecimal2"></el-table-column>
        <el-table-column label="备注" prop="remark" width="300px" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column label="创建者" prop="creator" width="100px" :formatter="formatUserName"></el-table-column>
        <el-table-column label="创建时间" prop="create_time" width="165px" :formatter="formatDatetime"></el-table-column>
        <el-table-column label="更新者" prop="updator" width="100px" :formatter="formatUserName"></el-table-column>
        <el-table-column label="更新时间" prop="update_time" width="165px" :formatter="formatDatetime"></el-table-column>
        <el-table-column width="200px" fixed="right" align="left" label="操作">
           <template slot-scope="scope">
             <el-button-group>
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-edit"
                @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
              <el-popconfirm
                confirm-button-text='好的'
                cancel-button-text='不用了'
                icon="el-icon-info"
                icon-color="red"
                title="确定要删除吗？"
                @confirm="handleDelete(scope.$index, scope.row)">
                  <el-button
                    size="mini"
                    type="danger"
                    icon="el-icon-delete"
                    slot="reference">删除</el-button>
              </el-popconfirm>
             </el-button-group>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页视图区 -->
      <el-pagination background style="width: 100%;margin:15px 0px;" class="pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[3, 6, 10, 20]"
        :page-size="queryInfo.pageSize"
        layout="prev, pager, next, jumper,total, sizes"
        :total="queryInfo.total">
     </el-pagination>
      <!-- 新增报关费用单据对话框 -->
     <el-dialog title="添加报关费用" :visible.sync="addDialogVisible" @close="addDialogClosed">
       <!-- 内容主体区 -->
      <el-form :model="addForm" ref="addFormRef" :rules="addFormRules" :inline="true" label-width="100px">
        <el-form-item label="主运单号" prop="mwbn">
          <el-input v-model="addForm.mwbn"></el-input>
        </el-form-item>
        <el-form-item label="件数" prop="quantity">
          <el-input v-model="addForm.quantity"></el-input>
        </el-form-item>
        <el-form-item label="重量" prop="quantity">
          <el-input v-model="addForm.quality"></el-input>
        </el-form-item>
        <el-form-item label="客户" prop="quantity">
          <el-select v-model="addForm.cus_id" placeholder="请选择所属客户">
            <el-option
                v-for="item in this.customerList"
                :key="item.name"
                :label="item.name"
                :value="item.id">
              </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="应收" prop="receivable">
          <el-input v-model="addForm.receivable"></el-input>
        </el-form-item>
        <el-form-item label="应付" prop="receivable">
          <el-input v-model="addForm.payable"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="addForm.remark" type="textarea" style="width: 470px"></el-input>
        </el-form-item>
      </el-form>
       <!-- 底部区域 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addDeclarance">保 存</el-button>
      </div>
     </el-dialog>
       <!-- 编辑报关费用单据对话框 -->
     <el-dialog title="编辑报关费用" :visible.sync="editDialogVisible" @close="editDialogClosed">
       <!-- 内容主体区 -->
      <el-form :model="editForm" ref="editFormRef" :rules="editFormRules" :inline="true" label-width="100px">
        <el-form-item label="报关编号" prop="id">
          <el-input v-model="editForm.id" readonly></el-input>
        </el-form-item>
        <el-form-item label="主运单号" prop="mwbn">
          <el-input v-model="editForm.mwbn"></el-input>
        </el-form-item>
        <el-form-item label="件数" prop="quantity">
          <el-input v-model="editForm.quantity"></el-input>
        </el-form-item>
        <el-form-item label="重量" prop="quantity">
          <el-input v-model="editForm.quality"></el-input>
        </el-form-item>
        <el-form-item label="客户" prop="quantity">
          <el-select v-model="editForm.cus_id">
            <el-option
                v-for="item in this.customerList"
                :key="item.name"
                :label="item.name"
                :value="item.id">
              </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="应收" prop="receivable">
          <el-input v-model="editForm.receivable"></el-input>
        </el-form-item>
        <el-form-item label="应付" prop="receivable">
          <el-input v-model="editForm.payable"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="editForm.remark" type="textarea" style="width: 470px"></el-input>
        </el-form-item>
      </el-form>
       <!-- 底部区域 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateDeclarance">保 存</el-button>
      </div>
     </el-dialog>
    </el-card>
  </div>
</template>

<script>
  import moment from 'moment'
  export default {
    name: 'Declarance',
    data () {
      return {
        /** 列表数据 */
        tableData: [],
        /** 客户字典 */
        customerList: [],
        customerList_all: [],
        userList: [],
        /** 列表展示过滤条件信息 */
        queryInfo: {
          query: '',
          page: 1,
          pageSize: 10,
          total: 0
        },
        /** 搜索表单 */
        searchForm: {
          mwbn: '',
          cus_id: 0
        },
        addDialogVisible: false,
        editDialogVisible: false,
        addForm: {
          mwbn: '',
          quantity: 0,
          quality: 0.000,
          cus_id: '',
          receivable: 0.00,
          payable: 0.00,
          remark: ''
        },
        /** 新增报关费用数据检查 */
        addFormRules: {
          mwbn: [
            { required: true, message: '请输入主运单号', trigger: 'blur' },
            { min: 2, max: 20, message: '长度在2～20个字符之间', trigger: 'blur' }
          ],
          quantity: [
            { required: true, message: '请输入件数', trigger: 'blur' },
            { pattern: /^[1-9]\d*$/, message: '件数必须为正整数', trigger: 'blur' }
          ],
          quality: [
            { required: true, message: '请输入重量', trigger: 'blur' },
            { pattern: /^(0|[1-9]\d{0,9})(\.\d{0,3})?$/, message: '请输入正确的过磅重量，保留3位小数', trigger: 'blur' }
          ],
          cus_id: [
            { required: true, message: '请选择所属客户', trigger: 'blur' }
          ],
          receivable: [
            { required: true, message: '请输入应收金额', trigger: 'blur' },
            { pattern: /^(0|[1-9]\d{0,9})(\.\d{0,2})?$/, message: '请输入正确金额，保留2位小数', trigger: 'blur' }
          ],
          payable: [
            { required: true, message: '请输入应付金额', trigger: 'blur' },
            { pattern: /^(0|[1-9]\d{0,9})(\.\d{0,2})?$/, message: '请输入正确金额，保留2位小数', trigger: 'blur' }
          ]
        },
        editForm: {

        },
        /** 编辑报关费用数据检查 */
        editFormRules: {
          mwbn: [
            { required: true, message: '请输入主运单号', trigger: 'blur' },
            { min: 2, max: 20, message: '长度在2～20个字符之间', trigger: 'blur' }
          ],
          quantity: [
            { required: true, message: '请输入件数', trigger: 'blur' },
            { pattern: /^[1-9]\d*$/, message: '件数必须为正整数', trigger: 'blur' }
          ],
          quality: [
            { required: true, message: '请输入重量', trigger: 'blur' },
            { pattern: /^(0|[1-9]\d{0,9})(\.\d{0,3})?$/, message: '请输入正确的过磅重量，保留3位小数', trigger: 'blur' }
          ],
          cus_id: [
            { required: true, message: '请选择所属客户', trigger: 'blur' }
          ],
          receivable: [
            { required: true, message: '请输入应收金额', trigger: 'blur' },
            { pattern: /^(0|[1-9]\d{0,9})(\.\d{0,2})?$/, message: '请输入正确金额，保留2位小数', trigger: 'blur' }
          ],
          payable: [
            { required: true, message: '请输入应付金额', trigger: 'blur' },
            { pattern: /^(0|[1-9]\d{0,9})(\.\d{0,2})?$/, message: '请输入正确金额，保留2位小数', trigger: 'blur' }
          ]
        }
      }
    },
    created () {
      this.getCustomerList()
      this.getDeclaranceList(this.queryInfo)
    },
    methods: {
      /** 添加或者更新报关费用，通过用户ID来判断 */
      saveUpdateDeclarance (form) {
        this.$http.post('/declarance', form).then(
          res => {
            // console.log('res :' + res)
            this.$message.success({ message: res.data.msg })
            this.addDialogVisible = false
            this.editDialogVisible = false
            this.getDeclaranceList(this.queryInfo)
          },
          err => {
            // console.log('err.response.data :' + JSON.stringify(err.response.data))
            this.$message.error({ message: err.response.data.msg })
          })
      },
      addDialogShow () {
        this.addDialogVisible = true
        // this.tableData = []
      },
      /** 添加报关费用 */
      addDeclarance () {
        this.$refs.addFormRef.validate(valid => {
          if (!valid) return
          this.saveUpdateDeclarance(this.addForm)
        })
      },
      /** 更新报关费用 */
      updateDeclarance () {
        this.$refs.editFormRef.validate(valid => {
          if (!valid) return
          this.saveUpdateDeclarance(this.editForm)
        })
      },
      /** 获取报关费用列表 */
      getDeclaranceList (queryInfo) {
        this.$http.post(`/declarance/pagination/${queryInfo.page}/${queryInfo.pageSize}`, this.searchForm).then(
          res => {
            // console.log('this.searchForm :', JSON.stringify(this.searchForm))
            this.queryInfo.total = res.data.data.total
            this.tableData = res.data.data.rows
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      /** 得到客户列表 */
      getCustomerList: function () {
        this.$http.get('/customer/list').then(
          res => {
            this.customerList = res.data.data.rows
            this.customerList_all = JSON.parse(JSON.stringify(this.customerList))
            this.customerList_all.unshift({ id: 0, name: '全部' })
            // console.log(this.customerList)
            // console.log(this.customerList_all)
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      /** 搜索事件函数 */
      searchDeclarance: function () {
        this.getDeclaranceList(this.queryInfo)
      },
      /** 显示编辑报关费用信息的对话框 */
      handleEdit (index, row) {
        this.editDialogVisible = true
        this.$http.get(`/declarance/${row.id}`).then(
          res => {
            this.editForm = res.data.data
            // console.log("this.editForm :", this.editForm.id);
            this.editDialogVisible = true
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      /** 删除指定的报关费用单据 */
      handleDelete: function (index, row) {
        this.$http.delete(`/declarance/${row.id}`).then(
          res => {
            this.getDeclaranceList(this.queryInfo)
            this.$message({ message: res.data.msg, type: 'success' })
          },
          err => {
            // console.log('err: ' + JSON.stringify(err))
            this.$message({ message: err.response.data.msg, type: 'error' })
          }
        )
      },
      /** 监听添加用户对话框关闭事件 */
      addDialogClosed () {
        this.$refs.addFormRef.resetFields()
      },
      /** 监听修改用户对话框关闭事件 */
      editDialogClosed () {
        this.$refs.editFormRef.resetFields()
      },
      /** 格式化客户名称 */
      formatCustomerName: function (row, column, cellValue) {
        let customer
        for (let i = 0; i < this.customerList.length; i++) {
          customer = this.customerList[i]
          if (customer.id === cellValue) {
            return customer.name
          }
        }
      },
      /** 格式化用户名称 */
      formatUserName: function (row, column, cellValue) {
        let user
        for (let i = 0; i < this.userList.length; i++) {
          user = this.userList[i]
          if (user.id === cellValue) {
            return user.nickname
          }
        }
        return window.sessionStorage.getItem('nickname')
      },
      /** 格式化时间戳为标准的时间字符串 */
      formatDatetime: function (row, column, cellValue) {
        return moment(cellValue * 1000).format('YYYY/MM/DD HH:mm:ss')
      },
      /** 保留小数位数，x原始值，y是小数保留位 */
      toDecimal: function (x, y) {
        let f = parseFloat(x)
        if (isNaN(f)) {
            return false
        }
        f = Math.round(x * 10 ** y) / 10 ** y
        let s = f.toString()
        let rs = s.indexOf('.')
        if (rs < 0) {
            rs = s.length
            s += '.'
        }
        while (s.length <= rs + y) {
            s += '0'
        }
        return s
      },
      toDecimal3: function (row, column, cellValue) {
        return this.toDecimal(cellValue, 3)
      },
      toDecimal2: function (row, column, cellValue) {
        return this.toDecimal(cellValue, 2)
      },
      /** 分页组件改变每页显示记录数 */
      handleSizeChange (val) {
        // console.log(`每页 ${val} 条`)
        this.queryInfo.pageSize = val
        this.getDeclaranceList(this.queryInfo)
      },
      /** 分页组件改变当前显示页码数 */
      handleCurrentChange (val) {
        // console.log(`当前页: ${val}`)
        this.queryInfo.page = val
        this.getDeclaranceList(this.queryInfo)
      }
    }
  }
</script>

<style scoped>

</style>
