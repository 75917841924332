<template>
<div>
  <el-breadcrumb separator-class="el-icon-arrow-right">
  <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
  <el-breadcrumb-item>数据可视化</el-breadcrumb-item>
  <el-breadcrumb-item>用车视图</el-breadcrumb-item>
  </el-breadcrumb>
  <el-row :gutter="10">
    <el-col :span="6">
      <el-card >
        <div slot="header" class="clearfix">
          <span>用车总应收（CNY）</span>
          <i class="el-icon-warning-outline" style="float: right; padding: 3px 0"></i>
        </div>
        <div class="bottom clearfix">
          <span class="text item">
            {{formatQfw(totalRec)}}
          </span>
          <el-divider></el-divider>
          <span>本月应收： {{formatQfw(totalRecCurmonth)}} CNY</span>
        </div>
      </el-card>
    </el-col>
    <el-col :span="6">
      <el-card >
        <div slot="header" class="clearfix">
          <span>用车总应付（CNY）</span>
          <i class="el-icon-warning-outline" style="float: right; padding: 3px 0"></i>
        </div>
        <div class="bottom clearfix">
          <span class="text item">
            {{formatQfw(totalPay)}}
          </span>
          <el-divider></el-divider>
          <span>本月应付： {{formatQfw(totalPayCurmonth)}} CNY</span>
        </div>
      </el-card>
    </el-col>
    <el-col :span="6">
      <el-card >
        <div slot="header" class="clearfix">
          <span>用车总利润（CNY）</span>
          <i class="el-icon-warning-outline" style="float: right; padding: 3px 0"></i>
        </div>
        <div class="bottom clearfix">
          <span class="text item">
            {{formatQfw(total)}}
          </span>
          <el-divider></el-divider>
          <span>本月利润： {{formatQfw(totalCurmonth)}} CNY</span>
        </div>
      </el-card>
    </el-col>
    <el-col :span="6">
      <el-card >
        <div slot="header" class="clearfix">
          <span>当月用车数（次）</span>
          <i class="el-icon-warning-outline" style="float: right; padding: 3px 0"></i>
        </div>
        <div class="bottom clearfix">
          <span class="text item">
            {{formatQfw(thisMonthTimes)}}
          </span>
          <el-divider></el-divider>
          <span>上月次数： {{formatQfw(lastMonthTimes)}} 次</span>
        </div>
      </el-card>
    </el-col>
  </el-row>
  <el-row :gutter="10">
    <el-col :span="24">
      <el-card>
        <div class='chart'>
          <v-chart :option="vehicleDateStatOption" />
        </div>
        <div align="center">
          <el-radio-group v-model="vehicleDateRadio" @change="changeShowSpan">
            <el-radio :label="1">最近一周</el-radio>
            <el-radio :label="2">最近一月</el-radio>
            <el-radio :label="3">最近三月</el-radio>
          </el-radio-group>
        </div>
      </el-card>
    </el-col>
  </el-row>
  <el-row :gutter="10">
  <el-col :span="24">
    <el-card>
      <div class='chart'>
        <v-chart :option="vehicleMonthStatOption" />
      </div>
      <div align="center">
        <el-radio-group v-model="vehicleMonthRadio" @change="changeShowSpanByMonth">
          <el-radio :label="1">最近六月</el-radio>
          <el-radio :label="2">最近一年</el-radio>
          <el-radio :label="3">全部</el-radio>
        </el-radio-group>
      </div>
    </el-card>
  </el-col>
  </el-row>
  <el-row :gutter="10">
  <el-col :span="24">
    <el-card>
      <div class='chart'>
        <v-chart :option="vehicleYearStatOption" />
      </div>
    </el-card>
  </el-col>
</el-row>
</div>
</template>

<script>
  export default {
    name: 'Vehicleview',
    data () {
      return {
        vehicleDateStatOption: {},
        vehicleMonthStatOption: {},
        vehicleYearStatOption: {},
        vehicleDateRadio: 2,
        vehicleMonthRadio: 2,
        total: 0,
        totalCurmonth: 0,
        totalRec: 0,
        totalRecCurmonth: 0,
        totalPay: 0,
        totalPayCurmonth: 0,
        thisMonthTimes: 0,
        lastMonthTimes: 0
      }
    },
    created () {
      this.getAllVehiclefee()
      this.getVehicleCountThisMonth()
      this.getVehicleCountLastMonth()
      this.getVehiclefeeThisMonth()
      this.changeShowSpan(this.vehicleDateRadio)
      this.changeShowSpanByMonth(this.vehicleMonthRadio)
      this.changeShowSpanByYear()
    },
    methods: {
      changeShowSpan (val) {
        let quantity = 1
        let span = 'weeks'
        if (val === 1) {
          quantity = 1
          span = 'weeks'
        } else if (val === 2) {
          quantity = 1
          span = 'months'
        } else if (val === 3) {
          quantity = 3
          span = 'months'
        }
        const startDate = this.$moment().subtract(quantity, span).startOf('day').valueOf() / 1000
        this.getVehiclefeeStat(startDate)
      },
      changeShowSpanByMonth (val) {
        let quantity = 1
        let span = ''
        if (val === 1) {
          quantity = 5
          span = 'months'
        } else if (val === 2) {
          quantity = 11
          span = 'months'
        } else {
          quantity = 100
          span = 'months'
        }
        const startDate = this.$moment().subtract(quantity, span).startOf('month').valueOf() / 1000
        this.getVehiclefeeMonthStat(startDate)
      },
      changeShowSpanByYear () {
        const startDate = this.$moment('2021-01-01').startOf('year').valueOf() / 1000
        this.getVehiclefeeYearStat(startDate)
      },
      getVehiclefeeYearStat (startDate) {
        const type = 'year'
        this.$http.get(`/vehiclefee/stat/${type}/${startDate}`).then(
          res => {
            const olist = res.data.data.rows
            console.log(olist.length)
            const vehicleStat = {
              dateList: [],
              recList: [],
              payList: [],
              profitList: []
            }
            for (let i = 0; i < olist.length; i++) {
              vehicleStat.dateList.push(olist[i].stat_date)
              vehicleStat.recList.push(olist[i].total_rec)
              vehicleStat.payList.push(olist[i].total_pay)
              vehicleStat.profitList.push((olist[i].total_rec - olist[i].total_pay).toFixed(2))
            }
            this.vehicleYearStatOption = this.drawStatChart('用车统计图（按年）', vehicleStat)
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      getVehiclefeeMonthStat (startDate) {
        const type = 'month'
        this.$http.get(`/vehiclefee/stat/${type}/${startDate}`).then(
          res => {
            const olist = res.data.data.rows
            console.log(olist.length)
            const vehicleStat = {
              dateList: [],
              recList: [],
              payList: [],
              profitList: []
            }
            for (let i = 0; i < olist.length; i++) {
              vehicleStat.dateList.push(olist[i].stat_date)
              vehicleStat.recList.push(olist[i].total_rec)
              vehicleStat.payList.push(olist[i].total_pay)
              vehicleStat.profitList.push((olist[i].total_rec - olist[i].total_pay).toFixed(2))
            }
            this.vehicleMonthStatOption = this.drawStatChart('用车统计图（按月）', vehicleStat)
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      getVehiclefeeStat (startDate) {
        const type = 'day'
        this.$http.get(`/vehiclefee/stat/${type}/${startDate}`).then(
          res => {
            const olist = res.data.data.rows
            console.log(olist.length)
            const vehicleStat = {
              dateList: [],
              recList: [],
              payList: [],
              profitList: []
            }
            for (let i = 0; i < olist.length; i++) {
              vehicleStat.dateList.push(olist[i].stat_date)
              vehicleStat.recList.push(olist[i].total_rec)
              vehicleStat.payList.push(olist[i].total_pay)
              vehicleStat.profitList.push((olist[i].total_rec - olist[i].total_pay).toFixed(2))
            }
            this.vehicleDateStatOption = this.drawStatChart('用车统计图（按天）', vehicleStat)
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      drawStatChart (title, vehicleStat) {
        console.log(vehicleStat)
        const option = {
          title: {
            text: title
          },
          legend: {
            data: ['应收CNY', '应付CNY', '利润CNY']
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
              label: {
                show: true
              }
            }
          },
          toolbox: {
            show: true,
            feature: {
              mark: { show: true },
              dataView: { show: true, readOnly: false, title: '数据视图' },
              magicType: { show: true, type: ['line', 'bar'], title: { line: '切换为折线图', bar: '切换为柱状图' } },
              restore: { show: false },
              saveAsImage: { show: true, title: '另存为图片' }
            }
          },
          xAxis: {
            type: 'category',
            data: vehicleStat.dateList
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              name: '应收CNY',
              data: vehicleStat.recList,
              type: 'bar',
              smooth: true
            },
            {
              name: '应付CNY',
              data: vehicleStat.payList,
              type: 'bar',
              smooth: true
            },
            {
              name: '利润CNY',
              data: vehicleStat.profitList,
              type: 'bar',
              smooth: true
            }
          ]
        }
        return option
      },
      getVehicleCountThisMonth () {
        const start = parseInt(this.$moment().startOf('month').valueOf() / 1000)
        const end = parseInt(this.$moment().endOf('month').valueOf() / 1000)

        this.$http.get(`/vehiclefee/count/${start}/${end}`).then(
          res => {
            // console.log(res.data)
            this.thisMonthTimes = res.data.data.count
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      getVehicleCountLastMonth () {
        const start = parseInt(this.$moment().subtract(1, 'months').startOf('month').valueOf() / 1000)
        const end = parseInt(this.$moment().subtract(1, 'months').endOf('month').valueOf() / 1000)

        this.$http.get(`/vehiclefee/count/${start}/${end}`).then(
          res => {
            // console.log(res.data)
            this.lastMonthTimes = res.data.data.count
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      getAllVehiclefee () {
        this.$http.get('/vehiclefee/total').then(
          res => {
            this.totalRec = res.data.data.total_rec
            this.totalPay = res.data.data.total_pay
            this.total = (this.totalRec - this.totalPay).toFixed(2)
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      getVehiclefeeThisMonth () {
        const start = parseInt(this.$moment().startOf('month').valueOf() / 1000)
        const end = parseInt(this.$moment().endOf('month').valueOf() / 1000)

        this.$http.get(`/vehiclefee/total/${start}/${end}`).then(
          res => {
            this.totalRecCurmonth = Number(res.data.data.total_rec)
            this.totalPayCurmonth = Number(res.data.data.total_pay)
            this.totalCurmonth = (this.totalRecCurmonth - this.totalPayCurmonth).toFixed(2)
            // console.log(res.data)
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      /** 格式化数字千分位 */
      formatQfw (n) {
        return this.$format.formatQfw(n)
      }
    }
  }
</script>

<style scoped>
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both
}
.text {
  font-size: 36px;
}
.box-card-number {
  width: 320px;
}
.box-card {
  width: 480px;
}
.item {
  margin-bottom: 18px;
}
.bottom {
  margin-top: 13px;
  line-height: 12px;
}
.chart {
  height: 400px;
}
</style>
