<template>
<div class="container">
  <el-card class="search-box">
    <el-form :model="searchForm" ref="searchFormRef" :rules="searchFormRules" :inline="true">
      <el-form-item prop="store_no">
        <el-input
          placeholder="请输入进仓单号"
          prefix-icon="el-icon-search"
          v-model="searchForm.store_no" clearable>
          <el-button slot="append" @click="search">搜索</el-button>
        </el-input>
      </el-form-item>
    </el-form>
  </el-card>
  <el-card>
    <div slot="header" class="clearfix">
      <i class="el-icon-tickets"></i>
      <span>基础信息</span>
    </div>
    <el-descriptions :column="1">
      <el-descriptions-item label="进仓单号">{{godown.store_no}}</el-descriptions-item>
      <el-descriptions-item label="入库时间">{{formatDatetime(godown.inbound_time)}}</el-descriptions-item>
      <el-descriptions-item label="存放库区">{{godown.warehouse_name}}</el-descriptions-item>
      <el-descriptions-item label="中文名称">{{godown.product_name}}</el-descriptions-item>
      <el-descriptions-item label="所属客户">{{godown.cus_name}}</el-descriptions-item>
      <el-descriptions-item label="主运单号">{{godown.mawbn}}</el-descriptions-item>
      <el-descriptions-item label="件   数">{{godown.quantity}}</el-descriptions-item>
      <el-descriptions-item label="过磅重量">{{toDecimal3(godown.quality)}}</el-descriptions-item>
      <el-descriptions-item label="过磅体积">{{toDecimal3(godown.cube)}}</el-descriptions-item>
      <el-descriptions-item label="费用(元)">{{formatQfw(godown.total_cost)}}</el-descriptions-item>
      <el-descriptions-item label="备   注">{{godown.remark}}</el-descriptions-item>
    </el-descriptions>
  </el-card>
  <el-card>
    <div slot="header" class="clearfix">
      <i class="el-icon-picture-outline"></i>
      <span>附件</span>
    </div>
    <el-image v-for="(item, index) in godown.images" :key="index" style="width: 100px; height: 100px"
              :src="getImageUrl(item)"
              :preview-src-list="getImageUrls(godown.images)">
    </el-image>
  </el-card>
</div>
</template>

<script>
export default {
  data () {
    return {
      searchForm: {
        store_no: null
      },
      searchFormRules: {
        store_no: [
          { required: true, message: '请输入进仓单号', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ]
      },
      /** 入仓单对象 */
      godown: {
        store_no: '',
        warehouse_name: null,
        inbound_time: null,
        product_name: '',
        mawbn: null,
        quantity: 0,
        quality: 0.000,
        cube: 0.000,
        remark: '',
        images: []
      }
    }
  },
  mounted () {
    // 绑定事件，其他界面可能要写在methods里
    window.addEventListener('keydown', this.keyDown)
  },
  created: function () {

  },
  methods: {
    getImageUrl (image) {
      return this.$config.image_base_url + image.path + image.name
    },
    getImageUrls (images) {
      const imgList = []

      for (let i = 0; i < images.length; i++) {
        imgList.push(this.$config.image_base_url + images[i].path + images[i].name)
      }
      return imgList
    },
    /** 格式化时间戳为标准的时间字符串 */
    formatDatetime: function (cellValue) {
      return this.$format.formatDatetime(cellValue)
      // return moment(cellValue * 1000).format('YYYY/MM/DD HH:mm:ss')
    },
    formatQfw: function (val) {
      return this.$format.formatQfw(val)
    },
    toDecimal3: function (val) {
      return this.$format.toDecimal3(val)
    },
    search () {
      this.$refs.searchFormRef.validate(valid => {
        if (!valid) return
        this.getGodownInfo(this.searchForm.store_no)
      })
    },
    getGodownInfo (storeNo) {
      this.$http.post(`/godown/h5/${storeNo}`).then(res => {
        console.log('godown: ', res.data)
        this.godown = res.data.data
      },
      err => {
        this.$message.error(err.response.data.msg)
      })
    },
    // 绑定监听事件
    keyDown (e) {
      // 如果是按回车则执行登录方法
      if (e.keyCode === 13) {
        this.search()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.container{
  //background-color: #2b4b6b;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.search-box{
  display: flex;
  flex-flow: row;
  //align-self: center;
}
.el-card {
  width: 375px;
}

</style>
