<template>
<div>
  <el-breadcrumb separator-class="el-icon-arrow-right">
  <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
  <el-breadcrumb-item>数据可视化</el-breadcrumb-item>
  <el-breadcrumb-item>订单视图</el-breadcrumb-item>
  </el-breadcrumb>
  <el-row :gutter="10">
    <el-col :span="6">
      <el-card >
        <div slot="header" class="clearfix">
          <span>订单总数（单）</span>
          <i class="el-icon-warning-outline" style="float: right; padding: 3px 0"></i>
        </div>
        <div class="bottom clearfix">
          <span class="text item">
            {{formatQfw(orderCount)}}
          </span>
          <el-divider></el-divider>
          <span>本月新增： {{formatQfw(orderCountCurmonth)}} 单</span>
        </div>
      </el-card>
    </el-col>
    <el-col :span="6">
      <el-card >
        <div slot="header" class="clearfix">
          <span>今日订单数（单）</span>
          <i class="el-icon-warning-outline" style="float: right; padding: 3px 0"></i>
        </div>
        <div class="bottom clearfix">
          <span class="text item">
            {{formatQfw(orderCountToday)}}
          </span>
          <el-divider></el-divider>
          <span>昨日新增： {{formatQfw(orderCountYesterday)}} 单</span>
        </div>
      </el-card>
    </el-col>
    <el-col :span="6">
      <el-card >
        <div slot="header" class="clearfix">
          <span>订单总额（USD）</span>
          <i class="el-icon-warning-outline" style="float: right; padding: 3px 0"></i>
        </div>
        <div class="bottom clearfix">
          <span class="text item">
            {{formatQfw(orderTotalSum)}}
          </span>
          <el-divider></el-divider>
          <span>本月新增： {{formatQfw(orderTotalSumCurmonth)}} USD</span>
        </div>
      </el-card>
    </el-col>
    <el-col :span="6">
      <el-card >
        <div slot="header" class="clearfix">
          <span>今日订单额（USD）</span>
          <i class="el-icon-warning-outline" style="float: right; padding: 3px 0"></i>
        </div>
        <div class="bottom clearfix">
          <span class="text item">
            {{formatQfw(orderTotalSumToday)}}
          </span>
          <el-divider />
          <span>昨日新增： {{formatQfw(orderTotalSumYesterday)}} USD</span>
        </div>
      </el-card>
    </el-col>
  </el-row>
  <el-row :gutter="10">
    <el-col :span="24">
      <el-card>
        <div class='chart'>
          <v-chart :option="option_order" />
        </div>
        <div align="center">
          <el-radio-group v-model="radio" @change="changeShowSpan">
            <el-radio :label="1">最近一周</el-radio>
            <el-radio :label="2">最近一月</el-radio>
            <el-radio :label="3">最近三月</el-radio>
          </el-radio-group>
        </div>
      </el-card>
    </el-col>
  </el-row>
  <el-row :gutter="10">
  <el-col :span="24">
    <el-card>
      <div class='chart'>
        <v-chart :option="option_order_month" />
      </div>
      <div align="center">
        <el-radio-group v-model="radio_month" @change="changeShowSpanByMonth">
          <el-radio :label="1">最近六月</el-radio>
          <el-radio :label="2">最近一年</el-radio>
          <el-radio :label="3">全部</el-radio>
        </el-radio-group>
      </div>
    </el-card>
  </el-col>
  </el-row>
  <el-row :gutter="10">
  <el-col :span="24">
    <el-card>
      <div class='chart'>
        <v-chart :option="option_order_year" />
      </div>
    </el-card>
  </el-col>
</el-row>
</div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'HelloWorld',
  data () {
    return {
      orderTotalSum: 0,
      orderCount: 0,
      orderTotalSumToday: 0,
      orderCountToday: 0,
      orderTotalSumYesterday: 0,
      orderCountYesterday: 0,
      orderTotalSumCurmonth: 0,
      orderCountCurmonth: 0,
      radio: 2,
      radio_month: 2,
      option_order: {},
      option_order_month: {},
      option_order_year: {}
    }
  },
  created () {
    this.getOrderCount()
    this.getOrderCountToday()
    this.getOrderCountYesterday()
    this.getOrderCountCurmonth()
    this.changeShowSpan(this.radio)
    this.changeShowSpanByMonth(this.radio)
    this.changeShowSpanByYear(this.radio)
  },
  methods: {
    getOrderCount () {
      this.$http.get('/template/count').then(
        res => {
          // console.log(res.data)
          this.orderCount = Number(res.data.data.count)
          this.orderTotalSum = Number(res.data.data.total_sum)
        },
        err => {
          this.$message.error({ message: err.response.data.msg })
        }
      )
    },
    getOrderCountToday () {
      const start = moment().startOf('day').valueOf() / 1000
      const end = parseInt(moment().endOf('day').valueOf() / 1000)
      this.$http.get(`/template/count/${start}/${end}`).then(
        res => {
          console.log(res.data)
          this.orderCountToday = Number(res.data.data.count)
          this.orderTotalSumToday = Number(res.data.data.total_sum)
        },
        err => {
          this.$message.error({ message: err.response.data.msg })
        }
      )
    },
    getOrderCountYesterday () {
      const start = moment().subtract(1, 'days').startOf('day').valueOf() / 1000
      const end = parseInt(moment().subtract(1, 'days').endOf('day').valueOf() / 1000)
      this.$http.get(`/template/count/${start}/${end}`).then(
        res => {
          console.log(res.data)
          this.orderCountYesterday = Number(res.data.data.count)
          this.orderTotalSumYesterday = Number(res.data.data.total_sum)
        },
        err => {
          this.$message.error({ message: err.response.data.msg })
        }
      )
    },
    getOrderCountCurmonth () {
      const start = moment().startOf('month').valueOf() / 1000
      const end = parseInt(moment().endOf('month').valueOf() / 1000)
      this.$http.get(`/template/count/${start}/${end}`).then(
        res => {
          console.log(res.data)
          this.orderCountCurmonth = Number(res.data.data.count)
          this.orderTotalSumCurmonth = Number(res.data.data.total_sum)
        },
        err => {
          this.$message.error({ message: err.response.data.msg })
        }
      )
    },
    changeShowSpan (val) {
      let quantity = 1
      let span = 'weeks'
      if (val === 1) {
        quantity = 1
        span = 'weeks'
      } else if (val === 2) {
        quantity = 1
        span = 'months'
      } else if (val === 3) {
        quantity = 3
        span = 'months'
      }
      const startDate = moment().subtract(quantity, span).startOf('day').valueOf() / 1000
      this.getOrderStat(startDate)
    },
    changeShowSpanByMonth (val) {
      let quantity = 1
      let span = ''
      if (val === 1) {
        quantity = 5
        span = 'months'
      } else if (val === 2) {
        quantity = 11
        span = 'months'
      } else {
        quantity = 100
        span = 'months'
      }
      const startDate = moment().subtract(quantity, span).startOf('month').valueOf() / 1000
      this.getOrderStatByMonth(startDate)
    },
    changeShowSpanByYear (val) {
      const startDate = moment('2021-01-01').startOf('year').valueOf() / 1000
      this.getOrderStatByYear(startDate)
    },
    drawLineChart (title, orderDateList, orderNumList, totalSumList, totalWeightList) {
      const option = {
        title: {
          text: title
        },
        legend: {
          data: ['订单数', '金额USD', '总重Kg']
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            label: {
              show: true
            }
          }
        },
        toolbox: {
          show: true,
          // orient: 'vertical',
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false, title: '数据视图' },
            magicType: { show: true, type: ['line', 'bar'], title: { line: '切换为折线图', bar: '切换为柱状图' } },
            restore: { show: false },
            saveAsImage: { show: true, title: '另存为图片' }
          }
        },
        xAxis: {
          type: 'category',
          data: orderDateList
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '订单数',
            data: orderNumList,
            type: 'bar',
            smooth: true
          },
          {
            name: '金额USD',
            data: totalSumList,
            type: 'bar',
            smooth: true
          },
          {
            name: '总重Kg',
            data: totalWeightList,
            type: 'bar',
            smooth: true
          }
        ]
      }
      return option
    },
    getOrderStat (startDate) {
      this.$http.get(`/template/day/stat/${startDate}`).then(
          res => {
            // console.log(res.data.data.rows)
            const orderNumList = []
            const orderDateList = []
            const totalSumList = []
            const totalWeightList = []
            const olist = res.data.data.rows
            for (let i = 0; i < olist.length; i++) {
              orderDateList.push(olist[i].order_date)
              orderNumList.push(olist[i].order_num)
              totalSumList.push(olist[i].total_sum)
              totalWeightList.push(olist[i].total_weight)
            }
            this.option_order = this.drawLineChart('订单统计图（按天）', orderDateList, orderNumList, totalSumList, totalWeightList)
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
    },
    getOrderStatByMonth (startDate) {
      this.$http.get(`/template/month/stat/${startDate}`).then(
          res => {
            // console.log(res.data.data.rows)
            const orderNumList = []
            const orderDateList = []
            const totalSumList = []
            const totalWeightList = []
            const olist = res.data.data.rows
            for (let i = 0; i < olist.length; i++) {
              orderDateList.push(olist[i].order_date)
              orderNumList.push(olist[i].order_num)
              totalSumList.push(olist[i].total_sum)
              totalWeightList.push(olist[i].total_weight)
            }
            this.option_order_month = this.drawLineChart('订单统计图（按月）', orderDateList, orderNumList, totalSumList, totalWeightList)
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
    },
    getOrderStatByYear (startDate) {
      this.$http.get(`/template/year/stat/${startDate}`).then(
          res => {
            // console.log(res.data.data.rows)
            const orderNumList = []
            const orderDateList = []
            const totalSumList = []
            const totalWeightList = []
            const olist = res.data.data.rows
            for (let i = 0; i < olist.length; i++) {
              orderDateList.push(olist[i].order_date)
              orderNumList.push(olist[i].order_num)
              totalSumList.push(olist[i].total_sum)
              totalWeightList.push(olist[i].total_weight)
            }
            this.option_order_year = this.drawLineChart('订单统计图（按年）', orderDateList, orderNumList, totalSumList, totalWeightList)
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
    },
    /** 格式化数字千分位 */
    formatQfw (n) {
      return this.$format.formatQfw(n)
    }
  }
}
</script>

<style scoped>
.bottom {
  margin-top: 13px;
  line-height: 12px;
}
.text {
    font-size: 36px;
  }

  .item {
    margin-bottom: 18px;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  }

  .box-card-number {
    width: 320px;
  }

  .box-card {
    width: 480px;
  }
  .chart {
  height: 400px;
}
  .chart1 {
    width: 100%;
  height: 800px;
}
</style>
