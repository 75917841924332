const config = {
  /**
   * 正式环境URL = 'https://wx.lmxhz.com:9009/v1'
   * 测试环境URL = 'http://120.26.59.89:5000/v1'
   * 研发环境URL = 'http://localhost:5000/v1'
   */
  api_base_url: 'https://wx.lmxhz.com:9009/v1',
  image_base_url: 'https://wx.lmxhz.com:9000/'
}

export { config }
