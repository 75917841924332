<template>
<div style="height: 100%" >
  <el-breadcrumb separator-class="el-icon-arrow-right">
  <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
  <el-breadcrumb-item>用车管理</el-breadcrumb-item>
  <el-breadcrumb-item>驾驶员管理</el-breadcrumb-item>
  </el-breadcrumb>
  <!-- 卡片视图区域 -->
  <!-- 搜索视图区 -->
  <el-card>
    <div class="search">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline" style="width: 960px">
        <el-form-item label="姓名">
          <el-input placeholder="请输入驾驶员姓名" v-model="searchForm.name" clearable style="width: 160px"></el-input>
        </el-form-item>
        <el-form-item label="编号">
          <el-input placeholder="请输入驾驶员编号" v-model="searchForm.number" clearable style="width: 160px"></el-input>
        </el-form-item>
        <el-form-item label="驾驶证号">
          <el-input placeholder="请输入驾驶证号" v-model="searchForm.license_no" clearable style="width: 160px"></el-input>
        </el-form-item>
      </el-form>
    </div>
  </el-card>
  <!-- 列表视图区 -->
  <el-card>
    <el-button-group>
      <el-button type="primary" @click="searchDriver" size="small" icon="el-icon-search">查询</el-button>
      <el-button type="primary" @click="addDialogVisible = true" size="small" icon="el-icon-plus">新增</el-button>
    </el-button-group>
    <el-table :data="tableData" style="width: 100%" stripe>
      <el-table-column label="ID" prop="id" width="60px" fixed="left"></el-table-column>
      <el-table-column label="姓名" prop="name" width="100px" fixed="left"></el-table-column>
      <el-table-column label="编号" prop="number" width="60px" fixed="left"></el-table-column>
      <el-table-column label="驾龄" prop="driving_age" width="120px" :formatter="formatDate"></el-table-column>
      <el-table-column label="驾驶证" prop="license_no" width="200px" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="年审日期" prop="ann_review_date" width="120px" :formatter="formatDate"></el-table-column>
      <el-table-column label="类型" prop="type" width="100px" :formatter="formatDriverTypeName"></el-table-column>
      <el-table-column label="档案编号" prop="file_no" width="120px" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="建档日期" prop="file_date" width="100px" :formatter="formatDate"></el-table-column>
      <el-table-column label="电话" prop="phone" width="120px"></el-table-column>
      <el-table-column label="地址" prop="address" width="200px" :show-tooltip-when-overflow="true"></el-table-column>
      <el-table-column label="家庭电话" prop="home_phone" width="120px"></el-table-column>
      <el-table-column label="家庭地址" prop="home_address" width="200px" :show-tooltip-when-overflow="true"></el-table-column>
      <el-table-column label="开户银行" prop="acc_bank" width="160px" :show-tooltip-when-overflow="true"></el-table-column>
      <el-table-column label="银行账户" prop="account_no" width="200px"></el-table-column>
      <el-table-column label="诚信评级" prop="credit_level" width="120px" :formatter="formatCreditLevelName"></el-table-column>
      <el-table-column label="备注" prop="remark" width="200px" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="创建者" prop="creator" width="100px" :formatter="formatUserName"></el-table-column>
      <el-table-column label="创建时间" prop="create_time" width="165px" :formatter="formatDatetime"></el-table-column>
      <el-table-column label="更新者" prop="updator" width="100px" :formatter="formatUserName"></el-table-column>
      <el-table-column label="更新时间" prop="update_time" width="165px" :formatter="formatDatetime"></el-table-column>
      <el-table-column width="200px" fixed="right" align="left" label="操作">
         <template slot-scope="scope">
           <el-button-group>
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-edit"
              @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
            <el-popconfirm
              confirm-button-text='好的'
              cancel-button-text='不用了'
              icon="el-icon-info"
              icon-color="red"
              title="确定要删除吗？"
              @confirm="handleDelete(scope.$index, scope.row)">
                <el-button
                  size="mini"
                  type="danger"
                  icon="el-icon-delete"
                  slot="reference">删除</el-button>
            </el-popconfirm>
           </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页视图区 -->
    <el-pagination background style="width: 100%;margin:15px 0px;" class="pagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryInfo.page"
      :page-sizes="[3, 6, 10, 20]"
      :page-size="queryInfo.pageSize"
      layout="prev, pager, next, jumper,total, sizes"
      :total="queryInfo.total">
   </el-pagination>
     <!-- 新增驾驶员信息对话框 -->
     <el-dialog title="添加报关费用" :visible.sync="addDialogVisible" @close="addDialogClosed">
       <!-- 内容主体区 -->
      <el-form :model="addForm" ref="addFormRef" :rules="addFormRules" :inline="true" label-width="100px">
        <el-form-item label="姓名" prop="name">
          <el-input v-model="addForm.name"></el-input>
        </el-form-item>
        <el-form-item label="编号" prop="number">
          <el-input v-model="addForm.number"></el-input>
        </el-form-item>
        <el-form-item label="驾龄" prop="driving_age">
          <el-date-picker v-model="addForm.driving_age" type="date" value-format="timestamp" style="width: 180px"></el-date-picker>
        </el-form-item>
        <el-form-item label="驾驶证" prop="license_no">
          <el-input v-model="addForm.license_no"></el-input>
        </el-form-item>
        <el-form-item label="年审日期" prop="ann_review_date">
          <el-date-picker v-model="addForm.ann_review_date" type="date" value-format="timestamp" style="width: 180px"></el-date-picker>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-select v-model="addForm.type" style="width: 180px">
            <el-option v-for="item in this.driver_type_list"
                :key="item.name"
                :label="item.name"
                :value="item.id">
              </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="档案编号" prop="file_no">
          <el-input v-model="addForm.file_no"></el-input>
        </el-form-item>
        <el-form-item label="建档日期" prop="file_date">
          <el-date-picker v-model="addForm.file_date" type="date" value-format="timestamp" style="width: 180px"></el-date-picker>
        </el-form-item>
        <el-form-item label="电话" prop="phone">
          <el-input v-model="addForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="地址" prop="address">
          <el-input v-model="addForm.address"></el-input>
        </el-form-item>
        <el-form-item label="家庭电话" prop="home_phone">
          <el-input v-model="addForm.home_phone"></el-input>
        </el-form-item>
        <el-form-item label="家庭地址" prop="home_address">
          <el-input v-model="addForm.home_address"></el-input>
        </el-form-item>
        <el-form-item label="开户银行" prop="acc_bank">
          <el-input v-model="addForm.acc_bank"></el-input>
        </el-form-item>
        <el-form-item label="银行账号" prop="account_no">
          <el-input v-model="addForm.account_no"></el-input>
        </el-form-item>
        <el-form-item label="诚信评级" prop="credit_level">
          <el-select v-model="addForm.credit_level" style="width: 180px">
            <el-option v-for="item in this.credit_level_list"
                :key="item.name"
                :label="item.name"
                :value="item.id">
              </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="addForm.remark" type="textarea" style="width: 470px"></el-input>
        </el-form-item>
      </el-form>
       <!-- 底部区域 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addDriver">保 存</el-button>
      </div>
     </el-dialog>
    <!-- 编辑驾驶员信息对话框 -->
     <el-dialog title="编辑驾驶员信息" :visible.sync="editDialogVisible" @close="editDialogClosed">
       <!-- 内容主体区 -->
      <el-form :model="editForm" ref="editFormRef" :rules="editFormRules" :inline="true" label-width="100px">
        <el-form-item label="ID" prop="id">
          <el-input v-model="editForm.id" readonly></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input v-model="editForm.name"></el-input>
        </el-form-item>
        <el-form-item label="编号" prop="number">
          <el-input v-model="editForm.number"></el-input>
        </el-form-item>
        <el-form-item label="驾龄" prop="driving_age">
          <el-date-picker v-model="editForm.driving_age" type="date" value-format="timestamp" style="width: 180px"></el-date-picker>
        </el-form-item>
        <el-form-item label="驾驶证" prop="license_no">
          <el-input v-model="editForm.license_no"></el-input>
        </el-form-item>
        <el-form-item label="年审日期" prop="ann_review_date">
          <el-date-picker v-model="editForm.ann_review_date" type="date" value-format="timestamp" style="width: 180px"></el-date-picker>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-select v-model="editForm.type" style="width: 180px">
            <el-option v-for="item in this.driver_type_list"
                :key="item.name"
                :label="item.name"
                :value="item.id">
              </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="档案编号" prop="file_no">
          <el-input v-model="editForm.file_no"></el-input>
        </el-form-item>
        <el-form-item label="建档日期" prop="file_date">
          <el-date-picker v-model="editForm.file_date" type="date" value-format="timestamp" style="width: 180px"></el-date-picker>
        </el-form-item>
        <el-form-item label="电话" prop="phone">
          <el-input v-model="editForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="地址" prop="address">
          <el-input v-model="editForm.address"></el-input>
        </el-form-item>
        <el-form-item label="家庭电话" prop="home_phone">
          <el-input v-model="editForm.home_phone"></el-input>
        </el-form-item>
        <el-form-item label="家庭地址" prop="home_address">
          <el-input v-model="editForm.home_address"></el-input>
        </el-form-item>
        <el-form-item label="开户银行" prop="acc_bank">
          <el-input v-model="editForm.acc_bank"></el-input>
        </el-form-item>
        <el-form-item label="银行账号" prop="account_no">
          <el-input v-model="editForm.account_no"></el-input>
        </el-form-item>
        <el-form-item label="诚信评级" prop="credit_level">
          <el-select v-model="editForm.credit_level" style="width: 180px">
            <el-option v-for="item in this.credit_level_list"
                :key="item.name"
                :label="item.name"
                :value="item.id">
              </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="editForm.remark" type="textarea" style="width: 470px"></el-input>
        </el-form-item>
      </el-form>
       <!-- 底部区域 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateDriver">保 存</el-button>
      </div>
     </el-dialog>
  </el-card>
</div>
</template>

<script>
  import moment from 'moment'
  export default {
    name: 'Drivers',
    data () {
      return {
        /** 司机类别 */
        driver_type_list: [{
          id: 1,
          name: '内部司机'
        }, {
          id: 2,
          name: '外部司机'
        }],
        /** 诚信评级 */
        credit_level_list: [{
          id: 1,
          name: '优良（AAA）'
        }, {
          id: 2,
          name: '及格（AA）'
        }, {
          id: 3,
          name: '基本及格（A）'
        }, {
          id: 4,
          name: '不及格（B）'
        }],
        addDialogVisible: false,
        editDialogVisible: false,
        tableData: [],
        userList: [],
        /** 列表展示过滤条件信息 */
        queryInfo: {
          query: '',
          page: 1,
          pageSize: 10,
          total: 0
        },
        /** 搜索表单 */
        searchForm: {
          name: '',
          number: '',
          license_no: ''
        },
        addForm: {
          name: '',
          number: '',
          driving_age: moment().valueOf(),
          license_no: '',
          ann_review_date: moment().valueOf(),
          type: 1,
          file_no: '',
          file_date: moment().valueOf(),
          phone: '',
          address: '',
          home_phone: '',
          home_address: '',
          acc_bank: '',
          account_no: '',
          credit_level: 1,
          remark: ''
        },
        addFormRules: {
          name: [
            { required: true, message: '请输入驾驶员姓名', trigger: 'blur' },
            { min: 2, max: 20, message: '长度在2～20个字符之间', trigger: 'blur' }
          ],
          number: [
            { required: true, message: '请输入驾驶员编号', trigger: 'blur' }
          ],
          driving_age: [
            { required: true, message: '请选择驾龄', trigger: 'blur' }
          ],
          license_no: [
            { required: true, message: '请输入驾驶证号', trigger: 'blur' },
            { pattern: /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, message: '请输入正确的驾驶证号', trigger: 'blur' }
          ],
          ann_review_date: [
            { required: true, message: '请选择年审日期', trigger: 'blur' }
          ],
          type: [
            { required: true, message: '请选择司机类型', trigger: 'blur' }
          ],
          file_no: [
            { required: true, message: '请输入档案编号', trigger: 'blur' }
          ],
          file_date: [
            { required: true, message: '请选择建档日期', trigger: 'blur' }
          ],
          phone: [
            { required: true, message: '请输入驾驶员手机号码', trigger: 'blur' },
            { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号码', trigger: 'blur' }
          ],
          home_phone: [
            { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号码', trigger: 'blur' }
          ]
        },
        editForm: {

        },
        editFormRules: {
          name: [
            { required: true, message: '请输入驾驶员姓名', trigger: 'blur' },
            { min: 2, max: 20, message: '长度在2～20个字符之间', trigger: 'blur' }
          ],
          number: [
            { required: true, message: '请输入驾驶员编号', trigger: 'blur' }
          ],
          driving_age: [
            { required: true, message: '请选择驾龄', trigger: 'blur' }
          ],
          license_no: [
            { required: true, message: '请输入驾驶证号', trigger: 'blur' },
            { pattern: /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, message: '请输入正确的驾驶证号', trigger: 'blur' }
          ],
          ann_review_date: [
            { required: true, message: '请选择年审日期', trigger: 'blur' }
          ],
          type: [
            { required: true, message: '请选择司机类型', trigger: 'blur' }
          ],
          file_no: [
            { required: true, message: '请输入档案编号', trigger: 'blur' }
          ],
          file_date: [
            { required: true, message: '请选择建档日期', trigger: 'blur' }
          ],
          phone: [
            { required: true, message: '请输入驾驶员手机号码', trigger: 'blur' },
            { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号码', trigger: 'blur' }
          ],
          home_phone: [
            { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号码', trigger: 'blur' }
          ]
        }
      }
    },
    created () {
      this.getDriverList(this.queryInfo)
    },
    methods: {
      /** 添加或者更新驾驶员信息，通过用户ID来判断 */
      saveUpdateDriver (form) {
        const _form = JSON.parse(JSON.stringify(form))
        _form.driving_age /= 1000
        _form.file_date /= 1000
        _form.ann_review_date /= 1000
        this.$http.post('/driver', _form).then(
          res => {
            // console.log('res :' + res)
            this.$message.success({ message: res.data.msg })
            this.addDialogVisible = false
            this.editDialogVisible = false
            this.getDriverList(this.queryInfo)
          },
          err => {
            // console.log('err.response.data :' + JSON.stringify(err.response.data))
            this.$message.error({ message: err.response.data.msg })
          })
      },
      /** 新增驾驶员 */
      addDriver () {
        this.$refs.addFormRef.validate(valid => {
          if (!valid) return
          this.saveUpdateDriver(this.addForm)
        })
      },
      /** 更新驾驶员 */
      updateDriver () {
        this.$refs.editFormRef.validate(valid => {
          if (!valid) return
          this.saveUpdateDriver(this.editForm)
        })
      },
      /** 搜索事件函数 */
      searchDriver: function () {
        this.getDriverList(this.queryInfo)
      },
      /** 获取驾驶员列表 */
      getDriverList (queryInfo) {
        this.$http.post(`/driver/pagination/${queryInfo.page}/${queryInfo.pageSize}`, this.searchForm).then(
          res => {
            // console.log('this.searchForm :', JSON.stringify(this.searchForm))
            this.queryInfo.total = res.data.data.total
            this.tableData = res.data.data.rows
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      /** 显示编辑驾驶员信息的对话框 */
      handleEdit (index, row) {
        this.editDialogVisible = true
        this.$http.get(`/driver/${row.id}`).then(
          res => {
            const driver = JSON.parse(JSON.stringify(res.data.data))
            driver.file_date *= 1000
            driver.ann_review_date *= 1000
            driver.driving_age *= 1000
            this.editForm = driver
            // console.log("this.editForm :", this.editForm.id);
            this.editDialogVisible = true
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      /** 删除指定的驾驶员记录 */
      handleDelete: function (index, row) {
        this.$http.delete(`/driver/${row.id}`).then(
          res => {
            this.getDeclaranceList(this.queryInfo)
            this.$message({ message: res.data.msg, type: 'success' })
          },
          err => {
            // console.log('err: ' + JSON.stringify(err))
            this.$message({ message: err.response.data.msg, type: 'error' })
          }
        )
      },
      /** 监听添加用户对话框关闭事件 */
      addDialogClosed () {
        this.$refs.addFormRef.resetFields()
      },
      /** 监听修改用户对话框关闭事件 */
      editDialogClosed () {
        this.$refs.editFormRef.resetFields()
      },
      /** 格式化诚信评级名称 */
      formatCreditLevelName: function (row, column, cellValue) {
        let levelName
        for (let i = 0; i < this.credit_level_list.length; i++) {
          levelName = this.credit_level_list[i]
          if (levelName.id === cellValue) {
            return levelName.name
          }
        }
      },
      /** 格式化驾驶员类型名称 */
      formatDriverTypeName: function (row, column, cellValue) {
        let typeName
        for (let i = 0; i < this.driver_type_list.length; i++) {
          typeName = this.driver_type_list[i]
          if (typeName.id === cellValue) {
            return typeName.name
          }
        }
      },
      /** 格式化用户名称 */
      formatUserName: function (row, column, cellValue) {
        let user
        for (let i = 0; i < this.userList.length; i++) {
          user = this.userList[i]
          if (user.id === cellValue) {
            return user.nickname
          }
        }
        return window.sessionStorage.getItem('nickname')
      },
      /** 格式化时间戳为标准的时间字符串 */
      formatDatetime: function (row, column, cellValue) {
        return moment(cellValue * 1000).format('YYYY/MM/DD HH:mm:ss')
      },
      /** 格式化时间戳为标准的时间字符串 */
      formatDate: function (row, column, cellValue) {
        return moment(cellValue * 1000).format('YYYY/MM/DD')
      },
      /** 保留小数位数，x原始值，y是小数保留位 */
      toDecimal: function (x, y) {
        let f = parseFloat(x)
        if (isNaN(f)) {
            return false
        }
        f = Math.round(x * 10 ** y) / 10 ** y
        let s = f.toString()
        let rs = s.indexOf('.')
        if (rs < 0) {
            rs = s.length
            s += '.'
        }
        while (s.length <= rs + y) {
            s += '0'
        }
        return s
      },
      toDecimal3: function (row, column, cellValue) {
        return this.toDecimal(cellValue, 3)
      },
      toDecimal2: function (row, column, cellValue) {
        return this.toDecimal(cellValue, 2)
      },
      /** 分页组件改变每页显示记录数 */
      handleSizeChange (val) {
        // console.log(`每页 ${val} 条`)
        this.queryInfo.pageSize = val
        this.getDriverList(this.queryInfo)
      },
      /** 分页组件改变当前显示页码数 */
      handleCurrentChange (val) {
        // console.log(`当前页: ${val}`)
        this.queryInfo.page = val
        this.getDriverList(this.queryInfo)
      }
    }
  }
</script>

<style scoped>
.el-input .el-date-picker  {
    width: 120px;
  }
</style>
