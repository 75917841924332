<template>
<div>
  <el-breadcrumb separator-class="el-icon-arrow-right">
  <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
  <el-breadcrumb-item>数据可视化</el-breadcrumb-item>
  <el-breadcrumb-item>进仓单视图</el-breadcrumb-item>
  </el-breadcrumb>
  <el-row :gutter="10">
    <el-col :span="6">
      <el-card >
        <div slot="header" class="clearfix">
          <span>进仓总数</span>
          <i class="el-icon-warning-outline" style="float: right; padding: 3px 0"></i>
        </div>
        <div class="bottom clearfix">
          <span class="text item">
            {{formatQfw(godownCount)}}
          </span>
          <el-divider />
          <span>本月新增： {{formatQfw(godownCountCurmonth)}} 单</span>
        </div>
      </el-card>
    </el-col>
    <el-col :span="6">
      <el-card >
        <div slot="header" class="clearfix">
          <span>今日进仓数</span>
          <i class="el-icon-warning-outline" style="float: right; padding: 3px 0"></i>
        </div>
        <div class="bottom clearfix">
          <span class="text item">
            {{formatQfw(godownCountToday)}}
          </span>
          <el-divider />
          <span>昨日新增： {{formatQfw(godownCountYesterday)}} 单</span>
        </div>
      </el-card>
    </el-col>
    <el-col :span="6">
      <el-card >
        <div slot="header" class="clearfix">
          <span>进仓总费用（CNY）</span>
          <i class="el-icon-warning-outline" style="float: right; padding: 3px 0"></i>
        </div>
        <div class="bottom clearfix">
          <span class="text item">
            {{formatQfw(godownTotalSum)}}
          </span>
          <el-divider />
          <span>本月新增： {{formatQfw(godownTotalSumCurmonth)}} CNY</span>
        </div>
      </el-card>
    </el-col>
    <el-col :span="6">
      <el-card >
        <div slot="header" class="clearfix">
          <span>今日进仓费（CNY）</span>
          <i class="el-icon-warning-outline" style="float: right; padding: 3px 0"></i>
        </div>
        <div class="bottom clearfix">
          <span class="text item">
            {{formatQfw(godownTotalSumToday)}}
          </span>
          <el-divider />
          <span>昨日新增： {{formatQfw(godownTotalSumYesterday)}} CNY</span>
        </div>
      </el-card>
    </el-col>
  </el-row>
  <el-row :gutter="10">
    <el-col :span="24">
      <el-card>
        <div class='chart'>
          <v-chart :option="godownDayStatOption" />
        </div>
        <div align="center">
          <el-radio-group v-model="godownDayRadio" @change="changeShowSpan">
            <el-radio :label="1">最近一周</el-radio>
            <el-radio :label="2">最近一月</el-radio>
            <el-radio :label="3">最近三月</el-radio>
          </el-radio-group>
        </div>
      </el-card>
    </el-col>
  </el-row>
  <el-row :gutter="10">
    <el-col :span="24">
      <el-card>
        <div class='chart'>
          <v-chart :option="godownMonthStatOption" />
        </div>
        <div align="center">
          <el-radio-group v-model="godownMonthRadio" @change="changeShowSpanByMonth">
            <el-radio :label="1">最近六月</el-radio>
            <el-radio :label="2">最近一年</el-radio>
            <el-radio :label="3">全部</el-radio>
          </el-radio-group>
        </div>
      </el-card>
    </el-col>
  </el-row>
  <el-row :gutter="10">
    <el-col :span="24">
      <el-card>
        <div class='chart'>
          <v-chart :option="godownYearStatOption" />
        </div>
      </el-card>
    </el-col>
  </el-row>
</div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'HelloWorld',
  data () {
    return {
      godownTotalSum: 0,
      godownCount: 0,
      godownTotalSumToday: 0,
      godownCountToday: 0,
      godownTotalSumYesterday: 0,
      godownCountYesterday: 0,
      godownTotalSumCurmonth: 0,
      godownCountCurmonth: 0,
      godownDayRadio: 2,
      godownMonthRadio: 2,
      godownDayStatOption: {},
      godownMonthStatOption: {},
      godownYearStatOption: {}
    }
  },
  created () {
    this.getGodownCount()
    this.getGodownCountToday()
    this.getGodownCountYesterday()
    this.getGodownCountCurmonth()
    this.changeShowSpan(this.godownDayRadio)
    this.changeShowSpanByMonth(this.godownMonthRadio)
    this.changeShowSpanByYear()
  },
  methods: {
    getGodownCount () {
      this.$http.get('/godown/count').then(
        res => {
          // console.log(res.data)
          this.godownCount = Number(res.data.data.count)
          this.godownTotalSum = Number(res.data.data.total_sum)
        },
        err => {
          this.$message.error({ message: err.response.data.msg })
        }
      )
    },
    getGodownCountToday () {
      const start = moment().startOf('day').valueOf() / 1000
      const end = parseInt(moment().endOf('day').valueOf() / 1000)
      this.$http.get(`/godown/count/${start}/${end}`).then(
        res => {
          // console.log(res.data)
          this.godownCountToday = Number(res.data.data.count)
          this.godownTotalSumToday = Number(res.data.data.total_sum)
        },
        err => {
          this.$message.error({ message: err.response.data.msg })
        }
      )
    },
    getGodownCountYesterday () {
      const start = moment().subtract(1, 'days').startOf('day').valueOf() / 1000
      const end = parseInt(moment().subtract(1, 'days').endOf('day').valueOf() / 1000)
      this.$http.get(`/godown/count/${start}/${end}`).then(
        res => {
          // console.log(res.data)
          this.godownCountYesterday = Number(res.data.data.count)
          this.godownTotalSumYesterday = Number(res.data.data.total_sum)
        },
        err => {
          this.$message.error({ message: err.response.data.msg })
        }
      )
    },
    getGodownCountCurmonth () {
      const start = moment().startOf('month').valueOf() / 1000
      const end = parseInt(moment().endOf('month').valueOf() / 1000)
      this.$http.get(`/godown/count/${start}/${end}`).then(
        res => {
          // console.log(res.data)
          this.godownCountCurmonth = Number(res.data.data.count)
          this.godownTotalSumCurmonth = Number(res.data.data.total_sum)
        },
        err => {
          this.$message.error({ message: err.response.data.msg })
        }
      )
    },
    changeShowSpan (val) {
      let quantity = 1
      let span = 'weeks'
      if (val === 1) {
        quantity = 1
        span = 'weeks'
      } else if (val === 2) {
        quantity = 1
        span = 'months'
      } else if (val === 3) {
        quantity = 3
        span = 'months'
      }
      const startDate = moment().subtract(quantity, span).startOf('day').valueOf() / 1000
      this.getGodownStat(startDate)
    },
    changeShowSpanByMonth (val) {
      let quantity = 1
      let span = ''
      if (val === 1) {
        quantity = 5
        span = 'months'
      } else if (val === 2) {
        quantity = 11
        span = 'months'
      } else {
        quantity = 100
        span = 'months'
      }
      const startDate = moment().subtract(quantity, span).startOf('month').valueOf() / 1000
      this.getGodownStatByMonth(startDate)
    },
    changeShowSpanByYear () {
      const startDate = moment('2021-01-01').startOf('year').valueOf() / 1000
      this.getGodownStatByYear(startDate)
    },
    drawLineChart (title, godownDateList, godownCountList, godownSumList) {
      const option = {
        title: {
          text: title
        },
        legend: {
          data: ['进仓单数', '进仓费用CNY']
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            label: {
              show: true
            }
          }
        },
        toolbox: {
          show: true,
          // orient: 'vertical',
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false, title: '数据视图' },
            magicType: { show: true, type: ['line', 'bar'], title: { line: '切换为折线图', bar: '切换为柱状图' } },
            restore: { show: false },
            saveAsImage: { show: true, title: '另存为图片' }
          }
        },
        xAxis: {
          type: 'category',
          data: godownDateList
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '进仓单数',
            data: godownCountList,
            type: 'bar',
            smooth: true
          },
          {
            name: '进仓费用CNY',
            data: godownSumList,
            type: 'bar',
            smooth: true
          }
        ]
      }
      return option
    },
    getGodownStat (startDate) {
      const type = 'day'
      const title = '进仓单统计图（按天）'
      this.$http.get(`/godown/stat/${type}/${startDate}`).then(
          res => {
            const godownCountList = []
            const godownDateList = []
            const godownSumList = []
            const olist = res.data.data.rows
            for (let i = 0; i < olist.length; i++) {
              godownDateList.push(olist[i].inbound_time)
              godownCountList.push(olist[i].godown_count)
              godownSumList.push(olist[i].godown_sum)
            }
            this.godownDayStatOption = this.drawLineChart(title, godownDateList, godownCountList, godownSumList)
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
    },
    getGodownStatByMonth (startDate) {
      const type = 'month'
      const title = '进仓单统计图（按月）'
      this.$http.get(`/godown/stat/${type}/${startDate}`).then(
          res => {
            const godownCountList = []
            const godownDateList = []
            const godownSumList = []
            const olist = res.data.data.rows
            for (let i = 0; i < olist.length; i++) {
              godownDateList.push(olist[i].inbound_time)
              godownCountList.push(olist[i].godown_count)
              godownSumList.push(olist[i].godown_sum)
            }
            this.godownMonthStatOption = this.drawLineChart(title, godownDateList, godownCountList, godownSumList)
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
    },
    getGodownStatByYear (startDate) {
      const type = 'year'
      const title = '进仓单统计图（按年）'
      this.$http.get(`/godown/stat/${type}/${startDate}`).then(
          res => {
            const godownCountList = []
            const godownDateList = []
            const godownSumList = []
            const olist = res.data.data.rows
            for (let i = 0; i < olist.length; i++) {
              godownDateList.push(olist[i].inbound_time)
              godownCountList.push(olist[i].godown_count)
              godownSumList.push(olist[i].godown_sum)
            }
            this.godownYearStatOption = this.drawLineChart(title, godownDateList, godownCountList, godownSumList)
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
    },
    /** 格式化数字千分位 */
    formatQfw (n) {
      return this.$format.formatQfw(n)
    }
  }
}
</script>

<style scoped>
.bottom {
  margin-top: 13px;
  line-height: 12px;
}
.text {
  font-size: 36px;
}
.item {
  margin-bottom: 18px;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
.chart {
  height: 400px;
}
</style>
