<template>
<div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
    <el-breadcrumb-item>报关管理</el-breadcrumb-item>
    <el-breadcrumb-item>订单汇总</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图区域 -->
    <!-- 搜索视图区 -->
    <el-card>
      <div class="search">
        <el-form :inline="true" :model="searchForm" class="demo-form-inline" style="width: 960px">
          <el-form-item label="订单号">
            <el-input placeholder="请输入订单号" v-model="searchForm.code" clearable style="width: 160px"></el-input>
          </el-form-item>
          <el-form-item label="订单日期">
          <el-date-picker value-format="timestamp" v-model="searchForm.order_time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" style="width: 300px">
          </el-date-picker>
        </el-form-item>
        </el-form>
      </div>
    </el-card>
    <!-- 列表视图区 -->
    <el-card>
      <el-button-group>
        <el-button type="primary" @click="searchOrder" size="small" icon="el-icon-search">查询</el-button>
        <el-button type="primary" @click="addDialogShow" size="small" icon="el-icon-plus">附件</el-button>
      </el-button-group>
      <el-table :data="tableData" style="width: 100%" stripe show-summary :summary-method="getSummaries">
        <el-table-column label="ID" prop="id" width="100px" fixed="left"></el-table-column>
        <el-table-column label="订单号" prop="code" width="200px" :show-overflow-tooltip="true" fixed="left"></el-table-column>
        <el-table-column label="订单日期" prop="order_time" width="165px" :formatter="formatDate"></el-table-column>
        <el-table-column label="商品数量" prop="goods_num" width="120px"></el-table-column>
        <el-table-column label="总重量(KG)" prop="order_weight" width="120px" :formatter="toDecimal3"></el-table-column>
        <el-table-column label="总金额($)" prop="order_sum" width="120px" :formatter="toDecimal3"></el-table-column>
        <el-table-column label="备注" prop="remark" width="172px" :show-overflow-tooltip="true"></el-table-column>
<!--        <el-table-column label="创建者" prop="creator" width="100px" :formatter="formatUserName"></el-table-column>-->
<!--        <el-table-column label="创建时间" prop="create_time" width="165px" :formatter="formatDatetime"></el-table-column>-->
        <el-table-column label="状态" prop="order_status" width="120px">
          <template scope="scope">
            <el-tag type="warning" v-show="scope.row.order_status === 0">doing</el-tag>
            <el-tag type="success" v-show="scope.row.order_status !== 0">completed</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作人" prop="updator" width="100px" :formatter="formatUserName"></el-table-column>
<!--        <el-table-column label="更新时间" prop="update_time" width="165px" :formatter="formatDatetime"></el-table-column>-->
<!--        <el-table-column label="来源" prop="template_id" width="120px">-->
<!--          <template scope="scope">-->
<!--            <el-tag type="success" v-show="scope.row.template_id === 0">手工新增</el-tag>-->
<!--            <el-tag type="warning" v-show="scope.row.template_id !== 0">模版导入</el-tag>-->
<!--          </template>-->
<!--        </el-table-column>-->
        <el-table-column width="180px" fixed="right" align="center" label="操作">
           <template slot-scope="scope">
             <el-button-group>
               <el-button v-show="scope.row.template_id === 0"
                size="mini"
                type="primary"
                icon="el-icon-edit"
                @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
               <el-button v-show="scope.row.template_id !== 0"
                size="mini"
                type="primary"
                icon="el-icon-view"
                @click="handleView(scope.$index, scope.row)">浏览</el-button>
               <el-popconfirm v-show="scope.row.template_id === 0"
                confirm-button-text='好的'
                cancel-button-text='不用了'
                icon="el-icon-info"
                icon-color="red"
                title="确定要删除吗？"
                @confirm="delOrder(scope.$index, scope.row)">
                  <el-button
                    size="mini"
                    type="danger"
                    icon="el-icon-delete"
                    slot="reference">删除</el-button>
              </el-popconfirm>
             </el-button-group>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页视图区 -->
      <el-pagination background style="width: 100%;margin:15px 0px;" class="pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[10, 50, 100, 500]"
        :page-size="queryInfo.pageSize"
        layout="prev, pager, next, jumper,total, sizes"
        :total="queryInfo.total">
     </el-pagination>
    </el-card>
    <!-- 新增订单对话框 -->
     <el-dialog title="新增订单" width="57%" :visible.sync="addDialogVisible" @close="addDialogClosed">
       <!-- 内容主体区 -->
      <el-form :model="addForm" ref="addFormRef" :rules="addFormRules" :inline="true" label-width="100px">
        <el-card class="box-card">
          <el-form-item label="订单号" prop="code">
            <el-input v-model="addForm.code" style="width: 205px"></el-input>
          </el-form-item>
          <el-form-item label="订单日期" prop="order_time">
            <el-date-picker v-model="addForm.order_time" type="date" value-format="timestamp" style="width: 205px"></el-date-picker>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input v-model="addForm.remark" type="textarea" style="width: 520px"></el-input>
          </el-form-item>

        <el-table :data="orderDetailList" style="width: 100%" stripe border>
          <el-table-column type="index" fixed="left" label="序号" width="50"></el-table-column>
          <el-table-column label="商品名" prop="goods" width="160px">
            <template scope="scope">
              <div class="input-box">
                <el-input size="small" v-model="scope.row.goods" ></el-input>
              </div>
           </template>
          </el-table-column>
          <el-table-column label="规格" prop="specs" width="160px">
            <template scope="scope">
                <div class="input-box">
                  <el-input size="small" v-model="scope.row.specs" ></el-input>
                </div>
             </template>
          </el-table-column>
          <el-table-column label="数量" prop="quantity" width="100px">
            <template scope="scope">
                <div class="input-box">
                  <el-input size="small" v-model="scope.row.quantity" ></el-input>
                </div>
             </template>
          </el-table-column>
          <el-table-column label="重量(KG)" prop="weight" width="100px">
            <template scope="scope">
                <div class="input-box">
                  <el-input size="small" v-model="scope.row.weight" ></el-input>
                </div>
             </template>
          </el-table-column>
          <el-table-column label="金额($)" prop="price" width="100px">
            <template scope="scope">
                <div class="input-box">
                  <el-input size="small" v-model="scope.row.price" ></el-input>
                </div>
             </template>
          </el-table-column>
          <el-table-column width="60px" fixed="right" align="center" label="操作">
           <template slot-scope="scope">
             <el-popconfirm
                align="center"
                confirm-button-text='好的'
                cancel-button-text='不用了'
                icon="el-icon-info"
                icon-color="red"
                title="确定要删除吗？"
                @confirm="handleDelete(scope.$index, scope.row)">
                <el-button
                  size="mini"
                  type="danger"
                  icon="el-icon-minus"
                  slot="reference"></el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
        </el-table>
        <el-button size="mini" type="primary" @click="handleNew" icon="el-icon-plus"></el-button>
          </el-card>
      </el-form>
       <!-- 底部区域 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addOrder">保 存</el-button>
      </div>
     </el-dialog>

    <!-- 编辑订单对话框 -->
     <el-dialog :title="formTitle" width="57%" :visible.sync="editDialogVisible" @close="editDialogClosed">
       <!-- 内容主体区 -->
      <el-form :model="editForm" ref="editFormRef" :rules="editFormRules" :inline="true" label-width="100px">
        <el-card class="box-card">
          <el-form-item v-if="false" label="ID" prop="id"></el-form-item>
          <el-form-item label="订单号" prop="code">
            <el-input v-model="editForm.code" style="width: 205px"></el-input>
          </el-form-item>
          <el-form-item label="订单日期" prop="order_time">
            <el-date-picker v-model="editForm.order_time" type="date" value-format="timestamp" style="width: 205px"></el-date-picker>
          </el-form-item>
          <el-form-item  label="状态" prop="order_status">
            <el-switch style="width: 180px"
                v-model="editForm.order_status"
                active-color="#409EFF"
                inactive-color="#C0CCDA"
                :active-value="1"
                :inactive-value="0"></el-switch>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input v-model="editForm.remark" type="textarea" style="width: 520px"></el-input>
          </el-form-item>
        <el-table :data="orderDetailList" style="width: 100%" stripe border>
          <el-table-column type="index" fixed="left" label="序号" width="50"></el-table-column>
          <el-table-column v-if="false" label="ID" prop="id" ></el-table-column>
          <el-table-column label="商品名" prop="goods" width="160px">
            <template scope="scope">
              <div class="input-box">
                <el-input size="small" v-model="scope.row.goods" ></el-input>
              </div>
           </template>
          </el-table-column>
          <el-table-column label="规格" prop="specs" width="160px">
            <template scope="scope">
                <div class="input-box">
                  <el-input size="small" v-model="scope.row.specs" ></el-input>
                </div>
             </template>
          </el-table-column>
          <el-table-column label="数量" prop="quantity" width="100px">
            <template scope="scope">
                <div class="input-box">
                  <el-input size="small" v-model="scope.row.quantity" ></el-input>
                </div>
             </template>
          </el-table-column>
          <el-table-column label="重量(KG)" prop="weight" width="100px">
            <template scope="scope">
                <div class="input-box">
                  <el-input size="small" v-model="scope.row.weight" ></el-input>
                </div>
             </template>
          </el-table-column>
          <el-table-column label="金额($)" prop="price" width="100px">
            <template scope="scope">
                <div class="input-box">
                  <el-input size="small" v-model="scope.row.price" ></el-input>
                </div>
             </template>
          </el-table-column>
          <el-table-column width="60px" fixed="right" align="center" label="操作" v-if="readOnly===false">
           <template slot-scope="scope">
             <el-popconfirm
                align="center"
                confirm-button-text='好的'
                cancel-button-text='不用了'
                icon="el-icon-info"
                icon-color="red"
                title="确定要删除吗？"
                @confirm="handleDelete(scope.$index, scope.row)">
                <el-button
                  size="mini"
                  type="danger"
                  icon="el-icon-minus"
                  slot="reference"></el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
        </el-table>
        <el-button v-if="readOnly===false" size="mini" type="primary" @click="handleNew" icon="el-icon-plus"></el-button>
          </el-card>
      </el-form>
       <!-- 底部区域 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button v-show="!readOnly" type="primary" @click="updateOrder">保 存</el-button>
      </div>
     </el-dialog>
</div>
</template>

<script>
  import moment from 'moment'
  export default {
    name: 'orders',
    data () {
      return {
        sum_list: [],
        readOnly: false,
        addDialogVisible: false,
        editDialogVisible: false,
        addForm: {
          code: 'lmx-',
          goods_num: 0,
          order_weight: 0.000,
          order_sum: 0.000,
          order_time: moment().valueOf(),
          template_id: 0,
          remark: '',
          order_detail: []
        },
        /** 新增订单数据检查 */
        addFormRules: {
          code: [
            { required: true, message: '请输入订单号', trigger: 'blur' },
            { pattern: /^lmx-\d{8,12}$/, message: '前缀不可更改，且后跟8-12位数字！', trigger: 'blur' }
          ],
          order_time: [
            { required: true, message: '请输入订单日期', trigger: 'blur' }
          ],
          order_detail: [
            { required: true, message: '请输入订单详情', trigger: 'blur' }
          ]
        },
        editForm: {

        },
        /** 编辑订单数据检查 */
        editFormRules: {
        },
        orderDetailList: [],
        tableData: [],
        /** 列表展示过滤条件信息 */
        queryInfo: {
          query: '',
          page: 1,
          pageSize: 10,
          total: 0
        },
        /** 搜索表单 */
        searchForm: {
          code: '',
          order_time: [moment().startOf('day').valueOf(), moment().endOf('day').valueOf()]
        },
        formTitle: '编辑订单'
      }
    },
    created () {
      this.getUserList()
      this.getOrderList(this.queryInfo)
      this.editFormRules = this.addFormRules
      this.formTitle = this.readOnly ? '浏览订单' : '编辑订单'
    },
    methods: {
      /** 自定义合计列算法 */
      getSummaries (param) {
        const columns = param.columns
        const sums = []

        sums[0] = '合计'
        for (const key in this.sum_list) {
          for (let i = 1; i < columns.length; i++) {
            // console.log('column.property = ' + columns[i].property)
            if (columns[i].property === key) {
              sums[i] = this.sum_list[key]
              continue
            }
          }
        }
        return sums
      },
      addOrder () {
        this.$refs.addFormRef.validate(valid => {
          if (!valid) return
          this.saveUpdateOrder(this.addForm)
          this.addDialogVisible = false
        })
      },
      updateOrder () {
        this.$refs.editFormRef.validate(valid => {
          if (!valid) return
          this.saveUpdateOrder(this.editForm)
          this.editDialogVisible = false
        })
      },
      saveUpdateOrder (form) {
        const _form = JSON.parse(JSON.stringify(form))
        const _formItem = JSON.parse(JSON.stringify(this.orderDetailList))

        _form.order_detail = _formItem
        _form.order_time = _form.order_time / 1000

        console.log(_form)

        this.$http.post('/order', _form).then(
          res => {
            this.$message.success({ message: res.data.msg })
            this.getOrderList(this.queryInfo)
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      /**
      addDialogShow () {
        this.addDialogVisible = true
        this.$http.get('/order/7769').then(
          res => {
            const obj = res.data.data.order
            obj.order_time *= 1000
            this.addForm = obj
            this.orderDetailList = res.data.data.rows
            this.addDialogVisible = true
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      }, */
      handleNew () {
        this.orderDetailList.push({
          goods: '',
          specs: '',
          weight: 0.000,
          price: 0.000,
          quantity: 1
        })
      },
      /** 删除订单 */
      delOrder (index, row) {
        this.$http.delete(`/order/${row.id}`).then(
          res => {
            this.getOrderList(this.queryInfo)
            this.$message.success({ message: res.data.msg })
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      handleDelete (index, row) {
        this.orderDetailList.splice(index, 1)
      },
      handleView (index, row) {
        this.handleEdit(index, row)
        this.readOnly = true
        this.formTitle = '浏览订单'
      },
      /** 显示编辑客户信息的对话框 */
      handleEdit (index, row) {
        this.readOnly = false
        this.formTitle = '编辑订单'
        this.editDialogVisible = true
        this.$http.get(`/order/${row.id}`).then(
          res => {
            const obj = res.data.data.order
            obj.order_time *= 1000
            this.editForm = obj

            this.orderDetailList = res.data.data.rows

            this.editDialogVisible = true
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      searchOrder: function () {
        this.getOrderList(this.queryInfo)
      },
      addDialogShow () {
        this.addDialogVisible = true
        this.orderDetailList = []
        this.handleNew()
      },
      /** 监听修改用户对话框关闭事件 */
      editDialogClosed () {
        this.$refs.editFormRef.resetFields()
        this.readOnly = false
      },
      /** 监听添加用户对话框关闭事件 */
      addDialogClosed () {
        this.$refs.addFormRef.resetFields()
      },
      /** 得到用户列表 */
      getUserList: function () {
        this.$http.get('/user/list').then(
          res => {
            this.userList = res.data.data.rows
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      /** 获取模版导入记录列表 */
      getOrderList (queryInfo) {
        const endTime = this.searchForm.order_time
        if (endTime) {
          this.searchForm.order_time[1] = moment(endTime[1]).endOf('day').valueOf()
        }
        this.$http.post(`/order/pagination/${queryInfo.page}/${queryInfo.pageSize}`, this.searchForm).then(
          res => {
            this.queryInfo.total = res.data.data.total
            this.tableData = res.data.data.rows
            this.sum_list = res.data.data.sum
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      /** 格式化用户名称 */
      formatUserName: function (row, column, cellValue) {
        let user
        for (let i = 0; i < this.userList.length; i++) {
          user = this.userList[i]
          if (user.id === cellValue) {
            return user.nickname
          }
        }
        return window.sessionStorage.getItem('nickname')
      },
      /** 格式化时间戳为标准的时间字符串 */
      formatDatetime: function (row, column, cellValue) {
        return moment(cellValue * 1000).format('YYYY/MM/DD HH:mm:ss')
      },
      /** 格式化时间戳为标准的日期字符串 */
      formatDate: function (row, column, cellValue) {
        return moment(cellValue * 1000).format('YYYY/MM/DD')
      },
      /** 保留小数位数，x原始值，y是小数保留位 */
      toDecimal: function (x, y) {
        let f = parseFloat(x)
        if (isNaN(f)) {
            return false
        }
        f = Math.round(x * 10 ** y) / 10 ** y
        let s = f.toString()
        let rs = s.indexOf('.')
        if (rs < 0) {
            rs = s.length
            s += '.'
        }
        while (s.length <= rs + y) {
            s += '0'
        }
        return s
      },
      toDecimal3: function (row, column, cellValue) {
        return this.toDecimal(cellValue, 3)
      },
       /** 分页组件改变每页显示记录数 */
      handleSizeChange (val) {
        // console.log(`每页 ${val} 条`)
        this.queryInfo.pageSize = val
        this.getOrderList(this.queryInfo)
      },
      /** 分页组件改变当前显示页码数 */
      handleCurrentChange (val) {
        // console.log(`当前页: ${val}`)
        this.queryInfo.page = val
        this.getOrderList(this.queryInfo)
      }
    }
  }
</script>

<style scoped>
</style>
