<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
    <el-breadcrumb-item>客户管理</el-breadcrumb-item>
    <el-breadcrumb-item>客户列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!--   搜索Form表单区域   -->
    <el-card>
      <el-form v-model="searchForm" :inline="true">
        <el-form-item label="客户名称">
          <el-input v-model="searchForm.name" placeholder="请输入客户名称" clearable style="width: 160px"></el-input>
        </el-form-item>
        <el-form-item label="客户类型">
          <el-select v-model="searchForm.type" placeholder="请选择客户类型" clearable style="width: 160px">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="创建日期">
          <el-date-picker value-format="timestamp" v-model="searchForm.create_time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" style="width: 240px">
          </el-date-picker>
        </el-form-item>
      </el-form>
    </el-card>
    <!--   客户列表区域   -->
    <el-card>
      <el-button-group>
        <el-button type="primary" @click="searchCustomer" size="small" icon="el-icon-search">查询</el-button>
        <el-button type="primary" @click="addDialogVisible=true" size="small" icon="el-icon-plus">新增</el-button>
      </el-button-group>
      <el-table :data="tableData" style="width: 100%" stripe>
        <el-table-column label="ID" prop="id" width="60px" fixed="left"></el-table-column>
        <el-table-column label="客户名称" prop="name" width="240px" fixed="left" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column label="类型" prop="type" width="100px" :formatter="formatTypeName"></el-table-column>
        <el-table-column label="联系人" prop="contact_name" width="80px" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column label="联系电话" prop="contact_phone" width="120px"></el-table-column>
        <el-table-column label="地址" prop="address" width="300" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column label="创建人" prop="creator" width="100px" :formatter="formatUserName"></el-table-column>
        <el-table-column label="创建时间" prop="create_time" width="165px" :formatter="formatDatetime"></el-table-column>
        <el-table-column label="计费模式" prop="billing_mode" width="100px" :formatter="formatBillingMode"></el-table-column>
        <el-table-column label="计件单价(元/件）" prop="qty_price" width="100px" :formatter="toDecimal3"></el-table-column>
        <el-table-column label="计重单价(元/KG）" prop="qlty_price" width="100px" :formatter="toDecimal3"></el-table-column>
        <el-table-column label="计体积单价(元/m^3）" prop="cu_price" width="100px" :formatter="toDecimal3"></el-table-column>
        <el-table-column label="最低收费(元）" prop="min_sum" width="100px" :formatter="toDecimal2"></el-table-column>
        <el-table-column width="200px" fixed="right" align="left" label="操作">
           <template slot-scope="scope">
             <el-button-group>
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-edit"
                @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
              <el-popconfirm
                confirm-button-text='好的'
                cancel-button-text='不用了'
                icon="el-icon-info"
                icon-color="red"
                title="确定要删除吗？"
                @confirm="handleDelete(scope.$index, scope.row)">
                  <el-button
                    size="mini"
                    type="danger"
                    icon="el-icon-delete"
                    slot="reference">删除</el-button>
              </el-popconfirm>
             </el-button-group>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination background style="width: 100%;margin:15px 0px;" class="pagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryInfo.page"
      :page-sizes="[3, 6, 10, 20]"
      :page-size="queryInfo.pageSize"
      layout="prev, pager, next, jumper,total, sizes"
      :total="queryInfo.total">
     </el-pagination>
     <!--  新增客户对话框区域    -->
      <el-dialog title="添加客户" :visible.sync="addDialogVisible" @close="addDialogClosed"  style="margin-top:1px;margin-bottom: 1px">
       <!-- 内容主体区 -->
        <h4  style="margin-top:1px;margin-bottom: 1px">客户基础信息</h4>
        <el-divider content-position="left"  style="margin-top:1px;margin-bottom: 1px"></el-divider>
        <el-form :model="addForm" ref="addFormRef" :rules="addFormRules" :inline="true" label-width="100px">
          <el-form-item label="客户名称" prop="name">
            <el-input v-model="addForm.name" style="width: 200px"></el-input>
          </el-form-item>
          <el-form-item label="客户类型" prop="type">
            <el-select v-model="addForm.type" style="width: 200px">
              <el-option v-for="item in this.options"
              :key="item.value"
              :label="item.label"
              :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="联系人" prop="contact_name">
            <el-input v-model="addForm.contact_name" style="width: 200px"></el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="contact_phone">
            <el-input v-model="addForm.contact_phone" style="width: 200px"></el-input>
          </el-form-item>
          <el-form-item label="联系地址" prop="address">
            <el-input v-model="addForm.address" style="width: 510px"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input v-model="addForm.remark" type="textarea" style="width: 510px"></el-input>
          </el-form-item>
          <h4  style="margin-top:1px;margin-bottom: 1px">客户定价</h4>
          <el-divider content-position="left"  style="margin-top:1px;margin-bottom: 1px"></el-divider>
          <el-form-item label="计费模式" prop="qty_price">
            <el-select v-model="addForm.billing_mode" style="width: 200px">
              <el-option v-for="item in this.bm_options"
              :key="item.value"
              :label="item.label"
              :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="计件单价" prop="qty_price">
            <el-input v-model="addForm.qty_price" style="width: 200px"><template slot="append">元/件</template></el-input>
          </el-form-item>
          <el-form-item label="计重单价" prop="qty_price">
            <el-input v-model="addForm.qlty_price" style="width: 200px"><template slot="append">元/KG</template></el-input>
          </el-form-item>
          <el-form-item label="计体积单价" prop="qty_price">
            <el-input v-model="addForm.cu_price" style="width: 200px"><template slot="append">元/m^3</template></el-input>
          </el-form-item>
          <el-form-item label="最低收费" prop="qty_price">
            <el-input v-model="addForm.min_sum" style="width: 200px"><template slot="append">元</template></el-input>
          </el-form-item>
        </el-form>
        <!-- 底部区域 -->
        <div slot="footer" class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addCustomer">保 存</el-button>
        </div>
      </el-dialog>
     <!--  编辑客户对话框区域    -->
      <el-dialog title="编辑客户" :visible.sync="editDialogVisible" @close="editDialogClosed"  style="margin-top:1px;margin-bottom: 1px">
       <!-- 内容主体区 -->
        <h4  style="margin-top:1px;margin-bottom: 1px">客户基础信息</h4>
        <el-divider content-position="left"  style="margin-top:1px;margin-bottom: 1px"></el-divider>
        <el-form :model="editForm" ref="editFormRef" :rules="editFormRules" :inline="true" label-width="100px">
          <el-form-item label="客户名称" prop="name">
            <el-input v-model="editForm.name" style="width: 200px"></el-input>
          </el-form-item>
          <el-form-item label="客户类型" prop="type">
            <el-select v-model="editForm.type" style="width: 200px">
              <el-option v-for="item in this.options"
              :key="item.value"
              :label="item.label"
              :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="联系人" prop="contact_name">
            <el-input v-model="editForm.contact_name" style="width: 200px"></el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="contact_phone">
            <el-input v-model="editForm.contact_phone" style="width: 200px"></el-input>
          </el-form-item>
          <el-form-item label="联系地址" prop="address">
            <el-input v-model="editForm.address" style="width: 510px"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input v-model="editForm.remark" type="textarea" style="width: 510px"></el-input>
          </el-form-item>
          <h4  style="margin-top:1px;margin-bottom: 1px">客户定价</h4>
          <el-divider content-position="left"  style="margin-top:1px;margin-bottom: 1px"></el-divider>
          <el-form-item label="计费模式" prop="qty_price">
            <el-select v-model="editForm.billing_mode" style="width: 200px">
              <el-option v-for="item in this.bm_options"
              :key="item.value"
              :label="item.label"
              :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="计件单价" prop="qty_price">
            <el-input v-model="editForm.qty_price" style="width: 200px"><template slot="append">元/件</template></el-input>
          </el-form-item>
          <el-form-item label="计重单价" prop="qty_price">
            <el-input v-model="editForm.qlty_price" style="width: 200px"><template slot="append">元/KG</template></el-input>
          </el-form-item>
          <el-form-item label="计体积单价" prop="qty_price">
            <el-input v-model="editForm.cu_price" style="width: 200px"><template slot="append">元/m^3</template></el-input>
          </el-form-item>
          <el-form-item label="最低收费" prop="qty_price">
            <el-input v-model="editForm.min_sum" style="width: 200px"><template slot="append">元</template></el-input>
          </el-form-item>
        </el-form>
        <!-- 底部区域 -->
        <div slot="footer" class="dialog-footer">
          <el-button @click="editDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="editCustomer">保 存</el-button>
        </div>
      </el-dialog>
    </el-card>
  </div>
</template>
<script>
  import moment from 'moment'
  export default {
    name: 'Customers',
    data () {
      return {
        editDialogVisible: false,
        addDialogVisible: false,
        tableData: [],
        options: [
          {
            value: 0,
            label: '公司'
          }, {
            value: 1,
            label: '个人'
          }
        ],
        bm_options: [
          {
            value: 0,
            label: '按件数'
          }, {
            value: 1,
            label: '按重量'
          }, {
            value: 2,
            label: '按体积'
          }
        ],
        value: 0,
        queryInfo: {
          query: '',
          page: 1,
          pageSize: 10,
          total: 0
        },
        /** 搜索表单对象 */
        searchForm: {
          name: '',
          type: 0,
          create_time: [moment().subtract(1, 'months').valueOf(), moment().valueOf()]
        },
        /** 添加客户表单对象 */
        addForm: {
          id: 0,
          name: '',
          type: 0,
          contact_name: '',
          contact_phone: '',
          address: '',
          remark: '',
          qty_price: 0.000,
          qlty_price: 0.000,
          cu_price: 0.000,
          min_sum: 0.00
        },
        /** 新增用户数据检查 */
        addFormRules: {
          name: [
            { required: true, message: '请输入客户名称', trigger: 'blur' },
            { min: 2, max: 100, message: '长度在2～100个字符之间', trigger: 'blur' }
          ],
          type: [
            { required: true, message: '请选择客户类型', trigger: 'blur' }
          ],
          contact_name: [
            { required: true, message: '请输入客户联系人名称', trigger: 'blur' },
            { min: 2, max: 20, message: '长度在2～20个字符之间', trigger: 'blur' }
          ],
          contact_phone: [
            { required: true, message: '请输入客户联系人手机号码', trigger: 'blur' },
            { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号码', trigger: 'blur' }
          ],
          address: [
            { required: true, message: '请输入客户联系地址', trigger: 'blur' }
          ],
          billing_mode: [
            { required: true, message: '请输入选择计费模式', trigger: 'blur' }
          ],
           qty_price: [
            { required: true, message: '请输入计件单价', trigger: 'blur' },
             { pattern: /^(0|[1-9]\d{0,9})(\.\d{0,3})?$/, message: '请输入正确的计件单价，保留3位小数', trigger: 'blur' }
          ],
           qlty_price: [
            { required: true, message: '请输入计量单价', trigger: 'blur' },
             { pattern: /^(0|[1-9]\d{0,9})(\.\d{0,3})?$/, message: '请输入正确的计量单价，保留3位小数', trigger: 'blur' }
          ],
           cu_price: [
            { required: true, message: '请输入计体积单价', trigger: 'blur' },
             { pattern: /^(0|[1-9]\d{0,9})(\.\d{0,3})?$/, message: '请输入正确的计体积单价，保留3位小数', trigger: 'blur' }
          ],
           min_sum: [
            { required: true, message: '请输入最低收费', trigger: 'blur' },
             { pattern: /^(0|[1-9]\d{0,9})(\.\d{0,2})?$/, message: '请输入正确的最低收费，保留2位小数', trigger: 'blur' }
          ]
        },
        /** 编辑客户表单对象 */
        editForm: {

        },
        /** 编辑客户数据检查 */
        editFormRules: {
          name: [
            { required: true, message: '请输入客户名称', trigger: 'blur' },
            { min: 2, max: 100, message: '长度在2～100个字符之间', trigger: 'blur' }
          ],
          type: [
            { required: true, message: '请选择客户类型', trigger: 'blur' }
          ],
          contact_name: [
            { required: true, message: '请输入客户联系人名称', trigger: 'blur' },
            { min: 2, max: 20, message: '长度在2～20个字符之间', trigger: 'blur' }
          ],
          contact_phone: [
            { required: true, message: '请输入客户联系人手机号码', trigger: 'blur' },
            { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号码', trigger: 'blur' }
          ],
          address: [
            { required: true, message: '请输入客户联系地址', trigger: 'blur' }
          ],
          billing_mode: [
            { required: true, message: '请输入选择计费模式', trigger: 'blur' }
          ],
           qty_price: [
            { required: true, message: '请输入计件单价', trigger: 'blur' },
             { pattern: /^(0|[1-9]\d{0,9})(\.\d{0,3})?$/, message: '请输入正确的计件单价，保留3位小数', trigger: 'blur' }
          ],
           qlty_price: [
            { required: true, message: '请输入计量单价', trigger: 'blur' },
             { pattern: /^(0|[1-9]\d{0,9})(\.\d{0,3})?$/, message: '请输入正确的计量单价，保留3位小数', trigger: 'blur' }
          ],
           cu_price: [
            { required: true, message: '请输入计体积单价', trigger: 'blur' },
             { pattern: /^(0|[1-9]\d{0,9})(\.\d{0,3})?$/, message: '请输入正确的计体积单价，保留3位小数', trigger: 'blur' }
          ],
           min_sum: [
            { required: true, message: '请输入最低收费', trigger: 'blur' },
             { pattern: /^(0|[1-9]\d{0,9})(\.\d{0,2})?$/, message: '请输入正确的最低收费，保留2位小数', trigger: 'blur' }
          ]
        }
      }
    },
    created: function () {
      this.getCustomerList(this.queryInfo)
      this.getUserList()
    },
    methods: {
      /** 查询客户列表 */
      searchCustomer: function () {
        this.getCustomerList(this.queryInfo)
      },
      /** 获取客户列表 */
      getCustomerList (queryInfo) {
        const endTime = this.searchForm.create_time
        if (endTime) {
          this.searchForm.create_time[1] = moment(endTime[1]).endOf('day').valueOf()
        }
        this.searchForm.type = (this.searchForm.type === '') ? null : this.searchForm.type
        this.$http.post(`/customer/pagination/${queryInfo.page}/${queryInfo.pageSize}`, this.searchForm).then(
          res => {
            // console.log('this.searchForm :', JSON.stringify(this.searchForm))
            this.queryInfo.total = res.data.data.total
            this.tableData = res.data.data.rows
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      /** 获取用户列表 */
      getUserList: function () {
        this.$http.get('/user/list').then(
          res => {
            this.userList = res.data.data.rows
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      /** 格式化用户名称 */
      formatUserName: function (row) {
        let user
        for (let i = 0; i < this.userList.length; i++) {
          user = this.userList[i]
          // console.log('i=' + i)
          if (user.id === row.creator) {
            // console.log(customer)
            return user.nickname
          }
        }
      },
      /** 格式化类型名称 */
      formatTypeName: function (row) {
        return row.type === 0 ? '公司' : '个人'
      },
      /** 格式化计费模式 */
      formatBillingMode: function (row, column, cellValue) {
        return cellValue === 0 ? '按件数' : cellValue === 1 ? '按重量' : '按体积'
      },
      /** 格式化时间戳为标准的时间字符串 */
      formatDatetime: function (row, column, cellValue) {
        return moment(cellValue * 1000).format('YYYY/MM/DD HH:mm:ss')
      },
      /** 显示编辑客户信息的对话框 */
      handleEdit (index, row) {
        this.editDialogVisible = true
        this.$http.get(`/customer/${row.id}`).then(
          res => {
            // console.log("res :", res);
            this.editForm = res.data.data.rows
            this.editDialogVisible = true
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      /** 删除当前客户信息 */
      handleDelete (index, row) {
        this.$http.delete(`/customer/${row.id}`).then(
          res => {
            this.getCustomerList(this.queryInfo)
            this.$message({ message: res.data.msg, type: 'success' })
          },
          err => {
            // console.log('err: ' + JSON.stringify(err))
            this.$message({ message: err.response.data.msg, type: 'error' })
          }
        )
      },
      /** 改变列表显示记录数触发事件 */
      handleSizeChange (val) {
        this.queryInfo.pageSize = val
        this.getCustomerList(this.queryInfo)
      },
      handleCurrentChange (val) {
        this.queryInfo.page = val
        this.getCustomerList(this.queryInfo)
      },
      /** 添加或者更新客户信息，通过用户ID来区分新增和编辑 */
      saveUpdateCustomer (form) {
        this.$http.post('/customer', JSON.stringify(form)).then(
          res => {
            // console.log('res :' + res)
            this.$message.success({ message: res.data.msg })
            this.addDialogVisible = false
            this.editDialogVisible = false
            this.getCustomerList(this.queryInfo)
          },
          err => {
            // console.log('err.response.data :' + JSON.stringify(err.response.data))
            this.$message.error({ message: err.response.data.msg })
          })
      },
      /** 添加客户 */
      addCustomer () {
        this.$refs.addFormRef.validate(valid => {
          if (!valid) return
          this.saveUpdateCustomer(this.addForm)
        })
      },
      /** 监听添加用户对话框关闭事件 */
      addDialogClosed () {
        this.$refs.addFormRef.resetFields()
      },
      /** 修改客户信息并提交 */
      editCustomer () {
        this.$refs.editFormRef.validate(valid => {
          if (!valid) return
          this.saveUpdateCustomer(this.editForm)
        })
      },
      /** 监听编辑用户对话框关闭事件 */
      editDialogClosed () {
        this.$refs.editFormRef.resetFields()
      },
      /** 保留小数位数，x原始值，y是小数保留位 */
      toDecimal: function (x, y) {
        let f = parseFloat(x)
        if (isNaN(f)) {
            return false
        }
        f = Math.round(x * 10 ** y) / 10 ** y
        let s = f.toString()
        let rs = s.indexOf('.')
        if (rs < 0) {
            rs = s.length
            s += '.'
        }
        while (s.length <= rs + y) {
            s += '0'
        }
        return s
      },
      toDecimal3: function (row, column, cellValue) {
        return this.toDecimal(cellValue, 3)
      },
      toDecimal2: function (row, column, cellValue) {
        return this.toDecimal(cellValue, 2)
      }
    }
  }
</script>

<style lang="less" scoped>

</style>
