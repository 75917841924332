<template>
<div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
    <el-breadcrumb-item>报关管理</el-breadcrumb-item>
    <el-breadcrumb-item>导入模版管理</el-breadcrumb-item>
    </el-breadcrumb>
  <!-- 卡片视图区域 -->
    <!-- 搜索视图区 -->
    <el-card>
      <div class="search">
        <el-form :inline="true" :model="searchForm" class="demo-form-inline" style="width: 960px">
          <el-form-item label="文件名">
            <el-input placeholder="请输入模版文件名称" v-model="searchForm.name" clearable style="width: 160px"></el-input>
          </el-form-item>
          <el-form-item label="创建日期">
          <el-date-picker value-format="timestamp" v-model="searchForm.create_time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" style="width: 300px">
          </el-date-picker>
        </el-form-item>
        </el-form>
      </div>
    </el-card>
  <!-- 列表视图区 -->
    <el-card>
      <el-button-group>
        <el-button type="primary" @click="searchTemplate" size="small" icon="el-icon-search">查询</el-button>
        <el-button type="primary" @click="addDialogShow" size="small" icon="el-icon-plus">报关录入（订单）</el-button>
      </el-button-group>
      <el-table :data="tableData" style="width: 100%" stripe show-summary :summary-method="getSummaries">
        <el-table-column label="ID" prop="id" width="60px" fixed="left"></el-table-column>
        <el-table-column label="文件名" prop="name" width="200px" fixed="left" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column label="订单日期" prop="order_date" width="120px" :formatter="formatDate"></el-table-column>
<!--        <el-table-column label="路径" prop="path" width="300px" :show-overflow-tooltip="true"></el-table-column>-->
        <el-table-column label="订单数" prop="order_num" width="120px"></el-table-column>
        <el-table-column label="总行数" prop="row_num" width="120px"></el-table-column>
        <el-table-column label="总重量(KG)" prop="total_weight" width="120px" :formatter="toDecimal3"></el-table-column>
        <el-table-column label="总金额($)" prop="total_sum" width="120px" :formatter="toDecimal3"></el-table-column>
<!--        <el-table-column label="备注" prop="remark" width="300px" :show-overflow-tooltip="true"></el-table-column>-->
        <el-table-column label="创建者" prop="creator" width="100px" :formatter="formatUserName"></el-table-column>
        <el-table-column label="创建时间" prop="create_time" width="165px" :formatter="formatDatetime"></el-table-column>
<!--        <el-table-column label="更新者" prop="updator" width="100px" :formatter="formatUserName"></el-table-column>-->
<!--        <el-table-column label="更新时间" prop="update_time" width="165px" :formatter="formatDatetime"></el-table-column>-->
        <el-table-column width="100px" fixed="right" align="left" label="操作">
           <template slot-scope="scope">
            <el-popconfirm
              confirm-button-text='好的'
              cancel-button-text='不用了'
              icon="el-icon-info"
              icon-color="red"
              title="确定要删除吗？"
              @confirm="handleDelete(scope.$index, scope.row)">
                <el-button
                  size="mini"
                  type="danger"
                  icon="el-icon-delete"
                  slot="reference">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页视图区 -->
      <el-pagination background style="width: 100%;margin:15px 0px;" class="pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[3, 6, 10, 20]"
        :page-size="queryInfo.pageSize"
        layout="prev, pager, next, jumper,total, sizes"
        :total="queryInfo.total">
     </el-pagination>
      <!-- 新增报关费用单据对话框 -->
     <el-dialog title="选择要导入的Excel模版" :visible.sync="addDialogVisible" @close="addDialogClosed" :close-on-click-modal="false">
       <!-- 内容主体区 -->
      <el-form :model="addForm" ref="addFormRef" :rules="addFormRules" :inline="true" label-width="100px">
        <el-upload
          class="upload-demo"
          ref="upload"
          :drag="true"
          :on-change="fileChange"
          :file-list="fileList"
          :auto-upload="false"
          :multiple="false"
          :limit=limitNum
          action="#"
          :on-remove="fileRemove"
          :on-exceed="handleExceed">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">只能上传xls/xlsx文件，且不超过5MB</div>
        </el-upload>
      </el-form>
       <!-- 底部区域 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button v-loading.fullscreen.lock="fullscreenLoading" type="primary" @click="importExcel">上传并导入数据</el-button>
      </div>
     </el-dialog>
    </el-card>
</div>
</template>

<script>
  import moment from 'moment'
  export default {
    name: 'Templates',
    data () {
      return {
        sum_list: [],
        fullscreenLoading: false,
        excels: [],
        uploadForm: {
          file: ''
        },
        limitNum: 1,
        /** 文件列表 */
        fileList: [],
        /** 列表数据 */
        tableData: [],
        /** 列表展示过滤条件信息 */
        queryInfo: {
          query: '',
          page: 1,
          pageSize: 10,
          total: 0
        },
        /** 搜索表单 */
        searchForm: {
          name: '',
          create_time: [moment().subtract(1, 'months').valueOf(), moment().valueOf()]
        },
        addDialogVisible: false,
        editDialogVisible: false,
        addForm: {
          name: '',
          path: '',
          order_num: 0,
          row_num: 0,
          total_weight: 0.000,
          total_sum: 0.000,
          remark: ''
        },
        /** 新增报关费用数据检查 */
        addFormRules: {

        },
        editForm: {

        },
        /** 编辑报关费用数据检查 */
        editFormRules: {

        }
      }
    },
    created () {
      this.getUserList()
      this.getTemplateList(this.queryInfo)
    },
    methods: {
      /** 自定义合计列算法 */
      getSummaries (param) {
        const columns = param.columns
        const sums = []

        sums[0] = '合计'
        for (const key in this.sum_list) {
          for (let i = 1; i < columns.length; i++) {
            if (columns[i].property === key) {
              sums[i] = this.sum_list[key]
              continue
            }
          }
        }
        return sums
      },
      searchTemplate: function () {
        this.getTemplateList(this.queryInfo)
      },
      addDialogShow () {
        this.addDialogVisible = true
      },
      /** 得到用户列表 */
      getUserList: function () {
        this.$http.get('/user/list').then(
          res => {
            this.userList = res.data.data.rows
            // this.customerList_all = JSON.parse(JSON.stringify(this.customerList))
            // this.customerList_all.unshift({ id: 0, name: '全部' })
            // console.log(this.customerList)
            // console.log(this.customerList_all)
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      /** 获取模版导入记录列表 */
      getTemplateList (queryInfo) {
        const endTime = this.searchForm.create_time
        if (endTime) {
          this.searchForm.create_time[1] = moment(endTime[1]).endOf('day').valueOf()
        }
        this.$http.post(`/template/pagination/${queryInfo.page}/${queryInfo.pageSize}`, this.searchForm).then(
          res => {
            // console.log('this.searchForm :', JSON.stringify(this.searchForm))
            this.queryInfo.total = res.data.data.total
            this.tableData = res.data.data.rows
            this.sum_list = res.data.data.sum
            // console.log('data: ' + JSON.stringify(this.tableData))
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
       /** 显示编辑报关费用信息的对话框 */
      handleEdit (index, row) {
        this.editDialogVisible = true
        this.$http.get(`/template/${row.id}`).then(
          res => {
            this.editForm = res.data.data
            // console.log("this.editForm :", this.editForm.id);
            this.editDialogVisible = true
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      /** 删除指定模版及其下的所有订单 */
      handleDelete: function (index, row) {
        this.$http.delete(`/template/${row.id}`).then(
          res => {
            this.getTemplateList(this.queryInfo)
            this.$message({ message: res.data.msg, type: 'success' })
          },
          err => {
            // console.log('err: ' + JSON.stringify(err))
            this.$message({ message: err.response.data.msg, type: 'error' })
          }
        )
      },
      /** 监听添加用户对话框关闭事件 */
      addDialogClosed () {
        // this.$refs.addFormRef.resetFields()
        this.clearUploadControl()
      },
      /** 监听修改用户对话框关闭事件 */
      editDialogClosed () {
        this.$refs.editFormRef.resetFields()
      },
      /** 格式化用户名称 */
      formatUserName: function (row, column, cellValue) {
        let user
        for (let i = 0; i < this.userList.length; i++) {
          user = this.userList[i]
          if (user.id === cellValue) {
            return user.nickname
          }
        }
        return window.sessionStorage.getItem('nickname')
      },
      /** 格式化时间戳为标准的时间字符串 */
      formatDatetime: function (row, column, cellValue) {
        return moment(cellValue * 1000).format('YYYY/MM/DD HH:mm:ss')
      },
      /** 格式化日期戳为标准的日期字符串 */
      formatDate: function (row, column, cellValue) {
        return moment(cellValue * 1000).format('YYYY/MM/DD')
      },
      /** 保留小数位数，x原始值，y是小数保留位 */
      toDecimal: function (x, y) {
        let f = parseFloat(x)
        if (isNaN(f)) {
            return false
        }
        f = Math.round(x * 10 ** y) / 10 ** y
        let s = f.toString()
        let rs = s.indexOf('.')
        if (rs < 0) {
            rs = s.length
            s += '.'
        }
        while (s.length <= rs + y) {
            s += '0'
        }
        return s
      },
      toDecimal3: function (row, column, cellValue) {
        return this.toDecimal(cellValue, 3)
      },
      /** 上传Excel到指定目录，并导入数据 */
      importExcel () {
        if (this.fileList.length === 0) {
          this.$message.warning('请选择要上传Excel模版文件')
        } else {
          const wfForm = new FormData()

          Object.entries(this.excels).forEach(file => {
          file[1].forEach(item => {
            wfForm.append('file', item.raw)
            wfForm.append(item.name, file[0])
            // console.log(wfForm)
          })
        })
          /** 显示全屏的等待层，限制用户操作！ */
          // this.fullscreenLoading = true
          this.addDialogVisible = false
          const loading = this.$loading({
            lock: true,
            text: '等待时长由Excel记录行数决定，请耐心等待（切记：不要离开该页面！）...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })

          this.$http.post('/upload/excel', wfForm, { headers: { enctype: 'multipart/form-data' } }).then(
            res => {
              // this.$message.success('上传成功！')
              console.log(res.data.data.filename)
              this.clearUploadControl()

              this.$http.post('/template/import', `{"filename": "${res.data.data.filename}"}`).then(
                res => {
                  this.$message.success({ message: res.data.msg })
                  this.getTemplateList(this.queryInfo)
                  /** 关闭加载遮罩 */
                  loading.close()
                },
                err => {
                  console.log(err.response)
                  this.$message.error({ message: err.response.data.msg })
                  loading.close()
                }
              )
            },
            err => {
              this.$message.error({ message: err.message })
              loading.close()
            }).finally(() => {
              // this.fullscreenLoading = false
          })
          // this.fullscreenLoading = false
        }
      },
      /** 超出选择文件触发事件 */
      handleExceed (files, fileList) {
        this.$message.warning(`一次只能上传 ${this.limitNum} 个文件！`)
      },
      /** 删除文件列表中文件 */
      fileRemove (file, fileList, index) {
        this.clearUploadControl()
      },
      /** 清空el-upload控件数据 */
      clearUploadControl () {
        this.fileList = []
        this.excels = []
      },
      /** 文件状态改变时的钩子 */
      fileChange (file, fileList) {
        // console.log('file.raw: ' + JSON.stringify(file.raw))
        // console.log(file.raw)
        if (this.beforeUploadFile(file)) {
          // this.$message.success('OK')
          this.excels.file = fileList
          this.fileList.push(file)
        } else {
          this.clearUploadControl()
        }
      },
      beforeUploadFile (file) {
        // 文件类型
        const isType = (file.raw.type === 'application/vnd.ms-excel')
        const isTypeComputer = (file.raw.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        // console.log(isType)
        // console.log(isTypeComputer)
        const fileType = isType || isTypeComputer
        if (!fileType) {
          this.$message.error('上传文件只能是xls/xlsx格式！')
        }
        // 文件大小限制为5M
        const fileLimit = file.size / 1024 / 1024 < 5
        if (!fileLimit) {
          this.$message.error('上传文件大小不超过5M！')
        }
        return fileType && fileLimit
      },
      /** 分页组件改变每页显示记录数 */
      handleSizeChange (val) {
        // console.log(`每页 ${val} 条`)
        this.queryInfo.pageSize = val
        this.getTemplateList(this.queryInfo)
      },
      /** 分页组件改变当前显示页码数 */
      handleCurrentChange (val) {
        // console.log(`当前页: ${val}`)
        this.queryInfo.page = val
        this.getTemplateList(this.queryInfo)
      }
    }
  }
</script>

<style scoped>

</style>
