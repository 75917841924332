<template>
<div>
  <el-breadcrumb separator-class="el-icon-arrow-right">
  <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
  <el-breadcrumb-item>数据可视化</el-breadcrumb-item>
  <el-breadcrumb-item>进仓利润视图</el-breadcrumb-item>
  </el-breadcrumb>
  <el-row :gutter="10">
    <el-col :span="12">
      <el-card>
        <div class='chart'>
          <v-chart :option="godownProfitOption" />
        </div>
      </el-card>
    </el-col>
    <el-col :span="12">
      <el-card>
        <div class='chart'>
          <v-chart :option="customerPacketOption" />
        </div>
      </el-card>
    </el-col>
  </el-row>
  <el-row :gutter="10">
    <el-col :span="24">
      <el-card>
        <div class='chart'>
          <v-chart :option="cusProfitOption" />
        </div>
      </el-card>
    </el-col>
  </el-row>
  <el-row :gutter="10">
    <el-col :span="24">
      <el-card>
        <div class='chart'>
          <v-chart :option="godownProfitStatOption" />
        </div>
        <div align="center">
          <el-radio-group v-model="radio" @change="changeShowSpan">
            <el-radio :label="1">最近一周</el-radio>
            <el-radio :label="2">最近一月</el-radio>
            <el-radio :label="3">最近三月</el-radio>
          </el-radio-group>
        </div>
      </el-card>
    </el-col>
  </el-row>
</div>
</template>

<script>
export default {
  name: 'Profitview',
  data () {
    return {
      cusProfitOption: {},
      godownProfitOption: {},
      customerPacketOption: {},
      radio: 2,
      godownProfitStatOption: {}
    }
  },
  created () {
    this.getCusProfit()
    this.getGodownProfitTop10()

    this.getCustomerPacketTop10()

    this.changeShowSpan(this.radio)
  },
  methods: {
    changeShowSpan (val) {
      let quantity = 1
      let span = 'weeks'
      if (val === 1) {
        quantity = 1
        span = 'weeks'
      } else if (val === 2) {
        quantity = 1
        span = 'months'
      } else if (val === 3) {
        quantity = 3
        span = 'months'
      }
      const startDate = this.$moment().subtract(quantity, span).startOf('day').valueOf() / 1000
      this.getGodownProfitStat(startDate)
    },
    getGodownProfitStatOption (godownDateList, packetList, profitList) {
      const option = {
        title: {
          text: '进仓单利润统计图'
        },
        legend: {
          data: ['包裹数', '利润CNY']
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            label: {
              show: true
            }
          }
        },
        toolbox: {
          show: true,
          // orient: 'vertical',
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false, title: '数据视图' },
            magicType: { show: true, type: ['line', 'bar'], title: { line: '切换为折线图', bar: '切换为柱状图' } },
            restore: { show: false },
            saveAsImage: { show: true, title: '另存为图片' }
          }
        },
        xAxis: {
          type: 'category',
          data: godownDateList
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '包裹数',
            data: packetList,
            type: 'bar',
            smooth: true
          },
          {
            name: '利润CNY',
            data: profitList,
            type: 'bar',
            smooth: true
          }
        ]
      }
      return option
    },
    getGodownProfitStat (startDate) {
      this.$http.get(`/godown/profit/stat/${startDate}`).then(
          res => {
            const packetList = []
            const godownDateList = []
            const profitList = []
            const olist = res.data.data.rows
            for (let i = 0; i < olist.length; i++) {
              godownDateList.push(olist[i].inbound_time)
              packetList.push(olist[i].packets)
              profitList.push(olist[i].profits)
            }
            this.godownProfitStatOption = this.getGodownProfitStatOption(godownDateList, packetList, profitList)
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
    },
    getCustomerPacketTop10 () {
      this.$http.get('/godown/packet/top10').then(
        res => {
          console.log(res.data)
          const lst = res.data.data.rows
          const cusList = []
          const packetList = []
          for (let i = 0; i < lst.length; i++) {
            cusList.unshift(lst[i].cus_name)
            packetList.unshift(lst[i].packets)
          }
          this.customerPacketOption = this.getCustomerPacketOption(cusList, packetList)
        },
        err => {
          this.$message.error({ message: err.response.data.msg })
        }
      )
    },
    getCustomerPacketOption (cusList, packetList) {
      const title = '客户包裹数量排行TOP10'
      const yName = '客户名称'
      const xName = '包裹数'
      return this.getTop10Option(title, yName, xName, cusList, packetList)
    },
    getGodownProfitTop10 () {
      this.$http.get('/godown/profit/top10').then(
        res => {
          // console.log(res)
          const lst = res.data.data.rows
          const productList = []
          const profitList = []
          for (let i = 0; i < lst.length; i++) {
            productList.unshift(lst[i].store_no + '(' + lst[i].product_name + ')')
            profitList.unshift(lst[i].profit)
          }
          this.godownProfitOption = this.getGodownProfitOption(profitList, productList)
        },
        err => {
          this.$message.error({ message: err.response.data.msg })
        }
      )
    },
    getGodownProfitOption (profitList, productList) {
      const title = '进仓单利润排行TOP10'
      const yName = '进仓单号（商品名）'
      const xName = '利润'
      return this.getTop10Option(title, yName, xName, productList, profitList)
    },
    getTop10Option (title, yName, xName, yList, seriseList) {
      return {
        title: {
          text: title,
          left: 'left'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            label: {
              show: true
            }
          }
        },
        toolbox: {
          show: true,
          // orient: 'vertical',
          feature: {
            mark: { show: true },
            dataView: {
              show: true,
              readOnly: false,
              title: '数据视图'
            },
            magicType: {
              show: true,
              type: ['line', 'bar'],
              title: {
                line: '切换为折线图',
                bar: '切换为柱状图'
              }
            },
            restore: { show: false },
            saveAsImage: {
              show: true,
              title: '另存为图片'
            }
          }
        },
        grid: { containLabel: true },
        xAxis: { name: xName },
        yAxis: {
          name: yName,
          type: 'category',
          data: yList
        },
        visualMap: {
          orient: 'horizontal',
          left: 'center',
          min: Math.min(...seriseList),
          max: Math.max(...seriseList),
          text: ['高', '低'],
          // Map the score column to color
          dimension: 0,
          inRange: {
            color: ['#65B581', '#FFCE34', '#FD665F']
          }
        },
        series: [
          {
            label: {
              show: true,
              verticalAlign: 'middle'
            },
            type: 'bar',
            encode: {
              x: 'profit',
              y: 'product'
            },
            data: seriseList
          }
        ]
      }
    },
    /** 得到进仓单各客户贡献利润 */
    getCusProfit () {
      this.$http.get('/godown/customer/profit').then(
        res => {
          // console.log(res.data)
          const lst = res.data.data.rows
          const cusNameList = []
          const cusProfitList = []
          for (let i = 0; i < lst.length; i++) {
            cusNameList.push(lst[i].cus_name)
            cusProfitList.push({
              value: lst[i].profit,
              name: lst[i].cus_name
            })
          }
          this.cusProfitOption = this.getPieChartOption(cusNameList, cusProfitList)
        },
        err => {
          this.$message.error({ message: err.response.data.msg })
        }
      )
    },
    getPieChartOption (cusNameList, cusProfitList) {
      return {
        title: {
          text: '客户贡献利润占比图',
          left: 'center'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} CNY ({d}%)'
        },
        toolbox: {
          show: true,
          // orient: 'vertical',
          feature: {
            mark: { show: true },
            dataView: {
              show: true,
              readOnly: false,
              title: '数据视图'
            },
            // magicType: { show: true, type: ['line', 'bar'], title: { line: '切换为折线图', bar: '切换为柱状图' } },
            restore: { show: false },
            saveAsImage: {
              show: true,
              title: '另存为图片'
            }
          }
        },
        legend: {
          orient: 'vertical',
          left: 'left',
          data: cusNameList
        },
        series: [
          {
            name: '利润及占比',
            type: 'pie',
            radius: '80%',
            center: ['60%', '60%'],
            data: cusProfitList,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
    }
  }
}
</script>

<style scoped>
.bottom {
  margin-top: 13px;
  line-height: 12px;
}
.text {
  font-size: 36px;
}
.item {
  margin-bottom: 18px;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
.chart {
  height: 400px;
}
</style>
