import moment from 'moment'
class FormatTools {
   /** 格式化数字千分位 */
  formatQfw (n) {
    // 数字转字符串
    let result = `${n}`
    // 校验输入值是否为数字
    const regNumber = /^\d+$|(\d+\.\d+)/g
    // 校验小数点及右侧部分
    const regRight = /(?<=\d)(\.\d+)/g
    // 校验小数点左侧的部分
    const regLeft = /(\d)(?=(\d{3})+$)/g
    // 判断是否是数字
    if (regNumber.test(result)) {
      // 判断是否包含小数点
      if (/\./g.test(result)) {
        // 提取小数点和右侧部分
        const right = regRight.exec(result)[0]
        // 替换小数点和右侧部分为空白，得到小数点左侧部分
        // 对左侧部分进行千分位处理
        const left = result.replace(regRight, '').replace(regLeft, '$1,')
        // 左右两部分拼接
        result = `${left}${right}`
      } else {
        // 不包含小数点，直接千分位处理
        result = result.replace(regLeft, '$1,')
      }
    } else {
      console.warn(`invalid number ${n}`)
    }
    return result
  }

  /** 格式化时间戳为标准的时间字符串 */
  formatDatetime (iValue) {
    return moment(iValue * 1000).format('YYYY/MM/DD HH:mm:ss')
  }

  /** 保留小数位数，x原始值，y是小数保留位 */
  toDecimal (x, y) {
    let f = parseFloat(x)
    if (isNaN(f)) {
        return false
    }
    f = Math.round(x * 10 ** y) / 10 ** y
    let s = f.toString()
    let rs = s.indexOf('.')
    if (rs < 0) {
        rs = s.length
        s += '.'
    }
    while (s.length <= rs + y) {
        s += '0'
    }
    return s
  }

  toDecimal2 (cellValue) {
    return this.toDecimal(cellValue, 2)
  }

  toDecimal3 (cellValue) {
    return this.toDecimal(cellValue, 3)
  }
}
export { FormatTools }
