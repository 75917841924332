import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/Login.vue'
import Home from '../components/Home.vue'
import Welcome from '../components/Welcome'
import Users from '../components/user/Users'
import Rights from '../components/power/Rights'
import Roles from '../components/power/Roles'
import Customers from '../components/customer/Customers'
import Godowns from '../components/godown/Godowns'
import Godownfee from '../components/godown/Godownfee'
import Monitor from '../components/vehicle/Monitor'
import Declarance from '../components/customs/Declarance'
import Drivers from '../components/vehicle/Drivers'
import Vehicles from '../components/vehicle/Vehicles'
import Vehiclefee from '../components/vehicle/Vehiclefee'
import Templates from '../components/customs/Templates'
import Orders from '../components/customs/Orders'
import Orderlist from '../components/customs/Orderlist'
import Orderview from '../components/dataview/Orderview'
import Godownview from '../components/dataview/Godownview'
import Vehicleview from '../components/dataview/Vehicleview'
import Profitview from '../components/dataview/Profitview'
import Baseprice from '../components/vehicle/Baseprice'
import Attendance from '@/components/attendance/Attendance'
import Leave from '@/components/leave/Leave'
import Reimbursement from '@/components/reimbursement/Reimbursement'
import GodownQuery from '@/components/GodownQuery'
import Barcode from '../components/godown/Barcode'
import Shipment from '@/components/godown/Shipment'
import Contract from '@/components/contract/Contract'
import InvoiceCome from '@/components/invoice/InvoiceCome'
import InvoiceGo from '@/components/invoice/InvoiceGo'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/login' },
  { path: '/login', component: Login },
  { path: '/godownquery', component: GodownQuery },
  {
    path: '/home',
    component: Home,
    redirect: '/welcome',
    children: [
      { path: '/welcome', component: Welcome },
      { path: '/userlist', component: Users },
      { path: '/rightlist', component: Rights },
      { path: '/rolelist', component: Roles },
      { path: '/customerlist', component: Customers },
      { path: '/godownlist', component: Godowns },
      { path: '/godownfee', component: Godownfee },
      { path: '/barcode', component: Barcode },
      { path: '/shipment', component: Shipment },
      { path: '/monitor', component: Monitor },
      { path: '/declarance', component: Declarance },
      { path: '/driverlist', component: Drivers },
      { path: '/vehiclelist', component: Vehicles },
      { path: '/vehiclefee', component: Vehiclefee },
      { path: '/template', component: Templates },
      { path: '/order', component: Orders },
      { path: '/orderlist', component: Orderlist },
      { path: '/orderview', component: Orderview },
      { path: '/godownview', component: Godownview },
      { path: '/vehicleview', component: Vehicleview },
      { path: '/profitview', component: Profitview },
      { path: '/baseprice', component: Baseprice },
      { path: '/attendance', component: Attendance },
      { path: '/leave', component: Leave },
      { path: '/reimbursement', component: Reimbursement },
      { path: '/contract', component: Contract },
      { path: '/invoicego', component: InvoiceGo },
      { path: '/invoicecome', component: InvoiceCome }]
  }
]

const router = new VueRouter({
  // mode: 'hash',
  routes
})

// 挂载路由导航守卫
router.beforeEach((to, form, next) => {
  if (to.path === '/login') next()

  const tokenStr = window.sessionStorage.getItem('token')
  if (!tokenStr && to.path !== '/login' && to.path !== '/godownquery') {
    next({
      path: '/login',
      query: { redirect: to.fullPath } // 将要跳转路由的path作为参数，传递到登录页面
    })
  } else {
    next()
  }
})

export default router
