<template>
  <el-container class="home-container">
    <el-header>
      <div class="sys-div">
        <img src="../assets/logo.png" height="90" width="102"/>
        <span>力码先管理系统</span>
      </div>
      <div>
        <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link">
            {{nickname}}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item icon="el-icon-edit" command="modifyPWD">修改密码</el-dropdown-item>
            <el-dropdown-item icon="el-icon-remove" command="logout">注    销</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-header>
    <el-container>
      <el-aside :width="isCollapse?'64px':'200px'">
        <div class="toggle-button" @click="toggleCollapse"><i :class="isCollapse?'el-icon-s-unfold':'el-icon-s-fold'"></i></div>
<!--        <div class="sys-div"><span>力码先管理系统</span></div>-->
<!--        <div class="toggle-button" @click="toggleCollapse">|||</div>-->
        <el-menu background-color="#333744" text-color="#fff" active-text-color="#ffd04b" :collapse="isCollapse" :collapse-transition="false" unique-opened router :default-active="activePath">
          <el-submenu :index="item.id+''" v-for="item in menulist" :key="item.id">
            <template slot="title">
              <i :class="item.logo"></i>
              <span>{{item.authName}}</span>
            </template>
            <el-menu-item :index="subItem.path" v-for="subItem in item.subMenus" :key="subItem.id" @click="saveNavState(subItem.path)">
              <template slot="title">
              <i :class="subItem.logo"></i>
              <span>{{subItem.authName}}</span>
            </template></el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <el-main>

        <!-- 路由占位符 -->
        <router-view></router-view>
      </el-main>
    </el-container>
    <!-- 修改密码 -->
     <el-dialog title="修改密码" :visible.sync="modifyPWDDialogVisible" @close="modifyPWDDialogClosed">
       <!-- 内容主体区 -->
      <el-form :model="modifyPWDForm" ref="modifyPWDFormRef" :rules="modifyPWDFormRules" label-width="80px">
        <el-form-item label="原密码" prop="old_password">
          <el-input v-model="modifyPWDForm.old_password" show-password prefix-icon="el-icon-lock"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="new_password">
          <el-input v-model="modifyPWDForm.new_password" show-password prefix-icon="el-icon-lock"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="confirm_password">
          <el-input v-model="modifyPWDForm.confirm_password" show-password prefix-icon="el-icon-lock"></el-input>
        </el-form-item>
      </el-form>
        <!-- 底部区域 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="modifyPWDDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="modifyPWD">保 存</el-button>
      </div>
     </el-dialog>
  </el-container>
</template>

<script>
export default {
  name: 'Home',
  data () {
    return {
      modifyPWDDialogVisible: false,
      modifyPWDForm: {
        old_password: '',
        new_password: '',
        confirm_password: ''
      },
      modifyPWDFormRules: {
        old_password: [
          { required: true, message: '请输入原密码', trigger: 'blur' },
          { min: 6, max: 20, message: '长度在6～20个字符之间', trigger: 'blur' }
        ],
        new_password: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { min: 6, max: 20, message: '长度在6～20个字符之间', trigger: 'blur' }
        ],
        confirm_password: [
          { required: true, message: '请输入确认密码', trigger: 'blur' },
          { min: 6, max: 20, message: '长度在6～20个字符之间', trigger: 'blur' }
        ]
      },
      nickname: window.sessionStorage.getItem('nickname'),
      menulist: [],
      isCollapse: false,
      activePath: ''
    }
  },
  created () {
    this.getMenuList()
    this.activePath = window.sessionStorage.getItem('activePath')
  },
  methods: {
    /** 修改密码对话框关闭事件 */
    modifyPWDDialogClosed () {
      this.$refs.modifyPWDFormRef.resetFields()
    },
    getMenuList () {
      const mlist = window.sessionStorage.getItem('MenuList')
      // console.log(mlist)
      if (mlist) {
        this.menulist = JSON.parse(mlist)
        // console.log(this.menulist)
        return
      }
      this.$http.get('/right').then(res => {
        this.menulist = res.data.data.rows
        // console.log(this.menulist)
        window.sessionStorage.setItem('MenuList', JSON.stringify(this.menulist))
      }, err => {
        this.$message.error('网络超时，请稍后再试！' + err.response.data.msg)
        // console.log('err = ' + err.response)
      })
    },
    /** 下拉事件处理 */
    handleCommand (command) {
      if (command === 'logout') {
        this.logout()
      } else {
        // this.modifyPWD()
        this.modifyPWDDialogVisible = true
      }
    },
    /** 修改密码 */
    modifyPWD () {
      this.$refs.modifyPWDFormRef.validate(valid => {
        if (!valid) return

        if (this.modifyPWDForm.new_password !== this.modifyPWDForm.confirm_password) {
          this.$message.error({ message: '新密码与确认密码不一致！' })
          return
        }
        console.log(JSON.stringify(this.modifyPWDForm))
        this.$http.put('/user/password', this.modifyPWDForm).then(
          res => {
            this.$message.success({ message: res.data.msg })
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          })
      })
    },
    /** 用户登出 */
    logout () {
      window.sessionStorage.clear()
      this.$router.push('/login')
    },
    toggleCollapse () {
      this.isCollapse = !this.isCollapse
    },
    saveNavState (activePath) {
      window.sessionStorage.setItem('activePath', activePath)
      this.activePath = activePath
    }
  }
}
</script>

<style lang="less" scoped>
  .el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
  .home-container {
    font-family: "PingFangSC-Thin";
    height: 800px;
  }
  .el-header {
    /*position: relative;*/
    /*width: 100%;*/
    /*height: 60px;*/
    background-color: #373d41;
    display: flex;
    justify-content: space-between;
    // padding-left: 0px;
    align-items: center;
    color: #fff;
    font-size: 20px;
    > div {
      display: flex;
      align-items: center;
      span {
        margin-left: 15px;
      }
    }
}
.el-aside {
  /*display: block;*/
  /*position: absolute;*/
  /*left: 0;*/
  /*top: 60px;*/
  /*bottom: 0;*/
  background-color: #333744;
  .el-menu {
    border-right: None;
  }
}
.el-main {
  /*position: absolute;*/
  /*left: 200px;*/
  /*right: 0;*/
  /*top: 60px;*/
  /*bottom: 0;*/
  /*overflow-y: scroll;*/
  /*height:0;*/
  /*flex-grow:1;*/
 background-color: #eaedf1;
}
.toggle-button {
  background-color: #4A5064;
  /*font-size: 10px;*/
  line-height: 24px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.2em;
  cursor: pointer;
}
  .el-input {
    /*width: 200px;*/
  }
  .sys-div {
    color: #fff;
    text-align: center;
  }
</style>
