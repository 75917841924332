<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>加班请假管理</el-breadcrumb-item>
      <el-breadcrumb-item>加班请假列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!--   搜索Form表单区域   -->
    <el-card>
      <el-form v-model="searchForm" :inline="true">
        <el-form-item label="部门">
          <el-select v-model="searchForm.dept_id" placeholder="请选择部门" @change="onDepartmentChange" @clear="onClearDepartment" clearable style="width: 160px">
            <el-option
              v-for="item in departmentList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="员工">
          <el-select v-model="searchForm.user_id" placeholder="请选择员工" @clear="onClearUser" clearable style="width: 160px">
            <el-option
              v-for="item in departmentUserList"
              :key="item.id"
              :label="item.nickname"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类型">
          <el-select v-model="searchForm.leave_type" placeholder="请选择申请类型" @clear="onClearLeaveType" clearable style="width: 160px">
            <el-option
              v-for="item in leaveTypeList"
              :key="item.value"
              :label="item.name"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="searchForm.leave_status" placeholder="请选择申请状态" @clear="onClearLeaveStatus" clearable style="width: 160px">
            <el-option
              v-for="item in leaveStatusList"
              :key="item.value"
              :label="item.name"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="申请日期">
          <el-date-picker value-format="timestamp" v-model="searchForm.commit_time" type="daterange" range-separator="至"
                          start-placeholder="开始日期" end-placeholder="结束日期" style="width: 300px">
          </el-date-picker>
        </el-form-item>
      </el-form>
    </el-card>
    <!--   员工列表区域   -->
    <el-card>
      <el-button-group>
        <el-button type="primary" @click="searchLeave" size="small" icon="el-icon-search">查询</el-button>
        <el-button type="primary" v-loading.fullscreen.lock="fullscreenLoading" @click="exportExcel" size="small" icon="el-icon-document">导出EXCEL</el-button>
        <!--el-button type="primary" @click="addDialogVisible=true" size="small" icon="el-icon-plus">新增</el-button-->
      </el-button-group>
      <el-table :data="tableData" style="width: 100%" stripe>
        <el-table-column label="ID" prop="id" width="60px" fixed="left"></el-table-column>
        <el-table-column label="申请日期" prop="commit_time" width="120px" :formatter="formatDate"></el-table-column>
        <el-table-column label="员工姓名" prop="name" width="120px" :formatter="formatUserName"></el-table-column>
        <el-table-column label="申请类型" prop="leave_type" width="100px" :formatter="formatTypeName"></el-table-column>
        <el-table-column label="开始时间" prop="start_time" width="165px" :formatter="formatDatetime"></el-table-column>
        <el-table-column label="结束时间" prop="end_time" width="165px" :formatter="formatDatetime"></el-table-column>
        <el-table-column label="天数/时长" prop="day_times" width="90px" ></el-table-column>
        <el-table-column label="证明材料" prop="images">
          <template slot-scope="scope">
            <el-image
              style="width: 20px; height: 20px"
              :src="getImageUrls(scope.row.images)[0]"
              :preview-src-list="getImageUrls(scope.row.images)">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column label="申请理由" prop="leave_reason" width="300px" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column label="流程状态" prop="leave_status" width="100px" :formatter="formatLeaveStatusName"></el-table-column>
        <el-table-column width="120px" fixed="right" align="center" label="操作">
           <template slot-scope="scope">
             <el-button-group>
               <el-button
                size="mini"
                type="primary"
                icon="el-icon-view"
                @click="handleViewFlow(scope.$index, scope.row)">流程追踪</el-button>
             </el-button-group>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination background style="width: 100%;margin:15px 0px;" class="pagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryInfo.page"
      :page-sizes="[3, 6, 10, 20]"
      :page-size="queryInfo.pageSize"
      layout="prev, pager, next, jumper,total, sizes"
      :total="queryInfo.total">
     </el-pagination>
      <el-drawer
        title="审批流程"
        :visible.sync="drawer"
        :direction="direction"
        :before-close="handleClose">
        <el-timeline :reverse="true">
          <el-timeline-item
            v-for="(activity, index) in flow_steps"
            :key="index"
            color="#0bbd87"
            :timestamp="formatDatetime(null, null, activity.audit_time)">
            {{activity.title}}<br/>{{activity.audit_info}}
          </el-timeline-item>
        </el-timeline>
      </el-drawer>
    </el-card>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'Leave',
  data () {
    return {
      fullscreenLoading: false,
      searchForm: {
        dept_id: null,
        user_id: null,
        leave_type: null,
        leave_status: null,
        commit_time: [this.$moment().subtract(1, 'months').valueOf(), this.$moment().valueOf()]
      },
      tableData: [],
      queryInfo: {
        query: '',
        page: 1,
        pageSize: 10,
        total: 0
      },
      userList: [],
      departmentList: [],
      departmentUserList: [],
      curDepartment: null,
      leaveTypeList: [],
      leaveStatusList: [],
      drawer: false,
      direction: 'rtl',
      leaveAuditList: [],
      flow_steps: []
    }
  },
  created: function () {
    this.getLeaveList(this.queryInfo)
    this.getUserList()
    this.getDepartmentList()
    this.getLeaveTypeList()
    this.getLeaveStatusList()
  },
  methods: {
    doWithFlow () {
      this.flow_steps = []
      for (const item of this.leaveAuditList) {
        const step = {}
        let desc = '发起申请'
        if (item.flow_node_id === 20) {
          desc = '部门审核'
        }
        if (item.flow_node_id === 30) {
          desc = '领导审批'
        }
        if (item.flow_node_id === 50) {
          desc = '完成归档'
        }
        step.title = item.user_name + desc
        step.audit_time = item.audit_time
        step.audit_info = item.audit_info
        this.flow_steps.push(step)
      }
    },
    getLeaveAuditList (id) {
      console.log('id = ', id)
      const fromObj = 'oa_leave'
      this.$http.get(`/audit/${fromObj}/${id}`).then(
        res => {
          // console.log('res = ', res)
          this.leaveAuditList = res.data.data
          this.doWithFlow()
        },
        err => {
          this.$message.error({ message: err.response.data.msg })
        }
      )
    },
    handleViewFlow (index, row) {
      this.drawer = true
      this.getLeaveAuditList(row.id)
    },
    getImageUrls (images) {
      // console.log('images:', images)
      const imgList = []

      for (let i = 0; i < images.length; i++) {
        imgList.push(this.$config.image_base_url + images[i].path + images[i].name)
      }
      // console.log('imgList:', imgList)
      return imgList
    },
    /** 导出Excel */
    exportExcel () {
      this.fullscreenLoading = true
      this.$http.post('/leave/excel', this.searchForm, { responseType: 'blob' }).then(
        res => {
          console.log(res)
          const blob = new Blob([res.data], { type: 'application/vnd.ms-excel;charset=utf-8' })
          const fileName = '请假管理.xls'
          const elink = document.createElement('a')
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = URL.createObjectURL(blob)
          document.body.appendChild(elink)

          elink.click()
          URL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)

          this.fullscreenLoading = false
        },
        err => {
          this.$message.error(err.response.data.msg)
        }
      )
    },
    /** 查询请假列表 */
    searchLeave: function () {
      this.getLeaveList(this.queryInfo)
    },
    /** 获取请假列表 */
    getLeaveList (queryInfo) {
      const endTime = this.searchForm.commit_time
      if (endTime) {
        this.searchForm.commit_time[1] = moment(endTime[1]).endOf('day').valueOf()
      }
      this.$http.post(`/leave/pagination/${queryInfo.page}/${queryInfo.pageSize}`, this.searchForm).then(
        res => {
          // console.log('this.searchForm :', JSON.stringify(this.searchForm))
          this.queryInfo.total = res.data.data.total
          this.tableData = res.data.data.rows
        },
        err => {
          this.$message.error({ message: err.response.data.msg })
        }
      )
    },
    /** 获取公司部门列表 */
    getDepartmentList: function () {
      this.$http.get('/department/list').then(
        res => {
          this.departmentList = res.data.data.rows
          // console.log('this.userList:', this.userList)
        },
        err => {
          this.$message.error({ message: err.response.data.msg })
        }
      )
    },
    /** 获取部门用户列表 */
    getDepartmentUserList: function () {
      this.$http.get(`/user/list/${this.curDepartment}`).then(
        res => {
          this.departmentUserList = res.data.data.rows
        },
        err => {
          this.$message.error({ message: err.response.data.msg })
        }
      )
    },
    /** 获取用户列表 */
    getUserList: function () {
      this.$http.get('/user/list').then(
        res => {
          this.userList = res.data.data.rows
        },
        err => {
          this.$message.error({ message: err.response.data.msg })
        }
      )
    },
    getLeaveTypeList: function () {
      this.$http.get('/dictionary/leave_type').then(
        res => {
          this.leaveTypeList = res.data.data.rows
        },
        err => {
          this.$message.error({ message: err.response.data.msg })
        }
      )
    },
    getLeaveStatusList: function () {
      this.$http.get('/dictionary/leave_status').then(
        res => {
          this.leaveStatusList = res.data.data.rows
        },
        err => {
          this.$message.error({ message: err.response.data.msg })
        }
      )
    },
    onClearDepartment (e) {
      this.searchForm.dept_id = null
    },
    onClearUser (e) {
      this.searchForm.user_id = null
    },
    onClearLeaveType (e) {
      this.searchForm.leave_type = null
    },
    onClearLeaveStatus (e) {
      this.searchForm.leave_status = null
    },
    onDepartmentChange (e) {
      this.curDepartment = e
      if (this.curDepartment) {
        this.searchForm.user_id = null
        this.getDepartmentUserList()
      } else {
        this.departmentUserList = null
      }
    },
    /** 改变列表显示记录数触发事件 */
    handleSizeChange (val) {
      this.queryInfo.pageSize = val
      this.getLeaveList(this.queryInfo)
    },
    handleCurrentChange (val) {
      this.queryInfo.page = val
      this.getLeaveList(this.queryInfo)
    },
    /** 格式化用户名称 */
    formatUserName: function (row) {
      let user
      for (let i = 0; i < this.userList.length; i++) {
        user = this.userList[i]
        // console.log('i=' + i)
        if (user.id === row.user_id) {
          // console.log(customer)
          return user.nickname
        }
      }
    },
    /** 格式化时间戳为标准的日期字符串 */
    formatDate: function (row, column, cellValue) {
      return moment(cellValue * 1000).format('YYYY/MM/DD')
    },
    /** 格式化时间戳为标准的时间字符串 */
    formatDatetime: function (row, column, cellValue) {
      return moment(cellValue * 1000).format('YYYY/MM/DD HH:mm:ss')
    },
    /** 格式化类型名称 */
    formatTypeName: function (row) {
      let type
      for (let i = 0; i < this.leaveTypeList.length; i++) {
        type = this.leaveTypeList[i]
        // console.log('i=' + i)
        if (type.value === row.leave_type) {
          // console.log(customer)
          return type.name
        }
      }
    },
    /** 格式化请假状态名称 */
    formatLeaveStatusName: function (row) {
      let status
      for (let i = 0; i < this.leaveStatusList.length; i++) {
        status = this.leaveStatusList[i]
        // console.log('i=' + i)
        if (status.value === row.leave_status) {
          // console.log(customer)
          return status.name
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
