<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
    </el-breadcrumb>
    <el-row :gutter="10">
      <el-col :span="12">
        <el-card>
          <div slot="header">
            <span>力码先小程序（手机客户端）</span>
          </div>
          <div style="height: 200px;">
            <img style="border-radius: 15px;" src="../assets/mina_qc.png" height="204px" />
          </div>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card>
          <div slot="header">
            <span>友情链接</span>
          </div>
          <div style="height: 200px">
            <el-link icon="el-icon-link" href="http://www.hzsupply.com/" type="success" target="_blank"><h3>兵  站（hzsupply.com）</h3></el-link>
            <br />
            <el-link icon="el-icon-link" href="https://mc.bandtechs.com/" type="success" target="_blank"><h3>板凳社区（mc.bandtechs.com）</h3></el-link>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="iSpan">
        <el-card>
          <div slot="header" class="clearfix">
            <span>本人近期登录记录：</span>
            <i class="el-icon-warning-outline" style="float: right; padding: 3px 0"></i>
          </div>
          <el-table :data="loginList" height="400">
            <el-table-column type="index" label="序号" width="80px"></el-table-column>
            <el-table-column label="登录时间" prop="login_time" :formatter="formatDatetime" width="180px"></el-table-column>
            <el-table-column label="登录IP" prop="login_ip"></el-table-column>
            <el-table-column label="登录方式" prop="client_type">
              <template scope="scope">
                <el-tag type="warning" v-show="scope.row.client_type === 100">小程序</el-tag>
                <el-tag type="success" v-show="scope.row.client_type === 300">WEB</el-tag>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
      <el-col :span="12" v-if="isAdminScope">
        <el-card>
          <div slot="header" class="clearfix">
            <span>用户最近一次登录时间：</span>
            <i class="el-icon-warning-outline" style="float: right; padding: 3px 0"></i>
          </div>
          <el-table :data="userLoginList" height="400">
            <el-table-column type="index" label="序号" width="80px"></el-table-column>
            <el-table-column label="账号" prop="username" width="150px"></el-table-column>
            <el-table-column label="姓名" prop="nickname" width="120px"></el-table-column>
            <el-table-column label="登录时间" prop="login_time" width="200px" :formatter="formatDatetime"></el-table-column>
          </el-table>
        </el-card>
      </el-col>
      <!--el-col :span="12" v-else>
        <el-card>
          <div slot="header" class="clearfix">
            <span>VR效果演示：</span>
            <i class="el-icon-warning-outline" style="float: right; padding: 3px 0"></i>
          </div>
          <iframe width="560" height="394" allowfullscreen style="border-style:none;" src="https://cdn.pannellum.org/2.5/pannellum.htm#config=https://pannellum.org/configs/multiresolution.json"></iframe>
        </el-card>
      </el-col-->
    </el-row>
  </div>
</template>
<script>
  export default {
    data () {
      return {
        isAdminScope: window.sessionStorage.getItem('scope') === 'AdminScope',
        loginList: [],
        userLoginList: [],
        iSpan: 12
      }
    },
    created () {
      this.getLoginInfoByself()
      this.getUserLastLoginList()
      if (!this.isAdminScope) {
        this.iSpan = 24
      }
    },
    methods: {
      getUserLastLoginList () {
        this.$http.get('/user/lastlogintime').then(
          res => {
            const result = res.data
            this.userLoginList = result.data.rows
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      getLoginInfoByself () {
        this.$http.get('/userlogin').then(
          res => {
            // console.log(res)
            const result = res.data
            this.loginList = result.data.rows
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      /** 格式化时间戳为标准的时间字符串 */
      formatDatetime: function (row, column, cellValue) {
        return this.$format.formatDatetime(cellValue)
      }
    }
  }
</script>

<style lang="less" scoped>
.el-row {
  margin-bottom: 10px;
}
.el-col {
  border-radius: 4px;
}
</style>
